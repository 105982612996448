import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  configList: [],
  settingList: [],
  aircraftId: undefined,
};

const crewCurrencySlice = createSlice({
  name: "crewCurrency",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setDefaultCurrencyConfigList: (state, action) => {
      let isArray = Array.isArray(action?.payload);
      if (!isArray) return;
      state.configList = [...(isArray && action.payload)];
    },
    setCurrencySettingsList: (state, action) => {
      let isArray = Array.isArray(action.payload);
      state.settingList = [...(isArray && action.payload)];
    },
    setAircraftId: (state, action) => {
      state.aircraftId = action.payload;
    },
  },
});

const { actions } = crewCurrencySlice;
export const {
  setDefaultCurrencyConfigList,
  setCurrencySettingsList,
  setAircraftId,
} = actions;

export const selDefaultCurrencyConfigs = (state) =>
  state.crewCurrency.configList;
export const selCurrencySettings = (state) => state.crewCurrency.settingList;
export const selAircraftId = (state) => state.crewCurrency.aircraftId;

const reducer = {
  crewCurrency: crewCurrencySlice.reducer,
};

export default reducer;
