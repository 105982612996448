import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import { EDITTAG, NEWTAG } from "./tagConstants";
import { resetStore } from "store/actions";
const initialState = {
  status: NEWTAG,
  list: [],
  userTags: [],
  flightTags: [],
  tag: { id: "", description: "" },
};
//

const tagSlice = createSlice({
  name: "tags",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    // setTagList: (state, action) => {
    //   state.status = NEWTAG;
    //   state.tag = { id: "", description: "" };
    //   state.list = action.payload;
    // },
    setUserTag: (state, action) => {
      state.status = NEWTAG;
      state.tag = { id: "", description: "" };
      state.userTags = action.payload;
    },
    setFlightTag: (state, action) => {
      state.status = NEWTAG;
      state.tag = { id: "", description: "" };
      state.flightTags = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTagToEdit: (state, action) => {
      state.status = EDITTAG;
      state.tag = action.payload;
    },
    setFormInit: (state, action) => {
      state.status = NEWTAG;
      state.tag = { id: "", description: "" };
    },

    filterList: (state, action) => {
      // const all = state.allList;
      // const { name, value } = action.payload;
      // if (value === "all") {
      //   state.list = all;
      // } else {
      //   const filtered = filterByPropValue(name, value)(all);
      //   state.list = filtered;
      // }
      // state.page = 1;
      // state.sort = "date";
      // state.asc = true;
    },
  },
});

export const { actions } = tagSlice;
export const {
  setTagList,
  setUserTag,
  setFlightTag,
  setTagStatus,
  setFormInit,
  filterList,
  setTagToEdit,
} = actions;

export const sendTagForEdit = (item) => (dispatch) => {
  return dispatch(setTagToEdit(item));
};
export const resetTagForm = () => (dispatch) => {
  return dispatch(setFormInit());
};

// selectors

export const selTagsStutus = (state) => state.tags.status;

export const selTagsList = (state) => state.tags.list;
export const selUserTags = (state) => state.tags.userTags;
export const selFlightTags = (state) => state.tags.flightTags;
export const selTagId = (state) => state.tags.status;
export const selCurentTag = (state) => state.tags.tag;

const reducer = {
  tags: tagSlice.reducer,
};

export default reducer;
