import { makeStyles, Button as MuiButton } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import CircularProgresss from "../CircularProgress";
const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "auto",
      minWidth: "100px",
      "& .MuiSvgIcon-root": {
        color: ({ variant }) => variant === "contained" && theme.palette.common.white,
      },
    },
  }),
  { name: "CommonButton" }
);
export const Button = React.memo(({ startIcon, variant, color, size, className, disabled, text, onClick, children, loading = false, ...rest }) => {
  const classes = useStyles({ variant });

  return (
    <MuiButton
      onClick={onClick}
      variant={variant}
      color={color}
      size={size}
      disabled={loading || disabled}
      className={clsx(classes.root, className)}
      startIcon={loading ? <CircularProgresss /> : startIcon}
      {...rest}>
      {text ?? children}
    </MuiButton>
  );
});
