import { createSlice } from "@reduxjs/toolkit";
import { fleetDocumentModel } from "./documentModel";
import { resetStore } from "store/actions";

const initialState = {
  documentLists: [],
  filter: {
    page: 1,
    limit: 5,
  },
  documentAssignment: [],
  airlineDocumentAssignment: [],
  assignmentTotalItems: 0,
};

const fleetDocumentSlice = createSlice({
  name: "fleetDocuments",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setDocumentList: (state, action) => {
      const { documentList } = action.payload;
      state.documentLists = documentList;
    },
    setDocumentAircraft: (state, action) => {
      state.filter = action.payload;
    },
    setDocumentAssignment: (state, action) => {
      state.documentAssignment = action.payload;
    },
    setAirlineDocumentAssignments: (state, action) => {
      const { assignments, totalItems } = action.payload;
      state.airlineDocumentAssignment = assignments;
      state.assignmentTotalItems = totalItems;
    },
    setAirlineDocumentPage: (state, action) => {
      state.filter.page = action.payload;
    },
    setAirlineDocumentLimit: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { actions } = fleetDocumentSlice;
export const {
  setDocumentList,
  setDocumentAircraft,
  setDocumentAssignment,
  setAirlineDocumentAssignments,
  setAirlineDocumentLimit,
  setAirlineDocumentPage,
} = actions;

export const selFleetDocument = (state) => state.fleetDocuments.documentLists;
export const selFleetDocumentAssignment = (state) =>
  state.fleetDocuments.documentAssignment;
export const selAirlineDocumentFilter = (state) => state.fleetDocuments.filter;
export const selAirlineDocumentAssignment = (state) =>
  state.fleetDocuments.airlineDocumentAssignment;
export const selAirlineDocumentAssignmentTotalItems = (state) =>
  state.fleetDocuments.assignmentTotalItems;

const reducer = {
  fleetDocuments: fleetDocumentSlice.reducer,
};

export default reducer;
