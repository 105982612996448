import loaderClient, { clientNoLoader } from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import {
  setDefaultGroups,
  setFleets,
  setGroupPolicies,
  setGroups,
  updateGroups,
} from "./groupsSlice";
import { selAirlineId } from "store/slices";
/* -------------------------------------------------------------------------- */
export const getDefaultGroups = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(
    `/permissions/policy-permissions?airline=${airlineId}&limit=all`
  );
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setDefaultGroups(res.message));
};
/* -------------------------------------------------------------------------- */
export const getGroupPolicies = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(
    `/groups/group-policy/policies?airline=${airlineId}&limit=all`
  );
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setGroupPolicies(res.message));
};
/* -------------------------------------------------------------------------- */
export const getGroups = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(`/groups/?airline=${airlineId}&limit=all`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setGroups(res.message));
};
/* -------------------------------------------------------------------------- */
export const updateGroup =
  (groupId, body, callback) => async (dispatch, getState) => {
    const airlineId = selAirlineId(getState());
    const client = loaderClient("patch")(`/groups/${groupId}`);
    const res = await client({ ...body, airline: airlineId })({});
    if (res.error) return;
    toast.success("Group  Updated Successfully");
    dispatch(updateGroups({ groupId, fields: res.message }));
    return callback && callback();
  };
/* -------------------------------------------------------------------------- */
export const createGroup = (body, callback) => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("post")(`/groups`);
  const res = await client({ ...body, airline: airlineId })({});
  if (!res.message) return "";
  toast.success("Group Created Successfully");
  dispatch(getGroups());
  return callback && callback();
};
/* -------------------------------------------------------------------------- */
export const deleteGroup = (groupId, callback) => async (dispatch) => {
  const client = loaderClient("delete")(`/groups/${groupId}`);
  const res = await client({})({});
  if (!res.message) return "";
  toast.success("Group Deleted");
  dispatch(getGroups());
  return callback && callback();
};
/* -------------------------------------------------------------------------- */
export const updateGroupUsers =
  (groupId, body, callback) => async (dispatch) => {
    const client = clientNoLoader("patch")(`/groups/${groupId}/users`);
    const res = await client({ ...body })({});
    if (res.error) {
      return callback?.(res.error);
    }
    dispatch(updateGroups({ groupId, fields: { users: res.message } }));
    return callback?.(res.error);
  };
/* -------------------------------------------------------------------------- */
export const getFleets = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(`/fleets?airline=${airlineId}&limit=all`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setFleets(res.message));
};
