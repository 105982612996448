import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { toast } from "react-toastify";
import { resetStore } from "store/actions";
/* -------------------------------------------------------------------------- */
const initialState = {
  list: [],
  custom: [],
};

const delayCodesSlice = createSlice({
  name: "delayCodes",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setCustomDelayCodes: (state, action) => {
      state.custom = action.payload;
    },
    setAirlineDelayCodes: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { actions } = delayCodesSlice;
export const { setCustomDelayCodes, setAirlineDelayCodes } = actions;

// selectors
export const selCustomDelayCodes = (state) => [...state.delayCodes.custom];
export const selAirlineDelayCodes = (state) =>
  _.cloneDeep(state.delayCodes.list);

const reducer = {
  delayCodes: delayCodesSlice.reducer,
};

export default reducer;
