import React, { useEffect } from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import Dashboard from "./dashboard";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAircrafts } from "store/slices/aircrafts/aircraftsThunks";
import { getAircraftTypes } from "./dashboard/routes/fleet/slice/fleetThunks";
import {
  getAirlineById,
  getTripTypeEnums,
} from "store/slices/airline/airlineThunks";
import {
  getAirlineDelayCodes,
  getCustomDelayCodes,
} from "store/slices/delayCodes/delayCodesThunks";
import { getGroupPolicies } from "./dashboard/routes/adminGroupAndPermissions/slice/groupsThunk";
import { getAirlineSettings } from "./dashboard/routes/generalSettings/slice/generalSettingThunk";
import { initProfileV2 } from "store/slices/thunks";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    position: "absolute",
    backgroundColor: "transparent",
    marginTop: 100,
  },
}));

const Routes = ({ match }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    sync();
  }, []);

  const sync = () => {
    const fetchAircrafts = dispatch(getAircrafts());
    const fetchAircraftTypes = dispatch(getAircraftTypes());
    const fetchAirlineById = dispatch(getAirlineById());
    const fetchCustomDelayCodes = dispatch(getCustomDelayCodes()); //TODO remove
    const fetchAirlineDelayCodes = dispatch(getAirlineDelayCodes());
    const fetchGroupPolicies = dispatch(getGroupPolicies());
    const fetchAirlineSettings = dispatch(getAirlineSettings());
    const fetchTripTypeEnums = dispatch(getTripTypeEnums());
    const fetchProfile = dispatch(initProfileV2());
    Promise.all([
      fetchAircrafts,
      fetchAircraftTypes,
      fetchAirlineById,
      fetchCustomDelayCodes,
      fetchAirlineDelayCodes,
      fetchGroupPolicies,
      fetchAirlineSettings,
      fetchTripTypeEnums,
      fetchProfile,
    ])
      .then((results) => {
        Promise.resolve();
      })
      .catch((error) => {
        Promise.reject();
      });
  };

  return (
    <Switch>
      <Route path={`${match.url}/dashboard`} component={Dashboard} />
      <Redirect exact from={`*`} to={`${match.url}/dashboard`} />
    </Switch>
  );
};

export default withRouter(Routes);
