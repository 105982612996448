import axios from "axios";
import { getNewAccessToken } from "./masterApi";
import store from "store";
import { selSecret, selTokens } from "store/slices";
import { resetStore } from "store/actions";
import { toast } from "react-toastify";
/* -------------------------------------------------------------------------- */
const IGNORABLE_ERROR_STATUS = [401, 409];
export const BASE_URL =
  process.env.REACT_APP_BACKEND_URL || "https://apidev.bep.ir/api";
/* -------------------------------------------------------------------------- */
let axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});
export const globalAxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 20000,
});
/* -------------------------------------------------------------------------- */
export const invalidateUser = () => {
  store.dispatch(resetStore());
};
/* -------------------------------------------------------------------------- */
/*                                 ResponseError                              */
/* -------------------------------------------------------------------------- */
let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (callback) => {
  refreshSubscribers.push(callback);
};

const onRrefreshed = (token) => {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
};

const onResponseError = async (error) => {
  if (error.response?.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const newToken = await getNewAccessToken();
        isRefreshing = false;
        onRrefreshed(newToken);
        return axiosInstance.request(error.config);
      } catch (err) {
        isRefreshing = false;
        onRrefreshed(null); // Notify all subscribers about the failure
        return Promise.reject(err);
      }
    } else {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          if (token) {
            error.config.headers["Authorization"] = `Bearer ${token}`;
            resolve(axiosInstance.request(error.config));
          } else {
            reject(error);
          }
        });
      });
    }
  } else {
    console.log("axiosError", error);
    if (!IGNORABLE_ERROR_STATUS.includes(error.response?.status))
      toast.error(
        `${
          error.response?.data?.message ||
          error.response?.data?.result_number ||
          `Network Error!! (${error.response?.status || ""})`
        }`
      );
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, onResponseError);
/* -------------------------------------------------------------------------- */
axiosInstance.interceptors.request.use(
  (reqConfig) => {
    const { token } = selTokens(store.getState());
    const secret = selSecret(store.getState());

    if (!reqConfig.url.includes("/token-auth")) {
      reqConfig.headers["Authorization"] = token ?? undefined;
      reqConfig.headers["secret"] = secret ?? undefined;
      return reqConfig;
    }
  },
  (err) => Promise.reject(err)
);
export default axiosInstance;
