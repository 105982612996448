import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import AppLocale from "../lngProvider";
import RTL from "util/RTL";
import { selAppStatusIs } from "store/slices";
import { StylesProvider, createGenerateClassName } from "@material-ui/core/styles";
import { VERIFIED } from "store/slices/constants";
import defaultTheme from "./themes/defaultTheme";
import { SWRConfig } from "swr";
import { swrConfig } from "config/swr";
import { CssBaseline } from "@material-ui/core";
/* -------------------------------------------------------------------------- */
/*                                   Routes                                   */
/* -------------------------------------------------------------------------- */
import AppLayout from "./AppLayout";
import SignIn from "../app/routes/auth/SignIn";
import SignUp from "../app/routes/auth/SignUp";
import VerifyEmail from "../app/routes/auth/VerifyEmail";
import ResetPassword from "../app/routes/auth/ResetPassword";
import ForgotPassword from "../app/routes/auth/ForgotPassword";
/* -------------------------------------------------------------------------- */
const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

const AppExeptionRoutes = ["/verify-email"];
const RedirectRoute = ({ path }) => {
  const location = useLocation();
  console.log("location.pathname", { path }, location.pathname, AppExeptionRoutes.includes(`${location.pathname}`));
  if (String(location.pathname).includes(path) || AppExeptionRoutes.includes(`${location.pathname}`)) return <></>;
  return <Redirect exact from={"*"} to={path} />;
};

const AuthRoutes = ["/login", "/forgot-password", "/signup", "/reset-password", "/verify-email"];
const RedirectAuthRoute = ({ path }) => {
  const location = useLocation();
  if (AuthRoutes.includes(location.pathname)) return <></>;
  return <Redirect exact from={"*"} to={path} />;
};

const App = (props) => {
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);

  const isDarkTheme = darkTheme;
  const {
    match,
    // , location
  } = props;
  console.log({ "match.url": match.url });
  const isVerified = useSelector(selAppStatusIs(VERIFIED));
  const currentAppLocale = AppLocale[locale.locale];

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
            <SWRConfig value={swrConfig}>
              <RTL>
                <div className='app-main'>
                  <Switch>
                    {/* Routes for authenticated users */}
                    {isVerified ? (
                      <>
                        {/* Dashboard route */}
                        <Route path={`${match.url}app`} component={AppLayout} />
                        <Route path='/verify-email' component={VerifyEmail} />
                        <RedirectRoute path={`${match.url}app`} />
                      </>
                    ) : (
                      // Routes for unauthenticated users
                      <>
                        {/* Auth routes */}
                        <Route path='/login' component={SignIn} />
                        <Route path='/forgot-password' component={ForgotPassword} />
                        <Route path='/signup' component={SignUp} />
                        <Route path='/reset-password' component={ResetPassword} />
                        <Route path='/verify-email' component={VerifyEmail} />

                        {/* Redirect to login for unknown routes */}
                        {/* <Redirect to='/login' /> */}
                        <RedirectAuthRoute path={"/login"} />
                      </>
                    )}
                  </Switch>
                </div>
              </RTL>
            </SWRConfig>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
