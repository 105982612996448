import { makeStyles } from "@material-ui/core";
import { RectangleIcon } from "app/components/common/Icons/Skeletons/Rectangle";
import { SquareIcon } from "app/components/common/Icons/Skeletons/Square";
import { Skeleton } from "app/components/common/Skeleton";
import React from "react";
/* -------------------------------------------------------------------------- */
export const BoxSkeleton = ({
  square = true,
  rect = true,
  squareSize = {},
  rectSize = { width: "50px", height: "15px" },
  className = "",
}) => {
  const classes = useStyles();
  return (
    <Skeleton>
      <div className={`${classes.container} ${className}`}>
        {square && (
          <SquareIcon
            style={{ width: "20px", height: "20px", ...squareSize }}
          />
        )}
        {rect && (
          <RectangleIcon
            style={{ width: "50px", height: "15px", ...rectSize }}
          />
        )}
      </div>
    </Skeleton>
  );
};
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
    overflow: "hidden",
  },
}));
