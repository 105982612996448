import { SvgIcon } from "@material-ui/core";

export const ArrowLeftIcon = (props) => {
  return (
    <SvgIcon width="24" height="24" {...props} fill="currentColor">
      <path
        d="M5.825 13.0008H21C21.2833 13.0008 21.5208 12.9049 21.7125 12.7133C21.9042 12.5216 22 12.2841 22 12.0008C22 11.7174 21.9042 11.4799 21.7125 11.2883C21.5208 11.0966 21.2833 11.0008 21 11.0008H5.825L9.7 7.10078C9.88333 6.91745 9.97916 6.68828 9.9875 6.41328C9.99583 6.13828 9.9 5.90078 9.7 5.70078C9.51666 5.51745 9.28333 5.42578 9 5.42578C8.71666 5.42578 8.48333 5.51745 8.3 5.70078L2.7 11.3008C2.59999 11.4008 2.52916 11.5091 2.4875 11.6258C2.44583 11.7424 2.425 11.8674 2.425 12.0008C2.425 12.1341 2.44583 12.2591 2.4875 12.3758C2.52916 12.4924 2.59999 12.6008 2.7 12.7008L8.3 18.3008C8.48333 18.4841 8.7125 18.5758 8.9875 18.5758C9.2625 18.5758 9.5 18.4841 9.7 18.3008C9.9 18.1008 10 17.8633 10 17.5883C10 17.3133 9.9 17.0758 9.7 16.8758L5.825 13.0008Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
