import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

export const initialEndorsementState = {
  aircrafts: [],
  positions: [],
  expiration_warning: false,
  validity_time: undefined,
  revalidation_window: undefined,
  round_expiry_date: false,
  is_deleted: false,
  airline: undefined,
  name: undefined,
  type: undefined,
  expiring_action: undefined,
  created_by: undefined,
  created_time: undefined,
  updated_time: undefined,
  id: undefined,
};

const initialState = {
  endorsementList: [],
  userEndorsementList: [],
  currentEndorsement: initialEndorsementState,
  mailingRules: {},
  filters: {
    endorsements: {
      total: 0,
      page: 1,
      limit: 20,
    },
    mailingRules: {
      total: 0,
      page: 1,
      limit: 20,
    },
  },
};

const crewEndorsementSlice = createSlice({
  name: "crewEndorsement",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    initEndorsement: (state) => {
      state.currentEndorsement = initialEndorsementState;
    },
    setEndorsementList: (state, action) => {
      state.endorsementList = action.payload;
    },
    setUserEndorsementList: (state, action) => {
      state.userEndorsementList = action.payload;
    },
    setCurrentEndorsement: (state, action) => {
      state.currentEndorsement = {
        ...state.currentEndorsement,
        ...action.payload,
      };
    },
    setMailingRules: (state, action) => {
      state.mailingRules = action.payload;
    },
    setEndorsementFilters: (state, action) => {
      state.filters.endorsements = {
        ...state.filters.endorsements,
        ...action.payload,
      };
    },
    setMailingRulesFilters: (state, action) => {
      state.filters.mailingRules = {
        ...state.filters.mailingRules,
        ...action.payload,
      };
    },
  },
});

const filteredLicense = (list) => {
  return list?.filter((item) => {
    return item.endorsement.type !== "licence";
  });
};

const { actions } = crewEndorsementSlice;

export const {
  initEndorsement,
  setEndorsementList,
  setCurrentEndorsement,
  setMailingRules,
  setUserEndorsementList,
  setEndorsementFilters,
  setMailingRulesFilters,
} = actions;

export const selEndorsementList = (state) =>
  state.crewEndorsement.endorsementList;
export const selUserEndorsementList = (state) =>
  state.crewEndorsement.userEndorsementList;

export const selCurrentEndorsement = (state) =>
  state.crewEndorsement.currentEndorsement;
export const selMailingRules = (state) => state.crewEndorsement.mailingRules;
export const selEndorsementsFilters = (state) =>
  state.crewEndorsement.filters.endorsements;
export const selMailingRulesFilters = (state) =>
  state.crewEndorsement.filters.mailingRules;

export const resetEndorsement = () => (dispatch) => {
  return dispatch(initEndorsement());
};

const reducer = {
  crewEndorsement: crewEndorsementSlice.reducer,
};

export default reducer;
