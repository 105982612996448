import { Box, Grid, Toolbar, makeStyles } from "@material-ui/core";
import Footer from "app/components/common/Footer";
import Sidebar from "./Header/SideBar";
import { useCallback, useState } from "react";
import Header from "./Header";
import { MAIN_HEADER_NAV_HEIGHT } from "containers/themes/attributes";
import { globalStyles } from "containers/themes/globalClasses";

const Horizontal = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const closeDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);
  const handleOpenDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);
  const classes = useStyles();
  return (
    <Grid
      style={
        {
          // overflow: "auto",
        }
      }
      xs={12}
    >
      <Header openDrawer={handleOpenDrawer} />
      <Sidebar onClose={closeDrawer} open={openDrawer} />
      <Toolbar className={classes.toolbarContainer} />
      <Box className={classes.appContent} id="app-content">
        {props.children}
      </Box>
      <Footer />
    </Grid>
  );
};

export default Horizontal;
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    height: MAIN_HEADER_NAV_HEIGHT,
  },
  appContent: {
    ...globalStyles(theme).content,
    position: "relative",
    background: `${theme.palette.common.white} !important`,
  },
}));
