import moment from "moment";
import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  duties: [],
  users: [],
  calendarRange: {
    from: moment().clone().toISOString(),
    to: moment().clone().add(1, "month").toISOString(),
  },
  filters: {
    type: undefined,
    position: undefined,
    aircraft: [],
  },
};
/* -------------------------------------------------------------------------- */
const crewCalendarSlice = createSlice({
  name: "crewCalendar",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setDuties: (state, action) => {
      state.duties = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setCalendarRange: (state, action) => {
      state.calendarRange = { ...state.calendarRange, ...action.payload };
    },
    setCalendarFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const { actions } = crewCalendarSlice;

export const { setDuties, setUsers, setCalendarRange, setCalendarFilters } =
  actions;

export const selCalendarDuties = (state) => state.crewCalendar.duties;
export const selCalendarUsers = (state) => state.crewCalendar.users;
export const selCalendarRange = (state) => state.crewCalendar.calendarRange;
export const selCalendarFilters = (state) => state.crewCalendar.filters;

const reducer = {
  crewCalendar: crewCalendarSlice.reducer,
};

export default reducer;
