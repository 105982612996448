import * as R from "ramda";
export const userFormModel = {
  birth_date: null,
  homebase_one: null,
  homebase_two: null,
  gender: "",
  nationality: "",
  groups: [],
  code: "",
  departments: [],
  email: "",
  human_email: "",
  tags: [],
  can_login: "",
  ftl_at_the_time_of_joining: {
    twentyEightDays: "0000:00",
    oneMonth: "0000:00",
    twelveMonths: "0000:00",
    oneYear: "0000:00",
    date: new Date(),
  },
};

export const remUserData = R.pick([
  "modified",
  "first_name",
  "middle_name",
  "last_name",
  "known_as",
  "username",
  "email",
  "password",
  "birth_date",
  "birth_place",
  "homebase_one",
  "gender",
  "address",
  "homebase_two",
  "telephone",
  "cell_phone",
  "nationality",
  "groups",
  "departments",
  "tags",
  "code",
  "ftl_at_the_time_of_joining",
]);
export const remUserDataCreate = R.pick([
  "first_name",
  "middle_name",
  "last_name",
  "known_as",
  "username",
  "email",
  "password",
  "birth_date",
  "birth_place",
  "homebase_one",
  "homebase_two",
  "gender",
  "address",
  "telephone",
  "cell_phone",
  "nationality",
  "groups",
  "code",
  "ftl_at_the_time_of_joining",
]);

export const userGeneralForm = {
  first_name: "",
  middle_name: "",
  last_name: "",
  known_as: "",
  username: "",
  email: "",
  human_email: "",
  password: "",
  birth_date: new Date(),
  birth_place: "",
  homebase_one: "",
  gender: "",
  address: "",
  homebase_two: "",
  code: "",
  ftl_at_the_time_of_joining: {
    twentyEightDays: "00:00",
    oneMonth: "00:00",
    twelveMonths: "00:00",
    oneYear: "00:00",
    date: new Date(),
  },
};
