import { SvgIcon } from "@material-ui/core";

export const FleetIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.563 3.22557C21.3357 2.37237 19.419 2.14365 17.285 2.71461L7.86039 5.24733L3.94551 2.98653L2.40039 3.40053L4.00071 6.28413L3.22839 6.49125L3.43527 7.26381C5.39391 8.39589 7.62759 8.62701 9.65415 8.08341L10.8503 7.76157L10.0869 10.4006L11.6322 10.0365L14.7254 6.71925L19.659 5.39205C20.9378 5.04909 21.7919 4.07877 21.563 3.22557Z" />
      <path d="M18.3999 9.59961V13.5997L19.1998 14.3996V21.5996H20.7999V14.3996L21.5998 13.5997V9.59961H18.3999Z" />
      <path d="M13.6005 16.0006V13.6006H2.40039V21.6005H17.6003V16.0006H13.6005ZM4.00047 15.2007H5.60055V16.8005H4.00047V15.2007ZM7.20039 20.0007H5.60031V18.4006H7.20039V20.0007ZM7.20039 15.2007H8.80047V16.8005H7.20039V15.2007ZM10.4003 20.0007H8.80023V18.4006H10.4003V20.0007ZM12.0004 16.8005H10.4003V15.2007H12.0004V16.8005Z" />
    </SvgIcon>
  );
};
