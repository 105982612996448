import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  IconButton,
  CardMedia,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { createBugReport } from "store/slices/thunks";
import Helper from "app/components/modules/HelperModal";
import { Modal } from "app/components/common/Modal";
import { Button } from "app/components/common/Button";
import { TextField } from "app/components/common/TextField";
import { useFileManager, useForm } from "hooks";
import { UploadIcon } from "app/components/common/Icons";
import { CloudUploadRounded } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { toast } from "react-toastify";

function BugReportModal({ open, onClose }) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const {
    FileManager,
    openFileManager,
    filesData,
    onRemoveFile,
    onMultipleUpload,
    onClearMultipleFile,
  } = useFileManager();
  const { data, errors, handleChange, handleSubmit, initFormData } = useForm({
    initialValues: {
      description: "",
    },
    validations: {
      description: {
        required: {
          value: true,
          message: "This field is required!",
        },
      },
    },
    onSubmit: () => {
      handleBugReport();
    },
  });
  const dispatch = useDispatch();
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    return () => {
      initFormData();
      onClearMultipleFile();
    };
  }, []);

  /* -------------------------------------------------------------------------- */

  const handleBugReport = async () => {
    setLoading(true);
    let attachments = undefined;
    if (filesData.length) {
      let res = await onMultipleUpload();
      if (res.error) {
        setLoading(false);
        return;
      }
      attachments = res?.message.map((item) => item.id);
    }
    dispatch(
      createBugReport({ ...data, attachments }, handleBugReportCallback)
    );
  };

  const handleBugReportCallback = (error) => {
    if (!error) {
      toast.success("Bug report sent");
      handleClose();
    }
    setLoading(false);
  };

  const renderAttachments = useCallback(() => {
    if (!filesData.length) return;
    return (
      <div className={classes.totalCardMedia}>
        {filesData.map((fd, index) => {
          let { file, url } = fd;
          return (
            <Grid key={url}>
              <IconButton
                size={"small"}
                onClick={() => onRemoveFile(url)}
                className={classes.closePicture}
              >
                <HighlightOffIcon />
              </IconButton>
              <CardMedia
                className={classes.cardMedia}
                component="img"
                alt="Contemplative Reptile"
                image={url}
                title={file.name}
              />
            </Grid>
          );
        })}
      </div>
    );
  }, [filesData]);

  const handleClose = () => {
    initFormData();
    onClearMultipleFile();
    onClose();
  };

  // [bugDescription] = detailsBug;

  /** Cleans TextField input to preferable value */
  const textfieldSanitize = (e) => e.target.value;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={"Report a bug"}
      headerContent={<Helper helperKey={"bugReportModal"} />}
      content={
        <form>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                error={errors.description}
                multiline
                rows={7}
                value={data.description}
                className={classes.multiline}
                onChange={handleChange("description", textfieldSanitize)}
              />
            </Grid>
            <FileManager multiple>
              <Button
                className={classes.uploadButton}
                type="button"
                variant="contained"
                color="primary"
                maxWidth="50%"
                onClick={openFileManager}
                startIcon={<CloudUploadRounded />}
              >
                Upload Files
              </Button>
            </FileManager>
            <Grid container>{renderAttachments()}</Grid>
          </Grid>
        </form>
      }
      actions={
        <>
          <Button
            style={{ marginRight: "2%", height: "33px" }}
            type="button"
            variant="contained"
            color="secondary"
            maxWidth="50%"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Send
          </Button>
        </>
      }
    />
  );
}
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  multiline: {
    "& .MuiInputBase-root": {
      height: 90,
    },
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  totalCardMedia: {
    position: "relative",
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    height: "171px",
    padding: theme.spacing(0.5),
    width: "512px",
  },
  cardMedia: {
    maxHeight: "130px",
    marginLeft: theme.spacing(1),
    marginBlock: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "auto",
    maxWidth: 250,
  },
  closePicture: {
    position: "absolute",
    top: 2,
    zIndex: 10,
    fill: `${theme.palette.common.white} !important`,
    background: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.common.white,
    },
  },
}));

export default BugReportModal;
