import { uniqBy } from "lodash";
import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  positionTypes: ["COCKPIT", "CABIN", "MAINTENANCE", "GROUND", "POSITIONING"],
  positions: [],
  aircrafts: [],
  crewPositions: [],
  crewPositionGroups: {},
};

const crewPositionSlice = createSlice({
  name: "crewPosition",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    initPositions: (state) => {
      state.positions = [];
    },
    setPositions: (state, action) => {
      state.positions = action.payload;
    },
    setAc: (state, action) => {
      state.ac = action.payload;
    },
    setAirCrafts: (state, action) => {
      state.aircrafts = action.payload;
    },
    setCrewPositions: (state, action) => {
      state.crewPositions = action.payload;
    },
    setCrewPositionGroups: (state, action) => {
      state.crewPositionGroups = action.payload;
    },
  },
});

const { actions } = crewPositionSlice;

export const {
  initPositions,
  setAc,
  setAirCrafts,
  setPositions,
  setCrewPositions,
  setCrewPositionGroups,
} = actions;

export const selAc = (state) => state.crewPosition.ac;
export const selAircrafts = (state) => state.crewPosition.aircrafts;
export const selPositions = (state) => state.crewPosition.positions;
export const selCrewPositions = (state) => state.crewPosition.crewPositions;
export const selCrewPositionGroups = (state) =>
  state.crewPosition.crewPositionGroups;
export const selPositionTypes = (state) => state.crewPosition.positionTypes;

export const resetPositions = () => (dispatch) => {
  return dispatch(initPositions());
};

const reducer = {
  crewPosition: crewPositionSlice.reducer,
};

export default reducer;
