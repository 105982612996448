import { catcher } from "./masterApi";
import { setLoaderApp, setLoaderProgress } from "store/loader/loaderSlice";
import * as R from "ramda";
import store from "store";
import { isBlob } from "react-pdf/dist/umd/shared/utils";

const setLoading = setLoaderApp;

const dispatch = store.dispatch;

let requestCount = 0; // Keep track of the ongoing API requests

let interval = null;
const clientLoader = async (method, link, data, config) => {
  try {
    if (requestCount === 0) {
      let progress = 0;
      dispatch(setLoading(true)); // Set loading to true only if no ongoing requests
      dispatch(setLoaderProgress(progress));
      interval = setInterval(() => {
        progress += 5;
        if (progress > 95) {
          clearInterval(interval);
          progress = 90;
        }
        dispatch(setLoaderProgress(progress));
      }, 100);
    }
    requestCount++;

    const res = await catcher(method, link, data, config);
    requestCount--;
    if (requestCount === 0) {
      clearInterval(interval);
      dispatch(setLoaderProgress(100));
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 1000);
    }
    return isBlob(res) ? res : { ...res };
  } catch (error) {
    requestCount--;
    if (requestCount === 0) {
      dispatch(setLoading(false)); // Set loading to false if all requests are completed
      dispatch(setLoaderProgress(0));
      clearInterval(interval);
    }
  }
};
const clientNoLoaderCathcer = async (method, link, data, params) => {
  try {
    const res = await catcher(method, link, data, params);
    return isBlob(res) ? res : { ...res };
  } catch (error) {
    console.log(11, { catcher: error });
    return error;
  }
};

export const clientNoLoader = R.curryN(4, clientNoLoaderCathcer);

export const loaderWithSetter = (setter) =>
  R.curryN(4, async (method, link, data, params) => {
    // debugger;
    setter(true);
    const res = await catcher(method, link, data, params);
    setter(false);
    // debugger;
    return { ...res };
  });
/* -------------------------------------------------------------------------- */
const loaderClient = R.curryN(4, clientLoader);
export default loaderClient;
