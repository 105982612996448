import { useEffect, useState } from "react";

export const useSearch = ({ items = [], keys = [] }) => {
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState(items);
  useEffect(() => {
    setFiltered(items);
  }, [items]);

  const onSearch = (value) => {
    let lowerValue = String(value).toLowerCase();
    setSearch(value);
    if (value.length == 0) {
      setFiltered(items);
    } else {
      setFiltered(
        items.filter((item) =>
          keys.some((key) => String(item[key]).toLowerCase().match(lowerValue))
        )
      );
    }
  };

  return { search, filtered, onSearch };
};
