import { createSlice } from "@reduxjs/toolkit";
import { INIT } from "./constants";
import { resetStore } from "store/actions";

const initialState = {
  status: INIT,
  vals: {},
  user: {},
  user_assignments: null,
  loading: false,
  token: null,
  refresh: null,
  secret: null,
  stash: {
    welcomeMessage: "",
    token: null,
    refresh: null,
    user: null,
  },
  role: {},
  currentUserAssignment: {},
  countries: [],
  modules: [],
  isModuleActive: true,
  bugReportedDescription: null,
};

const baseSlice = createSlice({
  name: "base",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAppStatus: (state, action) => {
      state.status = action.payload;
    },
    setBaseUser: (state, action) => {
      state.user = action.payload;
    },
    setUserAssignments: (state, action) => {
      state.user_assignments = action.payload;
    },

    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setIsActiveModule: (state, action) => {
      state.isModuleActive = action.payload;
    },

    setStash: (state, action) => {
      const curStash = state.stash;
      const reqStash = { ...curStash, ...action.payload };
      state.stash = reqStash;
    },
    clearStash: (state, action) => {
      state.stash = initialState.stash;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setTokens: (state, action) => {
      const { token, refresh } = action.payload;
      state.token = String(token).includes("Bearer")
        ? token
        : `Bearer ${token}`;
      state.refresh = refresh;
    },
    setBaseAirline: (state, action) => {
      state.currentUserAssignment.airline = action.payload;
    },
    setBaseUserAssignment: (state, action) => {
      state.currentUserAssignment = action.payload;
    },
    setAirlineBaseModule: (state, action) => {
      state.modules = action.payload;
    },
    setBugReported: (state, action) => {
      state.bugReportedDescription = action.payload;
    },
  },
});

export const { actions } = baseSlice;
export const {
  setLoading,
  setTokens,
  setBaseUser,
  setUserAssignments,
  setStash,
  clearStash,
  setAppStatus,
  setRole,
  setBaseAirline,
  setBaseUserAssignment,
  setCountries,
  setAirlineBaseModule,
  setIsActiveModule,
  setBugReported,
} = actions;

export const addTokens =
  ({ token, refresh }) =>
  (dispatch) => {
    return dispatch(setTokens({ token, refresh }));
  };

export const addUserBase = (user) => (dispatch) => {
  return dispatch(setBaseUser(user));
};

// selectors
export const selAppStatus = (state) => state.base.status;
export const selUser = (state) => state.base.user;
export const selUserAssignments = (state) => state.base.user_assignments;

export const selBaseStatus = (state) => state.base.status;
export const selLoading = (state) => state.base.loading;
export const selTokens = (state) => ({
  token: state.base.token,
  refresh: state.base.refresh,
});
export const selSecret = (state) => state.base.currentUserAssignment.secret;
export const selBaseAirline = (state) =>
  state.base.currentUserAssignment.airline;
export const selAirlineId = (state) =>
  state.base.currentUserAssignment.airline.id;
export const selBaseUserAssignment = (state) =>
  state.base.currentUserAssignment;
export const selStash = (state) => state.base.stash;
export const selAppStatusIs = (status) => (state) =>
  state.base.status === status;
export const selBaseUser = (state) => state.base.user;
export const selBaseCountries = (state) => state.base.countries;
export const selAirlineBaseModules = (state) => state.base.modules;
export const selIsActiveModule = (state) => state.base.isModuleActive;
export const selBugReported = (state) => state.base.bugReportedDescription;

const reducer = {
  base: baseSlice.reducer,
};

export default reducer;
