export const fleetFormModel = {
  // ******BASIC*******//
  //  AIRCRAFT TYPE
  status: "active",
  aircraft: "",
  name: "",
  iata: "",
  icao: "",
  short_name: "",
  registration: "",
  selcal: "",
  phone: {
    code: "",
    number: "",
  },
  sat_phone: "",
  // crew
  cockpit_crew: "",
  cabin_crew: "",
  rest_facility: "",
  maximum_cargo: "",
  // HOME BASE
  homebase: "",
  year_of_production: "",
  serial_number: "",

  // UNITS
  weight_unit: "",
  fuel_unit: "",
  fuel_uplift_unit: "",
  // maximum takeoff weight (MTOW)
  mtow: "",
  operator_icao_code: "",
  address: "",
  // *************//

  // ******OPS*******//
  table_view_color: "#000000",
  default_flight_rule: "",
  default_flight_type: "",
  default_icao_type: "",
  icao_noise_category: "",
  rff_category: "",
  minimum_runway_length: "",
  // fuel
  fuel_type: "",
  maximum_fuel: "",
  burn_on_taxi: "",

  // flightNumber
  default_flight_number: "",
  increase_by_one: false,

  // notes
  notes: "",
  calculation_model: "simple",
  acmi: false,
  // status: "active",

  // *************//

  // ******performance*******//
  average_fuel_consumption: "",
  climb_rate: "",
  descent_rate: "",
  max_flight_level: "",
  minimum_ground_time: "",
  max_range_with_zero_pax: "",
  max_range_with_max_pax: "",
  ifr_route_factor: "",
  // distance_profiles: [],

  //Seat Classes
  seat_class: [],
  pax_capacity: "",
};

// {
//   distance: null,
//   miles_to_climb: null,
//   climb_time: "",
//   cruise_speed: null,
// },

export const basicTabInit = {
  aircraft: "",
  status: "active",
  name: "",
  iata: "",
  icao: "",
  short_name: "",
  registration: "",
  selcal: "",
  phone: {
    code: "",
    number: "",
  },
  sat_phone: "",
  // crew
  cockpit_crew: "",
  cabin_crew: "",
  rest_facility: "",
  maximum_cargo: "",
  // HOME BASE
  homebase: "",
  year_of_production: "",
  serial_number: "",

  acmi: false,
  // status: "active",

  // UNITS
  weight_unit: "",
  fuel_unit: "kg",
  // fuel_uplift_unit: "",
  // maximum takeoff weight (MTOW)
  mtow: "",
  operator_icao_code: "",
  address: "",
};

export const opsTabInit = {
  table_view_color: "#000000",
  default_flight_rule: "",
  icao_noise_category: "",
  rff_category: "",
  minimum_runway_length: "",
  fuel_type: "",
  maximum_fuel: "",
  burn_on_taxi: "",
  default_flight_number: "",
  increase_by_one: false,
  notes: "",
  default_flight_type: "",
  default_icao_type: "",
};

export const performanceTabInit = {
  average_fuel_consumption: "",
  climb_rate: "",
  descent_rate: "",
  max_flight_level: "",
  max_range_with_zero_pax: "",
  max_range_with_max_pax: "",
  minimum_ground_time: "",
  ifr_route_factor: "",
  calculation_model: "simple",
  // distance_profiles: [],
};

export const seatTabInit = {
  // class: '',
  // code: '',
  // seats: 0,
};

// props

export const basicProps = [
  "aircraft",
  "acmi",
  "iata",
  "icao",
  "status",
  "name",
  "phone",
  "short_name",
  "registration",
  "selcal",
  "sat_phone",
  "cockpit_crew",
  "cabin_crew",
  "rest_facility",
  "maximum_cargo",
  "homebase",
  "year_of_production",
  "serial_number",
  "weight_unit",
  "fuel_unit",
  "fuel_uplift_unit",
  "mtow",
  "operator_icao_code",
  "address",
];

export const opsProps = [
  "table_view_color",
  "default_flight_rule",
  "icao_noise_category",
  "rff_category",
  "minimum_runway_length",
  "fuel_type",
  "maximum_fuel",
  "burn_on_taxi",
  "default_flight_number",
  "increase_by_one",
  "notes",
  "default_flight_type",
  "default_icao_type",
];

export const performanceProps = [
  "average_fuel_consumption",
  "climb_rate",
  "descent_rate",
  "max_flight_level",
  "max_range_with_zero_pax",
  "max_range_with_max_pax",
  "minimum_ground_time",
  "distance_profiles",
  "calculation_model",
  "ifr_route_factor",
];

export const seatProps = ["pax_capacity"];

export const allListProps = [
  ...basicProps,
  ...opsProps,
  ...performanceProps,
  ...seatProps,
];

// aircraft: {
//   type: mongoose.Schema.Types.ObjectId,
//   required: false,
//   ref: "Aircraft",
// },

// asking :
// Aircraft. Crew. Maintenance. Insurance
// acmi: {
//   type: mongoose.Schema.Types.ObjectId,
//   required: false,
//   ref: "Airline",
// },
// mzfw   // Maximum Zero Fuel Weight
//
