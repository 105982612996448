import { cloneDeep } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
// ---------------------------------------- Initial State ----------------------------------------
const initialState = {
  tripList: [],
  flightForm: {},
  list: [],
  selectedFlights: [],
  selectedFlightsId: [],
  timelineRange: {
    from: moment().clone().startOf("day").toISOString(),
    to: moment().clone().add(1, "month").toISOString(),
  },
  eventMenuData: { type: null, payload: null },
  fleet: [],
  readyToFly: [],
  timezone: "utc",
};
// ---------------------------------------- Slice ----------------------------------------
const flightScheduleSlice = createSlice({
  name: "flightSchedule",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setTripList: (state, action) => {
      state.tripList = action.payload;
    },
    createFlight: (state, action) => {},
    setFlightScheduleList: (state, action) => {
      state.list = action.payload;
    },
    setSelectedFlights: (state, action) => {
      state.selectedFlights = action.payload;
    },
    updateSchedFlights: (state, action) => {
      let newFlights = [];
      const { trips: payload, modify = true } = cloneDeep(action.payload);
      let hasSelectedFlights = modify && Boolean(state.selectedFlights?.length);
      if (Array.isArray(payload)) {
        newFlights = payload;
      } else if (typeof payload === "object") {
        newFlights = [payload];
      } else {
        return toast.error("Flights type is invalid");
      }
      //Modifing Selected Trips
      let updatedTripList = newFlights;
      let prevTripList = new Map();
      if (modify) {
        prevTripList = new Map(state.tripList.map((t) => [t.id, t]));
      }
      newFlights = newFlights.map((uF) => {
        uF.scheduled_time_of_departure =
          state.timezone == "utc"
            ? moment.utc(uF.scheduled_time_of_departure)
            : moment.utc(uF.scheduled_time_of_departure).local();
        uF.scheduled_time_of_arrival =
          state.timezone == "utc"
            ? moment.utc(uF.scheduled_time_of_arrival)
            : moment.utc(uF.scheduled_time_of_arrival).local();
        if (modify) prevTripList.set(uF.id, uF);
        return uF;
      });
      updatedTripList = modify
        ? Array.from(prevTripList, ([id, newTrip]) => newTrip)
        : newFlights;

      if (hasSelectedFlights) state.selectedFlights = newFlights;
      state.tripList = updatedTripList;
    },
    setTimelineRange: (state, action) => {
      state.timelineRange = action.payload;
    },
    setEventMenuData: (state, action) => {
      state.eventMenuData = action.payload;
    },
    setFleet: (state, action) => {
      state.fleet = action.payload;
    },
    setReadyToFly: (state, action) => {
      state.readyToFly = action.payload;
    },
    setTimeZone: (state, action) => {
      state.timezone = action.payload;
      state.tripList.map((flight) => {
        if (!flight.is_deleted) {
          if (action.payload == "utc") {
            flight.scheduled_time_of_departure = moment.utc(
              flight.scheduled_time_of_departure
            );
            flight.scheduled_time_of_arrival = moment.utc(
              flight.scheduled_time_of_arrival
            );
          } else {
            flight.scheduled_time_of_departure = moment
              .utc(flight.scheduled_time_of_departure)
              .local();
            flight.scheduled_time_of_arrival = moment
              .utc(flight.scheduled_time_of_arrival)
              .local();
          }
        }
      });
      state.selectedFlights.map((flight) => {
        if (action.payload == "utc") {
          flight.scheduled_time_of_departure = moment.utc(
            flight.scheduled_time_of_departure
          );
          flight.scheduled_time_of_arrival = moment.utc(
            flight.scheduled_time_of_arrival
          );
        } else {
          flight.scheduled_time_of_departure = moment
            .utc(flight.scheduled_time_of_departure)
            .local();
          flight.scheduled_time_of_arrival = moment
            .utc(flight.scheduled_time_of_arrival)
            .local();
        }
      });
      state.fleet.forEach((fleet) => {
        fleet.flights.forEach((flight) => {
          if (!flight.is_deleted) {
            flight.scheduled_time_of_departure =
              action.payload == "utc"
                ? moment.utc(flight.scheduled_time_of_departure)
                : moment.utc(flight.scheduled_time_of_departure).local();
            flight.scheduled_time_of_arrival =
              action.payload == "utc"
                ? moment.utc(flight.scheduled_time_of_arrival)
                : moment.utc(flight.scheduled_time_of_arrival).local();
          }
        });
      });
    },
  },
});
// ---------------------------------------- Selectors ----------------------------------------
export const selFlightScheduleSlice = (state) => state.flightSchedule;
export const selSelectedFlights = (state) =>
  state.flightSchedule.selectedFlights;
export const selScheduleTripList = (state) => state.flightSchedule.tripList;
export const selTimelineRange = (state) =>
  cloneDeep(state.flightSchedule.timelineRange);
export const selEventMenuData = (state) => state.flightSchedule.eventMenuData;
export const selFleet = (state) => state.flightSchedule.fleet;
export const selReadyToFly = (state) => state.flightSchedule.readyToFly;
export const selTimeZone = (state) => state.flightSchedule.timezone;
// ---------------------------------------- Actions ----------------------------------------
export const {
  setTripList,
  createFlight,
  setFlightScheduleList,
  updateTripList,
  setFlightsId,
  removeFlightsId,
  setSelectedFlights,
  setTimelineRange,
  setEventMenuData,
  setFleet,
  updateSchedFlights,
  setReadyToFly,
  setTimeZone,
} = flightScheduleSlice.actions;
// --------------------------------------------------------------------------------
const reducer = {
  flightSchedule: flightScheduleSlice.reducer,
};

export default reducer;
