import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import { remUserData, userFormModel } from "./models/userModels";

import { ALLFITLERS, EDITUSER, NEWUSER } from "./userConstants";
import { resetStore } from "store/actions";

const initialState = {
  status: NEWUSER,
  formAssignments: {},
  id: "",
  // list
  list: [],
  pendList: [],
  pendFilters: {
    limit: "20",
    page: 1,
  },
  airlineUserList: {
    users: [],
    totalItems: undefined,
  },

  airlineGroups: [],
  airports: [],

  allList: [],
  asc: true,
  sort: "date",
  filter: {
    limit: "20",
    page: 1,
    code: "",
    is_active: 1,
    group: "",
    status: "",
  },
  page: 1,
  listSize: 20,
  //
  tags: [],
  countries: [],
  //  form
  form: userFormModel,
  tags: [],
  curUser: {
    user: {},
    userAssignments: [],
  },
  //
  groups: [],
};
//

const filterByPath = (path, value) => {
  return R.filter((item) => R.pathOr(null, path)(item) === value);
};

const sortByPropTitle = (prop, asc) =>
  asc
    ? R.sortWith([R.ascend(R.compose(R.identity, R.pathOr("N/A", prop)))])
    : R.sortWith([R.descend(R.compose(R.identity, R.pathOr("N/A", prop)))]);

const filterByPropValue = (prop, val) =>
  R.filter((item) => R.prop(prop)(item) === val);

function filterListByPath(state, action) {
  // const {path,crew} = action.payload;

  const newGroup =
    action.payload.group !== "" ? action.payload.group : ALLFITLERS;

  const newStatus =
    action.payload.status !== "" ? action.payload.status : ALLFITLERS;

  const isAllGroup = newGroup === ALLFITLERS;
  const isAllStatus = newStatus === ALLFITLERS;

  let list = state.allList;
  let newList = R.filter((item) => {
    const groupCondition = isAllGroup
      ? true
      : R.pathOr(null, ["role"])(item) === newGroup;
    const statusCondition = isAllStatus
      ? true
      : R.pathOr(null, ["is_deleted"])(item) === newStatus;
    return statusCondition && groupCondition;
  })(list);

  state.list = newList;
  state.filter.group = newGroup;
  state.filter.status = newStatus;
}

function sortingList(state, action) {
  const list = state.list;
  const sort = state.sort;
  const asc = state.asc;
  const bysort = action.payload;
  const bySortTitle = action.payload[0];

  if (sort !== bySortTitle) {
    const newSorted = sortByPropTitle(bysort, true)(list);
    state.list = newSorted;
    state.asc = true;
    state.sort = bySortTitle;
  } else {
    const newSorted = sortByPropTitle(bysort, !asc)(list);

    state.list = newSorted;
    state.asc = !asc;
  }
  state.page = 1;
}

const getAirlineAssignments = (assignments = [], airlineId) => {
  return assignments.find((assignment) => assignment.airline.id === airlineId);
};

const serachProp = (prop, val) =>
  R.compose(R.includes(val), R.toLower, R.propOr("N/A", prop));

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setUser: (state, action) => {
      let {
        birth_date,
        gender,
        nationality,
        ftl_at_the_time_of_joining,
        email,
      } = action.payload.user;
      let userAirlineAssignment = getAirlineAssignments(
        action.payload?.user_assignments,
        action.payload.airlineId
      );
      let userForm = { ...state.form, birth_date, gender, nationality, email };
      console.log({ userAirlineAssignment }, action.payload?.user_assignments);
      if (userAirlineAssignment) {
        userForm.hamun_email = userAirlineAssignment.email;
        userForm.code = userAirlineAssignment.code;
        userForm.homebase_one = userAirlineAssignment.homebases[0]
          ? userAirlineAssignment.homebases[0].id
          : "";
        userForm.homebase_two = userAirlineAssignment.homebases[1]
          ? userAirlineAssignment.homebases[1].id
          : "";
        userForm.can_login = userAirlineAssignment.can_login;
        userForm.groups = userAirlineAssignment?.groups.map(
          (group) => group.id
        );
        userForm.departments = userAirlineAssignment?.departments.map(
          (group) => group.id
        );
        userForm.tags = userAirlineAssignment?.tags.map((tag) => tag.id);
        state.formAssignments = userAirlineAssignment;
      }
      if (ftl_at_the_time_of_joining?.length) {
        userForm.ftl_at_the_time_of_joining = ftl_at_the_time_of_joining[0];
      }
      state.form = userForm;
      state.id = action.payload.user.id;
    },

    setForminit: (state, action) => {
      state.form = userFormModel;
      state.status = NEWUSER;
      state.id = "";
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setTagsInUsers: (state, action) => {
      state.tags = action.payload;
    },
    setCountriesInUser: (state, action) => {
      state.countries = action.payload;
    },
    setHomebases: (state, action) => {
      state.homebases = action.payload;
    },
    setAirlineGroups: (state, action) => {
      const permissions = action.payload?.filter((permission) => {
        return permission.title !== "admin";
      });
      state.airlineGroups = permissions;
    },
    setGroupsInUsers: (state, action) => {
      state.groups = action.payload;
    },

    handleFormChange: (state, action) => {
      const { name, value, key } = action.payload;
      if (!key) state.form[name] = value;
      else state.form[name][key] = value;
    },
    setUserTags: (state, action) => {
      //  state.tags = value;
    },

    // List

    setUserList: (state, action) => {
      // state.status = NEWTAG;
      // state.tag = { id: "", description: "" };
      state.list = action.payload;
      state.allList = action.payload;
    },

    setPendUserList: (state, action) => {
      state.pendList = action.payload;
    },

    setPendUser: (state, action) => {
      state.pendUser = action.payload;
    },

    setAirlineUserList: (state, action) => {
      state.airlineUserList = action.payload;
    },

    setUserPage: (state, action) => {
      state.page = action.payload;
    },

    resetUserList: (state, action) => {
      const allList = state.allList;
      state.list = allList;
      state.filter.group = "";
      state.filter.status = "";
    },

    setUserListSize: (state, action) => {
      state.page = 1;
      state.listSize = action.payload;
    },
    sortUserListBy: sortingList,

    filterUserList: (state, action) => {
      const all = state.allList;
      const { name, value } = action.payload;
      if (value === "all") {
        state.list = all;
      } else {
        const filtered = filterByPropValue(name, value)(all);
        state.list = filtered;
      }
      state.page = 1;
      state.sort = "date";
      state.asc = true;
    },
    filterUserByText: (state, action) => {
      const text = action.payload;
      let list = state.allList;
      let newList = R.filter((item) => {
        const codeCondition = serachProp("username", text)(item);
        const firstCondition = serachProp("first_name", text)(item);
        const lastCondition = serachProp("last_name", text)(item);

        return codeCondition || firstCondition || lastCondition;
      })(list);
      state.list = newList;
      state.filter.group = "";
      state.filter.status = "";
    },

    filterUser: filterListByPath,

    setCurrentUser: (state, action) => {
      state.curUser = action.payload;
    },
    initCurrentUser: (state, action) => {
      state.curUser = {
        user: {},
        userAssignments: [],
      };
    },

    initFormAssignment: (state, action) => {
      console.log("initFormAssignment");
      state.formAssignments = {};
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
    setUserTableFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    setPendUserTableFilters: (state, action) => {
      state.pendFilters = { ...state.pendFilters, ...action.payload };
    },
  },
});

export const { actions } = userSlice;
export const {
  setStatus,
  setUser,
  handleFormChange,
  setUserTags,
  setForminit,
  setTagsInUsers,
  setCountriesInUser,
  setHomebases,
  setAirlineGroups,
  setGroupsInUsers,
  // list
  setUserList,
  setPendUserList,
  setPendUser,
  setUserListSize,
  setAirlineUserList,
  setUserPage,
  filterUserList,
  sortUserListBy,
  setCurrentUser,
  initCurrentUser,
  initFormAssignment,
  setAirports,
  filterUserByText,
  resetUserList,
  filterUser,
  setUserTableFilter,
  setPendUserTableFilters,
} = actions;

export const onUserFormChange = (name, value, key) => (dispatch) => {
  console.log("deparmentONCHANGE", name, value, key);
  return dispatch(handleFormChange({ name, value, key }));
};

export const userForminit = () => (dispatch) => {
  return dispatch(setForminit());
};

// export const sendTagForEdit = (item) => (dispatch) => {
//   return dispatch(setTagToEdit(item));
// };
// export const resetTagForm = () => (dispatch) => {
//   return dispatch(setFormInit());
// };

// selectors
export const selUsersList = (state) => state.users.list;
export const selPendUserList = (state) => state?.users?.pendList;
export const selPendUserTableFilters = (state) => state?.users?.pendFilters;
export const selPendUser = (state) => state?.users?.pendUser;
export const selUserPage = (state) => state.users.page;
export const selUsersSize = (state) => state.users.listSize;
export const selUserSort = (state) => state.users.sort;
export const selUserasc = (state) => state.users.asc;

export const selUsersStutus = (state) => state.users.status;
export const selUserId = (state) => state.users.id;
export const selTagsInUsers = (state) => state.users.tags;
export const selCountriesInUser = (state) => state.users.countries;
export const selHomebases = (state) => state.users.homebases;
export const selAirlineGroups = (state) => state.users.airlineGroups;
export const selAirlineUserList = (state) => state.users.airlineUserList;

export const selUserFormStatus = (state) => state.users.status;
export const selUserform = (state) => state.users.form;
export const selUserAssignments = (state) => state.users.formAssignments;
export const selGroupsInUser = (state) => state.users.groups;
export const selCurrentUser = (state) => state.users.curUser;
export const selAirports = (state) => state.users.airports;

export const selUserTableFilter = (state) => state.users.filter;
export const selTags = (state) => state.users.tags;

const reducer = {
  users: userSlice.reducer,
};

export default reducer;
