import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

// export const initialEndorsementState = {
//   aircrafts: [],
//   positions: [],
//   expiration_warning: false,
//   validity_time: undefined,
//   revalidation_window: undefined,
//   round_expiry_date: false,
//   is_deleted: false,
//   airline: undefined,
//   name: undefined,
//   type: undefined,
//   expiring_action: undefined,
//   created_by: undefined,
//   created_time: undefined,
//   updated_time: undefined,
//   id: undefined,
// };

const initialState = {
  ratingList: undefined,
  activeRatingTab: false,
  selectedAircraftId: undefined,
  userAssignmentId: undefined,
  // currentEndorsement: initialEndorsementState,
};

const crewRatingSlice = createSlice({
  name: "crewRating",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    initRating: (state) => {
      // state.currentEndorsement = initialEndorsementState;
    },
    setRatingList: (state, action) => {
      state.RatingList = action.payload;
    },
    setActiveRatingTab: (state, action) => {
      state.activeRatingTab = action.payload?.navigate;
      state.selectedAircraftId = action.payload?.aircraftId;
      state.userAssignmentId = action.payload?.userAssignmentId;
    },
  },
});

const { actions } = crewRatingSlice;

export const {
  initRating,
  setRatingList,
  setActiveRatingTab,
  // setCurrentRating,
} = actions;

export const selRatingList = (state) => state.crewRating.RatingList;
export const selActiveRatingTab = (state) => state.crewRating.activeRatingTab;
export const selUserAssignmentId = (state) => state.crewRating.userAssignmentId;
export const selSelectedAircraftId = (state) =>
  state.crewRating.selectedAircraftId;
// export const selCurrentRating = (state) =>
//   state.crewRating.currentRating;

export const resetRating = () => (dispatch) => {
  return dispatch(initRating());
};

const reducer = {
  crewRating: crewRatingSlice.reducer,
};

export default reducer;
