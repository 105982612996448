/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { userSignInV3 } from "actions/Auth";

import {
  selAppStatusIs,
  selStash,
  selUserAssignments,
  setAppStatus,
} from "store/slices";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { HOLD, INIT, AIRLINE_SELECT } from "store/slices/constants";
import { saveStashAndRole } from "store/slices/thunks";
import { TextField } from "app/components/common/TextField";
import { Button } from "app/components/common/Button";
import { ArrowLeftIcon } from "app/components/common/Icons";
import { EnrouteLogo } from "app/components/common/Icons/Enroute/EnrouteLogo";
import Avatar from "app/components/modules/Avatar";
import Axios from "axios";
import { BASE_URL } from "api/axiosConfig";
/* -------------------------------------------------------------------------- */
export const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 356,
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    backgroundColor: theme.palette.common.white,
  },
  content: {
    position: "relative",
    zIndex: 2,
    display: "inherit",
    alignSelf: "center",
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    flexDirection: "row-reverse",
    justifyContent: "center",
  },
  gridContainer: {
    // minWidth: 400,
    maxWidth: 400,
    width: `calc(100% - ${theme.spacing(3)}px)`,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    paddingBlock: theme.spacing(4),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    gap: theme.spacing(3),
  },
  actionsContainer: {
    gap: theme.spacing(1),
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  logo: {
    width: "395px",
    height: "100%",
    color: theme.palette.common.white,
  },
  logoBg: {
    minWidth: "370px",
    height: "100%",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "center",
  },
  selectContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  actionGrid: {
    height: "100%",
    marginBottom: `-${theme.spacing(4)}px !important`,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    paddingInline: theme.spacing(1),
    maxHeight: "300px",
    height: "100%",
    overflowY: "auto",
    border: `1px solid ${theme.palette.grey["lighter"]}`,
    borderRadius: theme.shape.borderRadius,
  },
  backButton: {
    bottom: "0px",
    left: "0px",
    position: "absolute",
  },
  resend: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(0.5),
  },
  footer: {
    width: "400px",
    position: "fixed",
    bottom: theme.spacing(3),
    textAlign: "center",
  },
  airlineCard: {
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.grey["lighter"]}`,
    },
    gap: theme.spacing(1),
  },
}));
/* -------------------------------------------------------------------------- */
const SignIn = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const user_assignments = useSelector(selUserAssignments);
  console.log({ user_assignments });
  const isInit = useSelector(selAppStatusIs(INIT));
  const isRole = useSelector(selAppStatusIs(AIRLINE_SELECT));
  const isHOLD = useSelector(selAppStatusIs(HOLD));
  if (isHOLD) return <></>;

  const handleAirline = (item) => {
    dispatch(saveStashAndRole(item));
  };

  const handleSignIn = (e) => {
    e?.preventDefault();
    setLoading(true);
    dispatch(
      userSignInV3({ email, password }, () => {
        setLoading(false);
      })
    );
  };
  /* -------------------------------------------------------------------------- */
  const renderLoginForm = () => {
    return (
      isInit && (
        <form onSubmit={handleSignIn} className={classes.form}>
          <TextField
            label={<IntlMessages id="appModule.usernameOrEmail" />}
            fullWidth
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <TextField
            type="password"
            label={<IntlMessages id="appModule.password" />}
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <div className={classes.actionsContainer}>
            <Button
              type="submit"
              style={{ width: "100%" }}
              onClick={handleSignIn}
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              <IntlMessages id="appModule.login" />
            </Button>
            <Link to="/forgot-password">
              <Typography
                color="primary"
                style={{ height: 34, display: "flex", alignItems: "center" }}
              >
                <IntlMessages id="appModule.forgotpassword" />
              </Typography>
            </Link>
          </div>
        </form>
      )
    );
  };
  const renderAirlines = () => {
    return user_assignments?.map((user_assignment) => {
      let { airline, userAssignment } = user_assignment;
      let groups = userAssignment.groups
        .map((group) => group.title)
        .join(" / ");
      console.log({ airline });
      return (
        <AirlineItem
          key={airline.id}
          disabled={
            airline.is_deleted ||
            !(userAssignment.can_login && airline.is_enabled)
          }
          onClick={() => handleAirline(user_assignment)}
          name={airline.name}
          groups={groups}
          logo={airline.logo}
        />
      );
    });
  };

  const renderAirlineSelector = () => {
    return (
      isRole && (
        <>
          <List className={classes.listContainer}>{renderAirlines()}</List>
          <div
          // className={classes.actionsContainer}
          >
            <Button
              startIcon={<ArrowLeftIcon color={"primary"} />}
              variant={"text"}
              style={{ width: "100%" }}
              onClick={() => {
                dispatch(setAppStatus(INIT));
              }}
              color="primary"
            >
              Back to Login
            </Button>
          </div>
        </>
      )
    );
  };
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <div className={`${classes.logoBg}`}>
          <Link to="/">
            <EnrouteLogo className={classes.logo} />
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5} className={classes.content}>
        <div className={classes.gridContainer}>
          <Typography variant="h4">
            <IntlMessages
              id={isInit ? "appModule.login" : "appModule.selectAirline"}
            />
          </Typography>
          {renderLoginForm()}
          {renderAirlineSelector()}
          <MainFooter />
        </div>
      </Grid>
    </Grid>
  );
};

export default SignIn;
/* -------------------------------------------------------------------------- */
const AirlineItem = memo(({ disabled, onClick, name, groups, logo }) => {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState();
  const { token } = useSelector(selStash);
  useEffect(() => {
    if (logo)
      Axios.get(`${BASE_URL}/filemanager/download/public/${logo}`, {
        responseType: "blob",
        headers: {
          resource: "Enroute user dashboard",
          Authorization: token,
        },
      })
        .then((res) => {
          let url = URL.createObjectURL(res.data);
          setImageUrl(url);
        })
        .catch((err) => {});
  }, [logo]);

  return (
    <ListItem
      disabled={disabled}
      button
      onClick={onClick}
      className={classes.airlineCard}
    >
      <Avatar alt={name} src={imageUrl} />
      <ListItemText secondary={name} primary={groups} />
    </ListItem>
  );
});
/* -------------------------------------------------------------------------- */
export const MainFooter = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography variant={"subtitle1"}>
        &copy; 2024 ENROUTE SOFTWARE ALL RIGHTS RESERVED | Enroute Software
      </Typography>
    </footer>
  );
};
