import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { resetStore } from "store/actions";
/* -------------------------------------------------------------------------- */
const initialState = {
  list: [],
  filteredList: [],
};

const aircraftsSlice = createSlice({
  name: "aircrafts",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setAircrafts: (state, action) => {
      state.list = action.payload;
    },
    setFilteredAircrafts: (state, action) => {
      state.filteredList = action.payload;
    },
  },
});

export const { actions } = aircraftsSlice;
export const { setAircrafts, setFilteredAircrafts } = actions;

// selectors

export const selAircrafts = (state) => state.aircrafts.list;
export const selFilteredAircrafts = (state) => state.aircrafts.filteredList;

const reducer = {
  aircrafts: aircraftsSlice.reducer,
};

export default reducer;
