export const shadows = [
  "none", //#0
  "-1px 4px 15px 0px rgba(0,0,0,0.05)", //#1 Cards & Boxes
  "-1px 4px 15px 0px rgba(0,0,0,0.1)", //#2 Headers & Toolbars & PaperCards
  "-1px 4px 15px 0px rgba(0,0,0,0.15)", //#3
  "-1px 4px 15px 0px rgba(0,0,0,0.2)", //#4 Focused Card

  "0px 5px 8px 0px rgba(0,0,0,0.15)", //#5 Table Head
  "6px 2px 8px 0px rgba(0,0,0,0.15)", //#6 Table Cell

  "-2px 2px 15px 0px rgba(0,0,0,0.2)", //#7 Contained Buttons
  "0px 0px 10px 0px rgba(0,0,0,0.15)", //#8 Focused Buttons

  "-1px 4px 10px 0px rgba(0,0,0,0.05)", //#9 Horizontal Tab Bar
  "6px 6px 10px 0px rgba(0,0,0,0.1)", //#10 MoreButtons
  "-1px 1px 15px 0px rgba(0,0,0,0.2)", //#11 Popover
  "0px 5px 5px 0px rgba(0,0,0,0.05)", //#12 Vertical Tab Bar
];
