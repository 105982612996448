import { mapFieldArrToObjSchema } from "hooks/useDValidation";

import * as R from "ramda";

export const signUpFormModel = {
  first_name: "",
  middle_name: "",
  last_name: "",
  phone: "",
  cell_phone: "",
  username: "",
  known_as: "",
  email: "",
  gender: "Male",
  birth_place: "",
  birth_date: "2017-05-24",
  address: "",
  nationality: "",
  password: "",
};

export const pickerSignup = R.pick([
  "first_name",
  "last_name",
  "middle_name",
  "phone",
  "cell_phone",
  "username",
  "known_as",
  "email",
  "gender",
  "birth_place",
  "birth_date",
  "address",
  "nationality",
  "password",
]);

const generalForm = [
  ["first_name", "string"],
  ["last_name", "string"],
  ["middle_name", "string"],
  ["phone", "string"],
  ["cell_phone", "string"],
  ["username", "string"],
  ["known_as", "string"],
  ["email", "string"],
  ["gender", "string"],
  ["birth_place", "string"],
  ["birth_date", "string"],
  ["address", "string"],
  ["nationality", "string"],
  ["password", "string"],
];

const allGeneralForm = [...generalForm];

export const signUpFormFields = mapFieldArrToObjSchema(allGeneralForm);
