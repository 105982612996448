// import * as R from "ramda";
import loaderClient from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import { setAirportList } from "./airportSlice";

/* -------------------------------------------------------------------------- */
export const getAirports = (params, callback) => async (dispatch) => {
  const client = loaderClient("get")(`/airports?&${params}`);
  const res = await client({})({});
  if (res.error) return;
  dispatch(setAirportList(res));
  return callback && callback();
};
