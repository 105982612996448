import React, { useEffect } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import store, { StoreProvider, history } from "./store";
import App from "./containers/App";
import { ToastContainer } from "react-toastify";
import { AIRLINE_SELECT, VERIFIED } from "store/slices/constants";
import { ErrorBoundary, Sentry } from "util/sentry";
/* -------------------------------------------------------------------------- */
let storagePersist = false;
/* -------------------------------------------------------------------------- */
const MainApp = () => {
  useEffect(() => {
    window.addEventListener("storage", (event) => {
      // Check if the storage event is relevant to your Redux state
      if (event.key === "persist:root") {
        storagePersist = true;
        const innerJSONString = JSON.parse(event.newValue);
        const { status } = JSON.parse(innerJSONString.base);
        if (status === VERIFIED) return;
        window.location.reload();
        return;
      }
      if (storagePersist) {
        window.location.reload();
      }
    });
    // console.log({ localStorage: JSON.parse(localStorage.getItem("persist:root")) });
    //Listener for token change in storage
    // window.onstorage = (event) => {
    //   let { status, token } = JSON.parse(JSON.parse(event.storageArea["persist:root"])?.base);
    //   console.log({ status, token });
    //   if (!token) return;
    //   if (status === VERIFIED) return;
    //   window.location.reload();
    // };
    return () => {
      //Clear storage listener
      window.onstorage = null;
    };
  }, []);

  return (
    <>
      <StoreProvider>
        <ErrorBoundary>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </ConnectedRouter>
        </ErrorBoundary>
      </StoreProvider>
      <ToastContainer />
    </>
  );
};

export default Sentry.withProfiler(MainApp);
