import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  CellPhoneIcon,
  LocationIcon,
  MailInfoIcon,
  UploadIcon,
} from "app/components/common/Icons";
import Avatar from "app/components/modules/Avatar";
import userPlaceHolder from "assets/images/placeholder.jpg";
import { useFileManager } from "hooks/useFileManager";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { selBaseAirline } from "store/slices";
import { selAirline } from "store/slices/airline/airlineSlice";
import {
  getAirlineById,
  updateAirlineById,
} from "store/slices/airline/airlineThunks";
import { allModules } from "store/slices/modules/module.mode";
import {
  selModsOBJ,
  setAirlineInModules,
} from "store/slices/modules/modulesSlice";
import { getListModulesByAirlineId, initAirline } from "store/slices/thunks";
// ======================S T Y L E S=====================
const useStyles = makeStyles((theme) => ({
  noWrap: {
    flexWrap: "nowrap",
  },
  tabsStyle: {
    color: `${theme.palette.main["100"]} !important`,
    borderBottom: "1px solid ",
    borderColor: `${theme.palette.main["40"]} !important`,
    width: "100%",

    "& .MuiTabs-indicator": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      height: "4px",
      width: "461px !important",
      marginRight: "16px !important",
    },

    "& .MuiTab-root": {
      display: "flex",
      justifyContent: "space-around",
      color: `${theme.palette.primary["100"]} !important`,
      maxWidth: "461px !important",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      maxWidth: "461px !important",
      width: "461px",
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
  airlineAvatar: {
    marginRight: "8px",
  },
  avatar: {
    width: "50px !important",
    height: "50px !important",
    cursor: "pointer",
  },
  airlineName: {
    color: theme.palette.main["100"],
  },
  airlineConnectionContainer: {
    "& .MuiGrid-root": {
      display: "block",
    },
  },
  airlineConnection: {
    "& .MuiGrid-root": {
      display: "grid",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  boxTab: {
    borderBottom: 1,
    borderColor: `${theme.palette.main["20"]} !important`,
  },
  leftGrid: {
    marginRight: "1%",
    border: "1px solid",
    borderColor: `${theme.palette.black["20"]} !important`,
    borderRadius: "4px",
    maxWidth: "29% !important",
    flexBasis: "29% !important",
  },
  rightGrid: {
    border: "1px solid",
    borderColor: `${theme.palette.black["20"]} !important`,
    borderRadius: "4px",
    maxWidth: "68.667% !important",
    flexBasis: "68.667% !important",
  },
  totalLeftRightGrids: {
    // display: "flex",
    // justifyContent: "center",
    paddingLeft: "0px  !important",
    paddingRight: "0px !important",
  },
  avatarGrid: {
    position: "relative",
    display: "grid",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: "100px",
      height: "100px",
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
  typographyStyle: {
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: "12px",
    color: `${theme.palette.black["80"]} !important`,
  },
  Title: {
    fontSize: "20px",
    fontWeight: 500,
    color: `${theme.palette.black["80"]} !important`,
  },
  DividerStyleLeft: {
    width: "88%",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "16px",
    marginBottom: "16px",
    "& .MuiDivider-root": {
      backgroundColor: `${theme.palette.black["20"]} !important`,
    },
  },
  dividerStyleRight: {
    width: "95%",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "34px",
    marginBottom: "32px",
    "& .MuiDivider-root": {
      backgroundColor: `${theme.palette.black["20"]}`,
    },
  },
  homeBaseTitle: {
    // color: `${theme.palette.black["80"]} !important`,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "18.75px",
    marginLeft: "24px",
    marginBottom: "16px",
  },
  subHomeBase: {
    border: "1px solid",
    borderColor: `${theme.palette.black["20"]} !important`,
    borderRadius: "4px",
    width: "480px",
    marginLeft: "24px",
    marginBottom: "16px",
    paddingTop: "9px",
    paddingBottom: "9px",
    paddingLeft: "16px",
  },
  airlineInfoStyle: {
    border: "1px white",
    borderColor: `${theme.palette.black["20"]} !important`,
    borderRadius: "4px",
    width: "270px",
    marginLeft: "24px",
    marginTop: "32px",
    marginBottom: "28px",
    display: "flex",
    justifyContent: "space-between",
  },
  airlineInfoTitleStyle: {
    fontSize: "14px",
    fontWeight: 400,
    color: `${theme.palette.black["40"]} !important`,
  },
  detailInfo: {
    fontSize: "16px",
    fontWeight: 400,
    color: `${theme.palette.black["100"]} !important`,
  },
  uploadButton: {
    position: "absolute",
    padding: "6px",
    right: "16px !important",
    bottom: "9px",
    background: `${theme.palette.primary.main} !important`,
    "& .MuiSvgIcon-root": {
      color: `${theme.palette.common.white} !important`,
    },
  },
  viewModdulesTitleActive: {
    color: `${theme.palette.black["100"]} !important`,
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "16px",
    marginTop: "24px",
    // marginLeft: "16px"
  },
  viewModdulesTitleInactive: {
    color: `${theme.palette.black["100"]} !important`,
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "16px",
    marginTop: "16px",
    // marginLeft: "16px"
  },
  viewModdulesBoxTitleActive: {
    color: `${theme.palette.black["100"]} !important`,
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "16px",
    marginTop: "16px",
    marginLeft: "12.25px",
  },
  viewModdulesBoxTitleInactive: {
    color: `${theme.palette.black["60"]} !important`,
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "16px",
    marginTop: "16px",
    marginLeft: "12.25px",
  },
  viewModdulesDetailTitle: {
    color: `${theme.palette.black["40"]} !important`,
    fontWeight: 400,
    fontSize: "14px",
    marginBottom: "6px",
    marginLeft: "12.25px",
  },
  card: {
    display: "flex",
    width: "304px",
    height: "154px",
    marginRight: "16px",
    border: "1px solid",
    borderRadius: "5px",
    borderColor: `${theme.palette.main["40"]} !important`,
    marginBottom: "24px",
  },
  tapCardActive: {
    width: "8.54px",
    height: "154px",
    backgroundColor: "#65C4AD",
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  tapCardInactive: {
    width: "8.54px",
    height: "154px",
    backgroundColor: `${theme.palette.black["20"]} !important`,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  totalGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typoColor: {
    color: `${theme.palette.black["100"]} !important`,
  },
  customStyle: {
    "& .MuiBox-root": {
      padding: "0px !important",
    },
  },
}));
// ======================================================
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export const ViewModules = () => {};

const AirlineModalInfo = memo(() => {
  const { onUpload, fileData, openFileManager, FileManager } = useFileManager({
    type: "image",
  });
  const [value, setValue] = useState(0);
  const SelectBaseAirline = useSelector(selBaseAirline);
  const airlineInfo = useSelector(selAirline);
  const homebase = airlineInfo.homebases || [];
  console.log({ airlineInfo, SelectBaseAirline });
  const allModsOBJ = useSelector(selModsOBJ);
  const [arrayModulesActive, setArrayModulesActive] = useState([]);
  const [arrayModulesInactive, setArrayModulesInactive] = useState([]);

  const modsKeys = allModules;
  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListModulesByAirlineId());
    dispatch(setAirlineInModules(id));
    dispatch(getAirlineById());
  }, [id]);

  useEffect(() => {
    renderTableRow();
  }, [airlineInfo]);

  useEffect(() => {
    if (fileData.file) {
      onUpload().then((res) =>
        dispatch(updateAirlineById(airlineInfo.id, { logo: res.id }))
      );
    }
  }, [fileData.file]);

  const renderTableRow = () => {
    let inactives = [];
    let actives = [];

    modsKeys.map((k) => {
      const mod = allModsOBJ[k];
      const data = {
        title: k,
        key: k,
        ...mod,
      };
      console.log("datttaaaaa", data);
      if (data?.enabled === false) {
        inactives.push(data);
        setArrayModulesInactive(inactives);
      } else if (data?.enabled === true) {
        if (data.module_status.days_remaining !== 0) {
          actives.push(data);
        } else if (data.module_status.days_remaining === 0) {
          inactives.push(data);
        }

        console.log("in", actives);
        setArrayModulesActive(actives);
      }
    });
    return [actives, inactives];
  };

  const classes = useStyles();
  const menuItems = [{ name: "Information" }, { name: "View Modules" }];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={classes.boxTab}>
        <Tabs
          className={classes.tabsStyle}
          value={value}
          onChange={handleChange}
        >
          <Tab label="Information" />
          <Tab label="View Modules" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid className={classes.totalLeftRightGrids} container>
          <Grid className={classes.leftGrid} xs={3}>
            <Grid className={classes.avatarGrid}>
              <Grid className={classes.avatarGrid}>
                <Avatar imageId={airlineInfo.logo} />
                <FileManager>
                  <IconButton
                    color="primary"
                    component="span"
                    onClick={openFileManager}
                    className={classes.uploadButton}
                  >
                    <UploadIcon />
                  </IconButton>
                </FileManager>
              </Grid>

              <Typography className={classes.Title}>
                {airlineInfo?.name}
              </Typography>
            </Grid>
            <Divider className={classes.DividerStyleLeft} />
            <Grid style={{ display: "block", margin: "10px" }} container>
              <Box style={{ display: "flex ", height: "50px" }}>
                <MailInfoIcon />
                <Typography className={classes.typographyStyle}>
                  {airlineInfo?.email}
                </Typography>
              </Box>
              <Box style={{ display: "flex ", height: "50px" }}>
                <CellPhoneIcon />
                <Typography className={classes.typographyStyle}>
                  {airlineInfo?.telephone?.code}{" "}
                  {airlineInfo?.telephone?.number}
                </Typography>
              </Box>
              <Box style={{ display: "flex ", height: "50px" }}>
                <LocationIcon />
                <Typography className={classes.typographyStyle}>
                  {airlineInfo?.address}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid className={classes.rightGrid} xs={8}>
            <Grid>
              <Grid
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      Name:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.name}
                    </Typography>
                  </Box>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      Knownas:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.known_as}
                    </Typography>
                  </Box>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      Uniqe code:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.unique_code}
                    </Typography>
                  </Box>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      Call sign:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.callsign}
                    </Typography>
                  </Box>
                </Grid>
                <Grid style={{ marginRight: "24px" }}>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      ICAO code:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.icao_code}
                    </Typography>
                  </Box>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      IATA code:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.iata_code}
                    </Typography>
                  </Box>
                  <Box className={classes.airlineInfoStyle}>
                    <Typography className={classes.airlineInfoTitleStyle}>
                      Status:
                    </Typography>
                    <Typography className={classes.detailInfo}>
                      {airlineInfo.is_enabled ? "Active" : "inactive"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider className={classes.dividerStyleRight} />
              <Grid>
                <Typography className={classes.homeBaseTitle}>
                  Homebases
                </Typography>
                {homebase?.map((item) => {
                  return (
                    <Box>
                      <Typography className={classes.subHomeBase}>
                        1.{item.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel className={classes.customStyle} value={value} index={1}>
        <Grid style={{ marginLeft: "16px" }}>
          <Grid>
            <Typography className={classes.viewModdulesTitleActive}>
              Active
            </Typography>
            {arrayModulesActive.map((index) => {
              return (
                <Grid style={{ display: "inline-flex" }}>
                  <Box className={classes.card}>
                    <Box className={classes.tapCardActive}></Box>
                    <Grid>
                      <Grid className={classes.totalGrid}>
                        <Typography
                          className={classes.viewModdulesBoxTitleActive}
                        >
                          {index.title.toUpperCase()}
                        </Typography>
                        <Typography className={classes.typoColor}>
                          {index.module_status?.days_remaining} days
                        </Typography>
                      </Grid>
                      <Divider
                        style={{
                          width: "270px",
                          marginLeft: "10.25px",
                        }}
                      />
                      <Grid style={{ marginTop: "16px" }}>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Expiration date:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.expiration_date).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Created at:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.created_time).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Modified at:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.updated_time).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Divider style={{ marginRight: "16px", width: "943px" }} />
          <Grid>
            <Typography className={classes.viewModdulesTitleInactive}>
              Inactive
            </Typography>

            {arrayModulesInactive.map((index) => {
              return (
                <Grid style={{ display: "inline-flex" }}>
                  <Box className={classes.card}>
                    <Box className={classes.tapCardInactive}></Box>
                    <Grid>
                      <Grid className={classes.totalGrid}>
                        <Typography
                          className={classes.viewModdulesBoxTitleInactive}
                        >
                          {index.title.toUpperCase()}
                        </Typography>
                        <Typography>
                          {index.module_status?.days_remaining} days
                        </Typography>
                      </Grid>
                      <Divider
                        style={{ width: "270px", marginLeft: "10.25px" }}
                      />
                      <Grid style={{ marginTop: "16px" }}>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Expiration date:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.expiration_date).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Created at:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.created_time).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                        <Grid
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            className={classes.viewModdulesDetailTitle}
                          >
                            Modified at:
                          </Typography>
                          <Typography className={classes.typoColor}>
                            {moment(index.updated_time).format("MM/DD/YYYY")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </CustomTabPanel>
    </>
  );
});
export default AirlineModalInfo;
