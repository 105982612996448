import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import { remUserData, signUpFormModel } from "./models/signup.model";

import { ALLFITLERS, EDITUSER, NEWUSER } from "./signupConstants";
import { resetStore } from "store/actions";

const initialState = {
  status: NEWUSER,
  id: "",
  form: signUpFormModel,
};
//

const signupSlice = createSlice({
  name: "signup",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setForminit: (state, action) => {
      state.form = signUpFormModel;
      state.status = NEWUSER;
      state.id = "";
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    handleFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },

    // List
  },
});

export const { actions } = signupSlice;
export const {
  setStatus,

  handleFormChange,
  setForminit,
} = actions;

export const onSignupFormChange = (key, value) => (dispatch) => {
  return dispatch(handleFormChange({ key, value }));
};

export const signupForminit = () => (dispatch) => {
  return dispatch(setForminit());
};

// selectors

export const selSignUpStutus = (state) => state.signup.status;
export const selSignupId = (state) => state.signup.id;
export const selSignupform = (state) => state.signup.form;

const reducer = {
  signup: signupSlice.reducer,
};

export default reducer;
