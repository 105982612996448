import { makeStyles } from "@material-ui/core";
import { Skeleton } from "app/components/common/Skeleton";
import { TableCell, TableRow } from "app/components/common/Table";
import React from "react";
/* -------------------------------------------------------------------------- */
export const TableSkeleton = ({ rows, cells = 0 }) => {
  const classes = useStyles();
  return (
    <Skeleton fragment>
      {Array(10)
        .fill()
        .map((a) => (
          <TableRow className={classes.tableRow}>
            {Array(cells)
              .fill()
              .map((a) => (
                <TableCell></TableCell>
              ))}
          </TableRow>
        ))}
    </Skeleton>
  );
};
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: `${theme.palette.black[5]} !important`,
      },
    },
  }),
  { name: "TableSkeleton" }
);
