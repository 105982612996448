import { Suspense, lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
/* ------------------------------ Lazy Imports ------------------------------ */
const Main = lazy(() => import("./routes/main"));
const Library = lazy(() => import("./routes/library"));
const Settings = lazy(() => import("./routes/settings"));
const AdminGroupAndPermissions = lazy(() =>
  import("./routes/adminGroupAndPermissions")
);
const PendUsers = lazy(() => import("./routes/pend-users"));
const Users = lazy(() => import("./routes/users-management"));
const SignUp = lazy(() => import("./routes/signup-form"));
const UserEdit = lazy(() => import("./routes/users-management/UsersEdit"));

const Fleet = lazy(() => import("./routes/fleet"));
const FleetEdit = lazy(() => import("./routes/fleet/fleetEdit"));
const Flights = lazy(() => import("./routes/flights/index"));
const Reports = lazy(() => import("./routes/reports"));
const GeneralSetting = lazy(() => import("./routes/generalSettings"));
const Seats = lazy(() => import("./routes/seats"));
const FleetDocument = lazy(() => import("./routes/fleetDocument"));
const CrewPosition = lazy(() => import("./routes/crew-position"));
const CrewCertificates = lazy(() => import("./routes/crew-certificates"));
const EmailTemplates = lazy(() => import("./routes/email-templates"));
const EmailTemplateEdit = lazy(() =>
  import("./routes/email-templates/templateEdit")
);
const FlightEditing = lazy(() => import("./routes/FlightEditing"));
const DefaultFlight = lazy(() => import("./routes/company-flight"));
const Sched = lazy(() => import("./routes/sched"));
const CrewCurrency = lazy(() => import("./routes/crew-currency"));
const CrewRatings = lazy(() => import("./routes/crew-ratings"));
const FTLSettings = lazy(() => import("./routes/ftl-settings"));
const Ticketing = lazy(() => import("./routes/ticketing"));
const Messaging = lazy(() => import("./routes/messaging"));
const Chat = lazy(() => import("./routes/ticketing/chat"));
const DutiesSetup = lazy(() => import("./routes/duties-setup"));
const CrewCalendar = lazy(() => import("./routes/crew-calendar"));
const AirlineModule = lazy(() => import("./routes/airlineModule"));
const OpsTimeline = lazy(() => import("./routes/flights/Timeline"));
const OpsCalendar = lazy(() => import("./routes/flights/Calendar"));

/* -------------------------------------------------------------------------- */
// import FleetCreate from "./routes/fleet/fleetCreate";
// const { user, isAuthenticated } = useAuth0();
const Dashboard = ({ match }) => {
  return (
    <>
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact path={`${match.url}`} component={Main} />
          <Route path={`${match.url}/library`} component={Library} />
          <Route path={`${match.url}/settings`} component={Settings} />
          <Route exact path={`${match.url}/flights`} component={Flights} />
          <Route
            path={`${match.url}/flights/timeline`}
            component={OpsTimeline}
          />
          <Route
            path={`${match.url}/flights/calendar`}
            component={OpsCalendar}
          />
          <Route exact path={`${match.url}/users/:id`} component={UserEdit} />
          <Route path={`${match.url}/users`} component={Users} />
          <Route
            path={`${match.url}/general-settings`}
            component={GeneralSetting}
          />
          <Route path={`${match.url}/seats`} component={Seats} />
          <Route path={`${match.url}/crew-position`} component={CrewPosition} />
          <Route
            path={`${match.url}/flight-editing`}
            component={FlightEditing}
          />
          <Route
            path={`${match.url}/flight-default`}
            component={DefaultFlight}
          />

          <Route
            path={`${match.url}/email-templates`}
            component={EmailTemplates}
          />
          <Route path={`${match.url}/messaging`} component={Messaging} />
          <Route path={`${match.url}/ticketing`} component={Ticketing} exact />
          <Route path={`${match.url}/ticketing/chat`} component={Chat} exact />
          <Route path={`${match.url}/crew-currency`} component={CrewCurrency} />

          <Route
            path={`${match.url}/sub-template/:id`}
            component={EmailTemplateEdit}
          />
          <Route
            path={`${match.url}/crew-certificates`}
            component={CrewCertificates}
          />

          <Route path={`${match.url}/crew-ratings`} component={CrewRatings} />
          <Route path={`${match.url}/ftl-settings`} component={FTLSettings} />
          <Route path={`${match.url}/duties-setup`} component={DutiesSetup} />
          <Route path={`${match.url}/crew-calendar`} component={CrewCalendar} />
          {/* 
          <Route
            path={`${match.url}/privileges`}
            component={GroupAndPermissions}
          /> */}
          <Route
            path={`${match.url}/admin-privileges`}
            component={AdminGroupAndPermissions}
          />
          <Route path={`${match.url}/sched`} component={Sched} />
          <Route exact path={`${match.url}/pend-users`} component={PendUsers} />
          {/* <Route exact path={`${match.url}/users`} component={UserEdit} /> */}
          <Route path={`${match.url}/fleet`} component={Fleet} />
          <Route path={`${match.url}/fleets/:id`} component={FleetEdit} />
          <Route path={`${match.url}/reports`} component={Reports} />

          <Route path={`${match.url}/fleets`} component={FleetEdit} />

          <Route path={`${match.url}/fleet-docs`} component={FleetDocument} />

          <Route path={`${match.url}/signup`} component={SignUp} />
          <Route
            path={`${match.url}/airlinemodule`}
            component={AirlineModule}
          />
          <Redirect exact from={`*`} to={`${match.url}`} />
        </Switch>
      </Suspense>
    </>
  );
};

export default Dashboard;
