import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getHelper } from "./slice/helperThunk";
import { setHelperDescription } from "./slice/helperSlice";
import { Modal } from "app/components/common/Modal";
import { Button } from "app/components/common/Button";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { HelpRounded } from "@material-ui/icons";
/* -------------------------------------------------------------------------- */
const Helper = memo(({ helperKey }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  console.log(111, { toggleHelper: open });
  const toggleHelper = useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      <IconButton onClick={toggleHelper}>
        <HelpRounded className={classes.icon} />
      </IconButton>
      {open && (
        <HelperModal
          helperKey={helperKey}
          isOpen={open}
          onClose={toggleHelper}
        />
      )}
    </>
  );
});
/* -------------------------------------------------------------------------- */
const HelperModal = memo(({ isOpen, onClose, helperKey }) => {
  const dispatch = useDispatch();

  const description = useSelector((state) => state.helpers.description);
  useEffect(() => {
    (async () => {
      const message = await getHelper(helperKey);
      const description = message?.[0]?.helper?.description;

      if (!description) return;

      dispatch(setHelperDescription(description));
    })();
  }, [helperKey]);

  return (
    <Modal
      title="Helper"
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      content={
        <Grid container>
          <Grid xs={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: decodeEntities(description ?? ""),
              }}
            ></div>
          </Grid>
        </Grid>
      }
      actions={
        <Button onClick={onClose} variant={"text"}>
          Close
        </Button>
      }
    />
  );
});

export default Helper;
/* -------------------------------------------------------------------------- */
const decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  const element = document.createElement("div");

  function RichTextEditor(str) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return RichTextEditor;
})();
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));
