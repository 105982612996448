/* eslint-disable react/jsx-no-bind */
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import Avatar from "app/components/modules/Avatar";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selBaseUserAssignment, selUserAssignments } from "store/slices";
import { saveStashAndRole } from "store/slices/thunks";
/* -------------------------------------------------------------------------- */
export const SwitchAirlines = ({ handleClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user_assignments = useSelector(selUserAssignments);
  const currentUserAssignment = useSelector(selBaseUserAssignment);
  const currentUserAssignmentId = currentUserAssignment?.["_id"];

  const handleSwitchAirline = (item) => {
    //TODO
    dispatch(saveStashAndRole(item));
    handleClose();
    history.push(`/`);
  };
  return user_assignments?.map((user_assignment) => {
    let { airline, userAssignment } = user_assignment;
    let groups = userAssignment.groups.map((group) => group.title).join(" / ");
    return (
      <AirlineItem
        selected={userAssignment["_id"] === currentUserAssignmentId}
        key={airline.id}
        disabled={
          airline.is_deleted ||
          !(userAssignment.can_login && airline.is_enabled)
        }
        onClick={() => handleSwitchAirline(user_assignment)}
        name={airline.name}
        groups={groups}
        logo={airline.logo}
      />
    );
  });
};

export const AirlineItem = memo(
  ({ disabled, selected, onClick, name, groups, logo }) => {
    const theme = useTheme();
    return (
      <ListItem
        disabled={disabled}
        button
        onClick={onClick}
        style={{
          border: selected && `1px solid ${theme.palette.primary.main}`,
        }}
      >
        <ListItemAvatar>
          <Avatar alt={name} imageId={logo} />
        </ListItemAvatar>
        <ListItemText
          // primary={groups}
          secondary={name}
        />
        {selected && (
          <CheckCircleRounded
            color="primary"
            style={{ transform: "scale(1.3)" }}
          />
        )}
      </ListItem>
    );
  }
);
