import { LinearProgress, makeStyles } from "@material-ui/core";
import { MAIN_HEADER_NAV_HEIGHT } from "containers/themes/attributes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "store";
import { selLoaderLoading, selLoaderProgress } from "store/loader/loaderSlice";
/* -------------------------------------------------------------------------- */
const PROGRESSBAR_HEIGHT = 4;
/* -------------------------------------------------------------------------- */
const ProgressBar = () => {
  const classes = useStyles();
  const progress = useSelector(selLoaderProgress);
  const isLoading = useSelector(selLoaderLoading);
  /* -------------------------------------------------------------------------- */
  return (
    isLoading && (
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={progress}
      />
    )
  );
};
export default ProgressBar;
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  progressBar: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: PROGRESSBAR_HEIGHT,
    backgroundColor: "transparent",
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
