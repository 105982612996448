import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { isEqual } from "lodash";
import clsx from "clsx";
import { globalStyles } from "containers/themes/globalClasses";
import { BoxSkeleton } from "app/components/modules/Skeletons";
import { INPUT_HEIGHT } from "containers/themes/attributes";
/* -------------------------------------------------------------------------- */
export const Selector = memo(
  ({
    label,
    inputLabel,
    options = [],
    placeholder,
    value,
    onChange,
    backgroundColor,
    renderValue,
    renderOptions,
    displayEmpty,
    hasNone,
    fullWidth,
    className,
    selectorClassName,
    style,
    loading,
    multiple = false,
    getOptionLabel,
    onInputChange,
    defaultValue,
    paperClass,
    rounded,
    noneOptionText = "All",
    error,
    helperText,
    disabled,
    readOnly,
    required,
    ...rest
  }) => {
    const classes = useStyles();
    console.log({ label, options });

    const handleChange = (e) => {
      if (e.target.value === noneOptionText) {
        e.target.value = "";
      }
      onChange(e);
    };
    const renderDefaultOptions = () => {
      if (multiple) return;
      return options.map((option, index) => {
        if (renderOptions) {
          return (
            <MenuItem
              key={index}
              className={`${selectorClassName} ${classes.selected}`}
              value={option}
            >
              {renderOptions(option)}
            </MenuItem>
          );
        }
        if (typeof option == "string")
          return (
            <MenuItem
              key={`${option}_${index}`}
              className={`${selectorClassName} ${classes.selected}`}
              value={option}
            >
              {option}
            </MenuItem>
          );
        return (
          <MenuItem
            key={`${option.value}_${index}`}
            className={`${selectorClassName} ${classes.selected}`}
            value={option?.id}
          >
            {option?.value}
          </MenuItem>
        );
      });
    };
    return (
      <FormControl
        disabled={readOnly || disabled}
        variant="outlined"
        required={required}
        style={{
          display: "flex",
          backgroundColor,
          ...(fullWidth && { width: "100%" }),
        }}
        className={`${classes.selector} ${rounded && classes.rounded} ${
          readOnly && classes.readOnly
        } ${className}`}
        error={error}
      >
        {label && <FormLabel>{label}</FormLabel>}
        {inputLabel && <InputLabel>{inputLabel}</InputLabel>}
        {loading ? (
          <BoxSkeleton
            rect={false}
            squareSize={{ width: "100%", height: INPUT_HEIGHT }}
          />
        ) : (
          <Select
            label={inputLabel}
            className={classes.select}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple={multiple}
            value={value}
            onChange={handleChange}
            fullWidth={fullWidth}
            displayEmpty={displayEmpty}
            renderValue={renderValue}
            MenuProps={{
              classes: {
                paper: clsx(classes.paper, paperClass),
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              getContentAnchorEl: null,
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
            }}
            inputProps={{ "aria-label": "Select" }}
          >
            {hasNone && (
              <MenuItem value={noneOptionText} disabled={placeholder}>
                <Typography>{placeholder || noneOptionText}</Typography>
              </MenuItem>
            )}
            {renderDefaultOptions()}
          </Select>
        )}
        {(helperText ?? error) && (
          <FormHelperText>{helperText ?? error}</FormHelperText>
        )}
      </FormControl>
    );
  },
  (prev, next) => {
    // for (const key in prev) {
    //   console.log("isEqual", next.label, key, isEqual(prev[key], next[key]), next[key]);
    // }
    return isEqual(prev, next);
  }
);
/* -------------------------------------------------------------------------- */

const useStyles = makeStyles(
  (theme) => ({
    selector: {
      "& .MuiInputBase-root": {
        height: 36,
        overflow: "hidden",
      },
      ...globalStyles(theme).formControl,
    },
    rounded: {
      "& .MuiInputBase-root": {
        borderRadius: 20,
        backgroundColor: "transparent",
        "& fieldset": {
          borderColor: "transparent",
          top: -4,
        },
        "& .MuiSelect-root": {
          justifyContent: "center",
        },
      },
    },
    selected: {
      "&.MuiMenuItem-root.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
      },
      autoInput: {
        "& .MuiInputBase-root": {
          // minHeight: 50,
          padding: 0,
        },
      },
    },
    paper: {
      marginTop: 4,
      maxHeight: 200,
      "& .MuiButtonBase-root:not(:last-child)": {
        borderBottom: `1px solid ${theme.palette.grey["light"]}`,
        height: INPUT_HEIGHT,
      },
      "& .MuiList-padding": {
        padding: 0,
      },
    },
    readOnly: globalStyles(theme).readOnly,
  }),
  {
    name: "Selector",
  }
);
/* ------------------------------- Prop Types ------------------------------- */
Selector.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  renderValue: PropTypes.func,
  renderOptions: PropTypes.func,
  displayEmpty: PropTypes.bool,
  hasNone: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  selectorClassName: PropTypes.string,
  style: PropTypes.object,
  multiple: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  onInputChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
