import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import {
  fleetFormModel,
  basicTabInit,
  opsTabInit,
  performanceTabInit,
} from "./fleetModels";

import { resetFormsReducers } from "./reducers";
import { NEWFLEET, EDITFLEET } from "./constants";
import {
  basicProps,
  allListProps,
  opsProps,
  performanceProps,
} from "./fleetModels";
import { resetStore } from "store/actions";

const initialState = {
  status: NEWFLEET,
  list: [],
  allList: [],
  asc: true,
  current: {},
  sort: "created_time:asc",
  id: "",
  page: 1,
  listSize: 5,
  totalItems: 0,
  form: fleetFormModel,
  basicTabEdit: basicTabInit,
  opsTabEdit: opsTabInit,
  performanceTabEdit: performanceTabInit,
  aircraftName: "",
  distanceProfilesIds: [],
  distance_profiles: [],
  countries: [],
  aircrafts: [],
  aircraftTypes: [],
  currentAircraft: { model: "" },
  filter: {
    page: 1,
    limit: 20,
    status: "",
    acmi: "",
    is_deleted: "",
    aircraft: "",
  },
  fleetDocumentfilter: {
    page: 1,
    limit: 20,
  },
  fleetDocumentTotalItems: 0,
};

const fleetSlice = createSlice({
  name: "fleet",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    ...resetFormsReducers,
    setList: (state, action) => {
      const { page, limit, totalItems, fleets } = action.payload;
      state.list = fleets;
      state.totalItems = totalItems;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    handleFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },

    setFleetSort: (state, action) => {
      state.sort = action.payload;
    },

    removeSeatClass: (state, action) => {
      const newState = [...state.form.seat_class];
      newState.splice(action.payload, 1);
      state.form.seat_class = [...newState];
    },

    setFleetPhoneCode: (state, action) => {
      state.form.phone.code = action.payload;
    },
    setFleetPhoneNumber: (state, action) => {
      state.form.phone.number = action.payload;
    },

    addNewSeatClass: (state, action) => {
      const sum = state.form.seat_class.reduce(function (prev, current) {
        return prev + current?.seats;
      }, 0);
      state.form.pax_capacity = sum;
    },
    setForminit: (state) => {
      state.form = fleetFormModel;
      state.current = initialState.current;
      state.status = NEWFLEET;
      state.id = "";
      state.distanceProfilesIds = [];
      state.distance_profiles = [];
      state.basicTabEdit = basicTabInit;
      state.opsTabEdit = opsTabInit;
      state.performanceTabEdit = performanceTabInit;
      state.aircraftName = "";
    },

    setAircraftTypes: (state, action) => {
      state.aircraftTypes = action.payload;
    },

    setAirCrafts: (state, action) => {
      state.aircrafts = action.payload;
    },
    setAircraft: (state, action) => {
      const aircraft = action.payload;
      state.aircraftName = aircraft.model_no;
      state.form.aircraft = aircraft.id;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setListSize: (state, action) => {
      state.page = 1;
      state.listSize = action.payload;
    },
    setCurrentAircraft: (state, action) => {
      state.currentAircraft = action.payload;
    },
    setFleet: (state, action) => {
      const data = action.payload;
      //convert kg to lbs
      if (data.weight_unit === "lbs") {
        data.mtow = Math.ceil(data.mtow * 2.205);
        data.maximum_cargo = Math.ceil(data.maximum_cargo * 2.205);
      }
      if (data.fuel_unit === "lbs") {
        data.maximum_fuel = Math.ceil(data.maximum_fuel * 2.205);
      }
      state.current = action.payload;
      state.aircraftName = data?.aircraft?.model_no;
      const newData = { ...fleetFormModel, ...data };
      state.form = {
        ...R.pick(allListProps)(newData),
        aircraft: data?.aircraft?.id || "",
        homebase: data?.homebase?.id,
        seat_class: data?.seat_class || [],
      };

      state.currentAircraft = data.aircraft;
      state.basicTabEdit = R.pick(basicProps)(data);
      state.opsTabEdit = R.pick(opsProps)(data);
      state.performanceTabEdit = R.pick(performanceProps)(data);
      state.id = data.id;
      state.distance_profiles = R.filter((item) => !item.is_deleted)(
        data.distance_profiles
      );

      state.distanceProfilesIds = R.map((item) => item.id)(
        R.filter((item) => !item.is_deleted)(data.distance_profiles)
      );
      state.status = EDITFLEET;
    },
    setFleetFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    initFleetFilters: (state, action) => {
      state.filter = initialState.filter;
    },
    setFleetDocumentFilter: (state, action) => {
      state.fleetDocumentfilter = {
        ...state.fleetDocumentfilter,
        ...action.payload,
      };
    },
    initFleetDocumentFilters: (state, action) => {
      state.fleetDocumentfilter = initialState.fleetDocumentfilter;
    },
    setFleetDocumentTotalItems: (state, action) => {
      state.fleetDocumentTotalItems = action.payload;
    },
  },
});

export const { actions } = fleetSlice;
export const {
  setFleetDocumentTotalItems,
  setFleetDocumentFilter,
  initFleetDocumentFilters,
  initFleetFilters,
  setFleetFilter,
  setList,
  setStatus,
  setForminit,
  handleFormChange,
  resetBasic,
  resetOPS,
  setFleetSort,
  resetPerformance,
  setAircraft,
  setPage,
  sortFleetListBy,
  setFleet,
  filterList,
  setListSize,
  setAirCrafts,
  setCurrentAircraft,
  addNewSeatClass,
  removeSeatClass,
  setFleetFilterAcmi,
  setFleetFilterIsDeleted,
  setFleetFilterStatus,
  setFleetPhoneCode,
  setFleetPhoneNumber,
  setAircraftTypes,
  setFleetFilterAircraftType,
} = actions;

export const onFleetFormChange = (key, value) => (dispatch) => {
  return dispatch(handleFormChange({ key, value }));
};
export const fleetForminit = () => (dispatch) => {
  return dispatch(setForminit());
};

// selectors

export const selFleetStatus = (state) => state.fleet.status;
export const selFleetList = (state) => state.fleet.list;
export const selFleetTotalItems = (state) => state.fleet.totalItems;
export const selFleetForm = (state) => state.fleet.form;
export const selAirCraftName = (state) => state.fleet.aircraftName;
export const selFleetId = (state) => state.fleet.id;
export const selDistanceProfiles = (state) => state.fleet.distance_profiles;
export const selCurrentFleet = (state) => state.fleet.current;
export const selFleetAirCraft = (state) => state.fleet.aircrafts;
export const selCurrentAirCraft = (state) => state.fleet.currentAircraft;
export const selAircraftTypes = (state) => state.fleet.aircraftTypes;
export const selFleetFilter = (state) => state.fleet.filter;
export const selFleetDocumentFilter = (state) =>
  state.fleet.fleetDocumentfilter;
export const selFleetDocumentTotalItems = (state) =>
  state.fleet.fleetDocumentTotalItems;

const reducer = {
  fleet: fleetSlice.reducer,
};

export default reducer;
