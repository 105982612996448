import { toast } from "react-toastify";
import loaderClient, { clientNoLoader } from "api/reduxLoaderApi";
import { setAirlineDelayCodes, setCustomDelayCodes } from "./delayCodesSlice";
import { selAirlineId } from "..";
/* -------------------------------------------------------------------------- */
export const getAirlineDelayCodes =
  (search, callback = false) =>
  async (dispatch, getState) => {
    let airlineId = selAirlineId(getState());
    let params = `?airline=${airlineId}&sortBy=code&limit=all`;
    if (!!search) {
      params += `&search=${search}`;
    }
    let client = loaderClient("get")(`/delay-codes${params}`);
    let res = await client({})({});
    if (res.error) return;
    dispatch(setAirlineDelayCodes(res.message));
    return callback && callback(res.message);
  };
/* -------------------------------------------------------------------------- */
export const getCustomDelayCodes =
  (search, callback = false) =>
  async (dispatch, getState) => {
    let airlineId = selAirlineId(getState());
    let params = `?airline=${airlineId}`;
    if (!!search) {
      params += `&search=${search}`;
    }
    const client = loaderClient("get")(`/delay-codes/custom${params}`);
    const res = await client({})({});
    if (res.error) return;
    dispatch(setCustomDelayCodes(res.message));
    return callback && callback(res.message);
  };
/* -------------------------------------------------------------------------- */
export const updateCustomDelayCodes =
  (customDelayCodes, callback) => async (dispatch, getState) => {
    let airline = selAirlineId(getState());
    const client = clientNoLoader("patch")(`/delay-codes/custom`);
    const res = await client({
      airline,
      customDelayCodes,
    })({});
    if (res.error) {
      callback?.(res.error);
      return;
    }
    dispatch(getCustomDelayCodes());
    return callback?.(res.error);
  };
/* -------------------------------------------------------------------------- */
