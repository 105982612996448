import axios, { invalidateUser, globalAxiosInstance } from "./axiosConfig";

import * as R from "ramda";
import { isBlob } from "react-pdf/dist/umd/shared/utils";
import store from "store";
import { selTokens, setTokens } from "store/slices";
/* -------------------------------------------------------------------------- */
let newRefresh = "";
export async function getNewAccessToken() {
  try {
    const { refresh: refreshToken } = selTokens(store.getState());

    if (!refreshToken) throw new Error("refreshToken not found");

    const res = await globalAxiosInstance.post(
      `/auth/refresh-tokens`,
      {
        refreshToken: newRefresh || refreshToken,
      },
      {
        headers: {
          design: "Enroute Admin panel",
        },
      }
    );
    const { message } = res.data;
    store.dispatch(
      setTokens({ token: message.access.token, refresh: message.refresh.token })
    );
    newRefresh = message.refresh.token;
    return message.access.token;
  } catch (error) {
    console.error("getNewAccessTokenError", { error });
    invalidateUser();
    throw error;
  }
}
/* -------------------------------------------------------------------------- */
const isFormData = (data) => data instanceof FormData;
export const masterApi = async (method, url, data = {}, config = {}) => {
  if (
    method !== "get" &&
    (!data || (!isFormData(data) && !Object.keys(data)?.length))
  )
    data = config;
  let res = null;
  try {
    if (method === "get") {
      config = data;
      console.log({ url, config, data });
      res = await axios[method](url, config);
    } else if (method === "delete") {
      res = await axios[method](url, { data }); //#TODO
    } else {
      res = await axios[method](url, data, config);
    }
    // Use the headers as needed
    return isBlob(res.data) ? res.data : { ...res.data, status: res.status };
  } catch (error) {
    const { response } = error;
    return { ...response?.data, status: response?.status };
  }
};
/* -------------------------------------------------------------------------- */
export const masterApiWithOptions = async ({ method, url, data, params }) => {
  method = method.toLowerCase();
  let res = { data: null };
  try {
    res = await axios[method](url, data);
    return res.data;
  } catch (error) {
    // console.error(error);
    return error.response && error.response;
  }
};

export const catcher = async (method, url, inData, config, ...args) => {
  try {
    const res = await masterApi(method, url, inData, config, ...args);

    if (isBlob(res)) return res;
    const { status } = res;
    switch (status) {
      case "axios_failed":
        return { data: null, status: "axios_failed" };

      case "success":
        return { ...res, status: "success" };

      case "failed":
        return { ...res, status: "failed" };

      default:
        return { ...res };
    }
  } catch (error) {
    console.log(111, { catcher: error });
    return error;
  }
};

export const client = async (method, url, data, config) => {
  const res = await masterApi(method, url, data, config);
  return { ...res };
};

const CatcherLoadingRedux = async (
  dispatch,
  loaderAction,
  method,
  link,
  data,
  params
) => {
  dispatch(loaderAction(true));
  const res = await client(method, link, data, params);
  dispatch(loaderAction(false));
  return { ...res };
};

const curClientLoader = R.curryN(6, CatcherLoadingRedux);

let curClient = R.curryN(4, client);

export { curClient, curClientLoader };
