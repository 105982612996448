import { EDITFLEET } from "./constants";
import { basicTabInit, opsTabInit, performanceTabInit } from "./fleetModels";

export const resetFormsReducers = {
  resetBasic: (state) => {
    const form = state.form;
    const status = state.status;
    const backup = state.basicTabEdit;
    state.form = { ...form, ...basicTabInit };
    status === EDITFLEET
      ? (state.form = { ...form, ...basicTabInit, ...backup })
      : (state.form = { ...form, ...basicTabInit });
  },

  resetOPS: (state) => {
    const form = state.form;
    const status = state.status;
    const backup = state.opsTabEdit;
    status === EDITFLEET
      ? (state.form = { ...form, ...opsTabInit, ...backup })
      : (state.form = { ...form, ...opsTabInit });
  },
  resetPerformance: (state) => {
    const form = state.form;
    const status = state.status;
    const backup = state.performanceTabEdit;
    status === EDITFLEET
      ? (state.form = { ...form, ...performanceTabInit, ...backup })
      : (state.form = { ...form, ...performanceTabInit });
  },
};
