import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  groupList: [],
  groupId: "",
  group: [],
  subTemp: {
    subTemplates: [],
  },
  filters: {
    aircrafts: "",
  },
};

const emailTemplateSlice = createSlice({
  name: "emailTemplates",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    // setGroupList: (state, action) => {
    //   state.groupList = action.payload;
    // },
    setEmailTemplateGroup: (state, action) => {
      state.group = action.payload;
    },
    getEmailSubTemplateGroup: (state, action) => {
      state.subTemp = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

const { actions } = emailTemplateSlice;
export const { setEmailTemplateGroup, getEmailSubTemplateGroup, setFilters } =
  actions;

const reducer = {
  emailTemplates: emailTemplateSlice.reducer,
};

// export const selEmailTemplateGroupList = (state) =>
//   state.emailTemplates.groupList;
export const selEmailTemplateGroup = (state) => state.emailTemplates.group;
export const emailSubTemplateGroup = (state) =>
  state.emailTemplates.subTemp.subTemplates;
export const selEmailTemplateFilters = (state) => state.emailTemplates.filters;

export default reducer;
