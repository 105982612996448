import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  list: [],
  tripTypes: [],
  icaoTypes: [],
  aircraftType: [],
  form: {},
  showModal: false,
  itemId: undefined,
  total: 20,
};
const defaultFlightSlice = createSlice({
  name: "defaultFlight",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setFlightList: (state, action) => {
      state.list = action.payload.message;
      state.total = action.payload.result_number;
    },
    setTripTypes: (state, action) => {
      state.tripTypes = action.payload?.airline.generalSetting.trip_types;
    },
    setIcaoTypes: (state, action) => {
      const icaoType = action.payload?.[0].values[0];
      const icaoTypes = Object.entries(icaoType).filter(
        ([key, value]) => (key = value)
      );
      state.icaoTypes = icaoTypes;
    },
    setFlightForm: (state, action) => {
      if (action.payload?.item?.id) {
        state.form = action.payload.item;
      } else {
        const { name, value } = action.payload;
        state.form[name] = value;
      }
    },
    setAircraftTypes: (state, action) => {
      state.aircraftType = action.payload;
    },
    setItemId: (state, action) => {
      state.itemId = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    resetState: (state) => {
      state.form = {};
    },
  },
});

export const { actions } = defaultFlightSlice;

export const {
  setFlightList,
  setTripTypes,
  setIcaoTypes,
  setFlightForm,
  setShowModal,
  setItemId,
  resetState,
} = actions;

export const selDefaultFlights = (state) => state.defaultFlights.list;
export const selTripTypes = (state) => state.defaultFlights.tripTypes;
export const selIcaoTypes = (state) => state.defaultFlights.icaoTypes;
export const selFlightForm = (state) => state.defaultFlights.form;
export const selAircraftType = (state) => state.defaultFlights.aircraftType;
export const selItemId = (state) => state.defaultFlights.itemId;
export const selTotalDefaultFlight = (state) => state.defaultFlights.total;

// export const selShowModal = (state) => state.defaultFlights.showModal;

const reducer = {
  defaultFlights: defaultFlightSlice.reducer,
};

export default reducer;
