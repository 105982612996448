import {
  setAirline,
  setAirlineSetting,
  setAirlineHomebases,
  setAirports,
  setEnums,
} from "./airlineSlice";

import loaderClient, { clientNoLoader } from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import { setGeneralSettingForm } from "app/routes/dashboard/routes/generalSettings/slice/generalSettingSlice";
import { downloadFileAPI } from "api/fileManagerApi";
import { selAirlineId, selBaseAirline, setBaseAirline } from "..";
import { initProfileV2 } from "../thunks";

export const getAirlineById = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(`airlines/${airlineId && airlineId}`);
  const res = await client({})({});
  if (res.error) return "";
  if (res?.message?.airline) {
    const airline = { ...res.message.airline };
    if (airline?.generalSetting) {
      setGeneralSettings(dispatch, airline.generalSetting);
    }
    return dispatch(setAirline(res.message.airline));
  }
};

const setGeneralSettings = (dispatch, generalSt) => {
  if (!generalSt) return;

  const default_weight_unit = generalSt.default_weight_unit || "";
  const default_fuel_unit = generalSt.default_fuel_unit || "";
  const crew_age_validation = generalSt.crew_age_validation || "";
  const default_quantity_unit_for_fuel_prices =
    generalSt.default_quantity_unit_for_fuel_prices || "";
  const trip_types = generalSt.trip_types || "";
  const deviate = generalSt.deviate || 0;
  const working_days = generalSt.working_days || "";
  const flight_max_delay = generalSt.flight_max_delay || "00:00";

  dispatch(
    setGeneralSettingForm({
      default_weight_unit,
      default_fuel_unit,
      crew_age_validation,
      default_quantity_unit_for_fuel_prices,
      trip_types,
      deviate,
      working_days,
      flight_max_delay,
    })
  );
};

export const getAirlineHomebases = (id) => async (dispatch) => {
  const client = loaderClient("get")(`airlines/${id}/homebases`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setAirlineHomebases(res.message));
};

export const getTripTypeEnums = () => async (dispatch) => {
  const client = loaderClient("get")(`enums?collection_name=flight-type`);
  const res = await client({})({});
  console.log("enum_client", client);
  console.log("enum_thunk", res);
  if (res.error) return;
  return dispatch(setEnums(res?.message));
};

/**
 *
 * @param {search, page, limit} filters
 * @param callback
 * @returns //airports
 */
export const getAirports = (filters, callback) => async (dispatch) => {
  let loader = filters?.loader ?? true;
  delete filters?.loader;
  let limit = filters?.limit || "10";
  if (filters?.limit) delete filters.limit;
  let params = `?limit=${limit}`;
  for (const key in filters) {
    if (filters[key]) {
      params += `&${key}=${filters[key]}`;
    }
  }
  const client = (loader ? loaderClient : clientNoLoader)?.("get")(
    `/airports${params}`
  );
  const res = await client({})({});
  if (res.error) return;
  dispatch(setAirports(res.message));
  callback && callback(res.message);
  return res.message;
};

export const getAirlineSetting = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(`/airlines/${airlineId}/settings`);
  const res = await client({})({});
  console.log("getairline", res);
  if (res.error) return;
  return dispatch(setAirlineSetting(res.message));
};

export const patchAirlineHomebase =
  (id, data, callback = false) =>
  async (dispatch) => {
    const homebaseList = data.map((item) => item.id);
    const client = loaderClient("patch")(`airlines/${id}/homebases`);
    const res = await client({ homebaseList })({});
    if (res.error) return;
    return callback && callback();
  };

export const patchAirlineWorkingDays = (id, WorkingDays) => {
  const client = loaderClient("patch")(`airlines/general-setting/${id}`);
  const res = client({ WorkingDays })({});
  console.log("resapi", res);
  if (res.error) return;
  return;
};

export const patchAirline =
  (id, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("patch")(`airlines/${id}/`);
    const res = await client({ ...data })({});
    if (res.error) return;
    return callback && callback();
  };

export const updateAirlineById =
  (airlineId, body, callback = false) =>
  async (dispatch, getState) => {
    const client = loaderClient("patch")(`/airlines/${airlineId}`);
    const res = await client(body)({});
    if (res.error) return;
    dispatch(setAirline(res.message));
    const baseAirline = selBaseAirline(getState());
    dispatch(setBaseAirline({ ...baseAirline, ...body }));
    dispatch(initProfileV2()); //TODO not clean (for updating user_assignments)
    toast.success("Change avatar Success");
    return;
  };
