import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core";
import { SearchIcon } from "../Icons";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    height: "32px",
    background: "transparent",
    border: `1px solid ${theme.palette.main["40"]}`,
    color: theme.palette.black["80"],
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
  },
  searchIcon: {
    color: theme.palette.main["100"],
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // borderRight: "1px solid",
  },
  inputRoot: {
    color: "inherit",
    backgroundColor: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export function Search() {
  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
}
