import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { airlineModel } from "./airlineModel";
import { resetStore } from "store/actions";
const initialState = {
  status: "init",
  airline: airlineModel,
  id: "",
  setting: {
    seat_class: [],
  },
  homebases: [],
  airports: [],
  tripTypeEnums: [],
};

const airlineSlice = createSlice({
  name: "airline",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBaseUser: (state, action) => {
      state.user = action.payload;
    },
    setUserAssignments: (state, action) => {
      state.user_assignments = action.payload || [];
    },

    setAirline: (state, action) => {
      state.airline = action.payload;
      state.id = action.payload?.id;
    },
    setAirlineSetting: (state, action) => {
      state.setting = action.payload;
    },
    setAirlineHomebases: (state, action) => {
      state.homebases = action.payload;
    },
    setEnums: (state, action) => {
      state.tripTypeEnums = action.payload?.[0]?.values?.[0] ?? [];
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
    addAirlineHomebase: (state, action) => {
      const index = state.homebases.findIndex(
        (el) => el?.id === action.payload?.id
      );
      if (index === -1) {
        state.homebases = [
          ...state.homebases,
          {
            ...action.payload,
            homebase_number: state.homebases.length + 1,
          },
        ];
      } else {
        toast.error(`${action.payload.name} is selected`);
      }
    },
    removeAirlineHomebase: (state, action) => {
      const newState = [...state.homebases];
      newState.splice(action.payload, 1);
      state.homebases = [...newState];
    },
  },
});

export const { actions } = airlineSlice;
export const {
  setLoading,
  setAirline,
  setUserAssignments,
  setAirlineSetting,
  setEnums,
  setAirlineHomebases,
  setAirports,
  addAirlineHomebase,
  removeAirlineHomebase,
} = actions;

// selectors

export const selAirline = (state) => state.airline.airline;
export const selAirlineId = (state) => state.airline.id;
export const selAirlineSetting = (state) => state.airline.setting;
export const selAirlineHomebases = (state) => state.airline.homebases;
export const selAirports = (state) => state.airline.airports;
export const selTripTypeEnums = (state) => state.airline.tripTypeEnums;

const reducer = {
  airline: airlineSlice.reducer,
};

export default reducer;
