import { Avatar as MuiAvatar, makeStyles } from "@material-ui/core";
import { downloadFileAPI } from "api/fileManagerApi";
import userPlaceHolder from "assets/images/placeholder.jpg";
import { isEqual } from "lodash";
import React from "react";
import useSWR from "swr";
/* -------------------------------------------------------------------------- */
let cachedImages = {};
/* -------------------------------------------------------------------------- */
const Avatar = React.memo(
  ({ className, imageId, onClick, src = "", ...rest }) => {
    const classes = useStyles();
    const { data = userPlaceHolder } = useSWR(imageId, fetchImage, {
      keepPreviousData: true,
      revalidateIfStale: true,
    });
    function fetchImage() {
      if (!imageId) return userPlaceHolder;
      if (cachedImages?.[imageId] && data) return cachedImages?.[imageId];
      return downloadFileAPI(imageId)
        .then((res) => {
          console.log({ avatar: res });
          let url = URL.createObjectURL(res);
          cachedImages[imageId] = url;
          return url;
        })
        .catch((err) => {
          return userPlaceHolder;
        });
    }

    return (
      <MuiAvatar
        onClick={onClick}
        className={`${classes.avatar} ${className}`}
        src={src || data}
        {...rest}
      />
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default Avatar;
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    avatar: {
      border: `1px solid ${theme.palette.grey["light"]}`,
      backgroundColor: theme.palette.common.white,
      borderRadius: 50,
      "& .MuiAvatar-img": {
        objectFit: "contain",
      },
    },
  }),
  { name: "ModuleAvatar" }
);
