import {
  FormControl,
  InputAdornment,
  IconButton,
  TextField as MUITextField,
  makeStyles,
  FormLabel,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { BoxSkeleton } from "app/components/modules/Skeletons";
import { INPUT_HEIGHT } from "containers/themes/attributes";
import { globalStyles } from "containers/themes/globalClasses";
import { isEqual } from "lodash";
import React, { forwardRef, useState } from "react";

export const TextField = React.memo(
  forwardRef((props, ref) => {
    const {
      name,
      error = false,
      type = "text",
      inputLabel,
      label,
      value,
      onChange,
      readOnly = false,
      endAdornment,
      endAdornmentStyle,
      startAdornment,
      className,
      inputClassName,
      onBlur,
      multiline,
      size,
      maxLength,
      disabled,
      id = null,
      loading = false,
      required,
      ...rest
    } = props;
    const classes = useStyles({ multiline });
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const isPassword = type === "password";
    const inputType = isPassword ? (showPassword ? "text" : "password") : type;

    return (
      <FormControl
        error={Boolean(error)}
        required={required}
        fullWidth
        className={`${classes.formControl} ${className} ${
          readOnly && classes.readOnly
        }`}
      >
        {label && <FormLabel>{label}</FormLabel>}
        {loading ? (
          <BoxSkeleton
            square={false}
            rectSize={{ width: "100%", height: INPUT_HEIGHT }}
          />
        ) : (
          <MUITextField
            innerRef={ref}
            name={name}
            label={inputLabel}
            variant="outlined"
            type={inputType}
            value={value ?? ""}
            multiline={multiline}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={""}
            disabled={disabled}
            InputProps={{
              inputProps: {
                className: `${classes.input} ${inputClassName} ${
                  multiline ?? ""
                }`,
              },
              startAdornment: startAdornment ? (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ) : undefined,
              endAdornment:
                endAdornment || isPassword ? (
                  <InputAdornment
                    position="end"
                    style={{ ...endAdornmentStyle }}
                  >
                    {isPassword && (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    )}
                    {endAdornment}
                  </InputAdornment>
                ) : undefined,
            }}
            InputLabelProps={{ ...(value && { shrink: true }) }}
            error={Boolean(error)}
            helperText={error ?? undefined}
            {...rest}
          />
        )}
      </FormControl>
    );
  }),
  (prev, next) => isEqual(prev, next)
);
const useStyles = makeStyles(
  (theme) => ({
    formControl: {
      ...globalStyles(theme).formControl,
    },
    input: {
      height: ({ multiline }) => (multiline ? "100%" : 36),
      padding: "0px 15px",
      paddingLeft: theme.spacing(1),
      fontSize: 14,
    },
    multiline: {
      "& .MuiOutlinedInput-input": {
        padding: "0px !important",
      },
    },
    readOnly: globalStyles(theme).readOnly,
  }),
  { name: "CommonTextField" }
);
