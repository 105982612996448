import { makeStyles } from "@material-ui/styles";
import Table, { TableCell, TableRow } from "app/components/common/Table";
import Pagination from "app/components/modules/Pagination";
import { TableSkeleton } from "app/components/modules/Skeletons/TableSkeleton";
import React, { Suspense, memo } from "react";
import AirportTableRow from "./AirportTableRow";
import { Typography } from "@material-ui/core";

/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    headTitle: {
      fontWeight: "bold !important",
    },
    tableRow: {
      cursor: "pointer",
    },
    textAlignCenter: {
      textAlign: "center",
    },
    textAlignLeft: {
      textAlign: "left",
    },
    tableRoot: {
      maxHeight: "calc(100vh - 340px)",
      "& .MuiTableHead-root": {
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
    },
  }),
  { index: 1 }
);

const AirportsTable = ({
  airports,
  pagination,
  handlePagination,
  getAirports,
  isLoading,
}) => {
  const classes = useStyles();
  const totalItem = airports?.result_number;

  function onChangeSize(e) {
    const { value } = e.target;
    handlePagination({ limit: value });
  }
  function onClickPage(e, page) {
    handlePagination({ page });
  }
  return (
    <React.Fragment>
      <Table
        size="small"
        showNoResult
        distinct
        className={classes.tableRoot}
        columns={<TableColumns />}
        rows={<TableRows rows={airports?.message} isLoading={isLoading} />}
      />
      <Pagination
        label={"Shows per page"}
        limit={pagination.limit}
        total={totalItem}
        options={[
          { id: 20, value: "20" },
          { id: 50, value: "50" },
          { id: 100, value: "100" },
          { id: totalItem, value: "All" },
        ]}
        onChangeSize={onChangeSize}
        onChangePage={onClickPage}
      />
    </React.Fragment>
  );
};

const columnsData = [
  { title: "", width: 24 },
  { title: "Name", align: "left" },
  { title: "Lat" },
  { title: "Long" },
  { title: "Region" },
  { title: "Country" },
  { title: "IATA" },
  { title: "ICAO" },
];
const TableColumns = memo(() => {
  return columnsData.map((column) => {
    const { title, ...rest } = column;
    return (
      <TableCell {...rest}>
        <Typography>{column.title}</Typography>
      </TableCell>
    );
  });
});

const TableRows = memo(({ rows, isLoading }) => {
  if (isLoading) return <TableSkeleton size={10} cells={columnsData.length} />;
  let tableRows = rows?.map((item, index) => {
    return <AirportTableRow key={item.id} data={item} />;
  });
  return tableRows;
});

export default AirportsTable;
