import { memoize } from "lodash";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
/* -------------------------------------------------------------------------- */
/**
 * useForm hook for managing form state and validation.
 *
 * @param {Object} options Configuration options for the form.
 * @param {Object} options.initialValues Initial values of the form fields.
 * @param {Object} options.validations Schema for validating form fields where each field can include:
 *   @param {Object} [field.required] Specifies if the field is mandatory.
 *     @param {boolean} field.required.value True if the field is required.
 *     @param {string} field.required.message Error message to display if the field is empty.
 *   @param {Object} [field.pattern] Defines a regex pattern the field value must match.
 *     @param {RegExp} field.pattern.value Regex pattern the field must conform to.
 *     @param {string} field.pattern.message Error message to display if the pattern does not match.
 *   @param {Object} [field.custom] Custom validation logic.
 *     @param {Function} field.custom.isValid Function that takes the field value and returns a boolean indicating validity.
 *     @param {Function} field.custom.message Function that returns a custom error message based on the field value.
 * @param {Function} options.onSubmit Callback function that is called when the form is successfully submitted.
 * @param {Function} options.onError Callback function that is called when there are validation errors.
 *
 * @returns  Form management functions and state, including:
 *   - @param data: Current state of the form data.
 *   - @param setData: Function to update the form data.
 *   - @param handleChange: Memoized function to handle changes in form fields.
 *   - @param handleSubmit: Asynchronous function to handle form submission, including validation.
 *        @returns
 *          @param isValid callback
 *          @param data
 *   - @param initFormData: Function to reset form data to initial values.
 *   - @param errors: Current validation errors.
 */
export const useForm = (options) => {
  const [data, setData] = useState(options?.initialValues || {});
  const [errors, setErrors] = useState({});

  const handleChange = useMemo(
    () =>
      memoize((key, sanitizeFn) => (newValue) => {
        console.log({ newValue });
        const value = sanitizeFn ? sanitizeFn(newValue) : newValue;
        setData((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        console.log({ value });
        //check validations
        handleValidation(key, value, (error) => {
          setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        });
      }),

    []
  );
  console.log(333, { errors });

  const handleValidation = (key, value, callback) => {
    const validations = options?.validations;
    if (!validations) return;
    if (!validations?.[key]) return;
    const validation = validations?.[key];
    let error = "";
    if (validation?.required?.value && !value) {
      error = validation?.required?.message;
      return callback(error);
    }
    if (value) {
      const pattern = validation?.pattern;

      if (pattern?.value && !RegExp(pattern.value).test(value)) {
        error = pattern.message;
        return callback(error);
      }

      const custom = validation?.custom;

      if (custom?.isValid && !custom.isValid(value, data)) {
        error = custom.message(value);
        return callback(error);
      }
    }
    return callback(error);
  };

  const handleSubmit = async (e, callback) => {
    if (e instanceof Event) {
      e.preventDefault();
    } else if (e instanceof Function) {
      callback = e;
    }
    const validations = options?.validations;

    if (validations) {
      let valid = true;
      const newErrors = {};

      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];

        if (validation?.required?.value && !value) {
          valid = false;
          newErrors[key] = validation?.required?.message;
          continue;
        }

        if (value) {
          const pattern = validation?.pattern;

          if (pattern?.value && !RegExp(pattern.value).test(value)) {
            valid = false;
            newErrors[key] = pattern.message;
            continue;
          }

          const custom = validation?.custom;

          if (custom?.isValid && !custom.isValid(value, data)) {
            valid = false;
            newErrors[key] = custom.message(value);
            continue;
          }
        }
      }

      if (callback) callback({ isValid: valid });
      if (!valid) {
        setErrors(newErrors);
        if (options?.onError) {
          options?.onError?.(newErrors);
        }
        const isEmpty = Object.keys(newErrors).length === 0;
        console.log({ newErrors }, isEmpty);
        if (!isEmpty) return Promise.resolve(false);
      }
    }

    setErrors({});

    if (options?.onSubmit) {
      return options.onSubmit();
    }
  };

  const initFormData = () => {
    setData(options?.initialValues || {});
    setErrors({});
  };

  return {
    data,
    setData,
    handleChange,
    handleSubmit,
    initFormData,
    errors,
  };
};
