export const colors = {
  primary: {
    main: "#3F51B5", // Blue (Main)
    light: "#B2B9E1", // Light Blue
    lighter: "#D9DCF0", // Pale Blue
    lightest: "#ECEEF8", // Very Pale Blue
    lightish: "#F5F6FB", // Light Grayish Blue
    contrastText: "#fff",
  },
  secondary: {
    light: "#E9F7F2",
    main: "#22AB82",
    contrastText: "#fff ",
  },
  main: {
    ["100"]: "#3F51B5",
    ["80"]: "#6574C4",
    ["60"]: "#8C97D3",
    ["50"]: "#9FA8DA",
    ["40"]: "#B2B9E1",
    ["30"]: "#E7EAFF", // not use
    ["20"]: "#D9DCF0",
    ["10"]: "#ECEEF8",
    ["5"]: "#F5F6FB",
  },
  black: {
    ["100"]: "#000000",
    ["80"]: "#333333",
    ["60"]: "#666666",
    ["40"]: "#8F8F8F",
    ["20"]: "#CCCCCC",
    ["10"]: "#E6E6E6",
    ["5"]: "#F2F2F2",
  },
  error: {
    main: "#E30000",
  },
  warning: {
    main: "#FF9800",
    contrastText: "#fff",
  },
  text: {
    primary: "rgba(0, 0, 0, 0.6)",
  },
  info: {
    main: "#5262BC",
  },
  common: {
    white: "#fff",
    black: "#000",
  },
  grey: {
    dark: "#343438",
    main: "#8F8F8F",
    light: "#CCCCCC",
    lighter: "#E6E6E6",
    fav: "#626F86",
    0: "#ffffff",
    5: "#f2f2f2",
    10: "#e6e6e6",
    20: "#cccccc",
    30: "#bfbfbf",
    40: "#b2b2b2",
    50: "#a6a6a6",
    60: "#a1a1a1",
    70: "#8c8c8c",
    80: "#808080",
    90: "#737373",
    95: "#6d6d6d",
    100: "#666666",
  },
  text: {
    primary: "#343438",
    secondary: "#343438",
    // disabled: styles.ttt,
    // hint: styles.tttt,
  },
};
