import { Grid, ButtonGroup as MuiGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isEqual } from "lodash";
import React from "react";
const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: "4px",
      display: "flex",
      overflow: "hidden",
      height: 36,
      "& .MuiButton-outlinedPrimary": {
        backgroundColor: theme.palette.primary["lightest"],
        border: `1px solid ${theme.palette.primary.main}`,
        position: "relative",
        zIndex: 10,
        "& .MuiButton-label": {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
      "& .MuiButtonBase-root": {
        borderRadius: "unset",
        "& .MuiButton-label": {
          ...theme.typography.body1,
        },
      },
      "& .MuiButtonGroup-groupedOutlinedHorizontal:not(:last-child)": {
        // borderRightColor: "inherit",
      },
      "& .MuiButtonBase-root:first-child": {
        borderRadius: "4px",
        borderTopRightRadius: "unset",
        borderBottomRightRadius: "unset",
      },
      "& .MuiButtonBase-root:last-child": {
        borderRadius: "4px",
        borderTopLeftRadius: "unset",
        borderBottomLeftRadius: "unset",
      },
    },
    outline: {
      width: "fit-content",
      border: "unset",
      borderRadius: "4px",
      // marginInline: "4px",
    },
  }),
  {
    name: "index(GroupButton)",
  }
);

const ButtonGroup = React.memo(
  ({ buttons, color = "primary", orientation = "horizontal", className, ...rest }) => {
    const classes = useStyles();
    return (
      <div className={`${classes.outline} ${className}`}>
        <MuiGroup orientation={orientation} className={classes.root} {...rest}>
          {buttons.map((button) => {
            return button;
          })}
        </MuiGroup>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default ButtonGroup;
