import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "store/actions";

const initialState = {
  key: "",
  title: "",
  description: "",
};

const helperSlice = createSlice({
  name: "helpers",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setHelper: (state, action) => {
      state.key = action.payload;
    },
    setHelperDescription: (state, action) => {
      state.description = action.payload;
    },
  },
});
export const { actions } = helperSlice;
export const { setHelper, setHelperDescription } = actions;

const reducer = {
  helpers: helperSlice.reducer,
};
export default reducer;
