import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { Dialog, Divider, Grid, IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Button } from "app/components/common/Button";
import { LogOutIcon, UploadIcon } from "app/components/common/Icons";
import { useFileManager } from "hooks/useFileManager";
import Avatar from "app/components/modules/Avatar";
import { updateUserProfile } from "app/routes/dashboard/routes/users-management/slice/userThunks";
import { isEqual } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  selBaseAirline,
  selBaseUser,
  selBaseUserAssignment,
} from "store/slices";
import { initProfileV2 } from "store/slices/thunks";
import { AddAPhotoOutlined } from "@material-ui/icons";
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    root: {
      "& .MuiDialog-container": {
        height: "fit-content !important",
        position: "absolute",
        top: "64px",
        right: "24px",
        "& .MuiDialog-paper": {
          width: 280,
          height: 324,
          margin: 0,
          fontSize: "10px",
          fontWeight: 500,
        },
      },
      "& .MuiCard-root": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "40px",
        // gap: theme.spacing(4),
      },
      "& .MuiCardContent-root": {
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      "& .MuiGrid-root": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        "& .MuiAvatar-root": {
          width: "60px",
          height: "60px",
        },
        "& h6": {
          marginTop: "12px",
        },
      },
    },
    logOut: {
      padding: 0,
      width: "100%",
      marginLeft: "0px !important",
      color: `${theme.palette.error.main} !important`,
      fontSize: theme.typography.subtitle2.fontSize,
    },
    switch: {
      width: "100% !important",
      marginBottom: theme.spacing(2),
    },
    userName: {
      marginTop: theme.spacing(1.5),
      fontWeight: theme.typography.fontWeightMedium,
    },
    AirlineName: {
      marginBlock: theme.spacing(1),
      color: theme.palette.black[40],
      fontWeight: theme.typography.fontWeightMedium,
    },
    avatar: {
      width: `88px !important`,
      height: `88px !important`,
    },
    uploadButton: {
      position: "absolute",
      padding: "6px",
      transform: "translate(30px,59px)",
      zIndex: 1,
      backgroundColor: `${theme.palette.common.white} !important`,
      "& .MuiSvgIcon-root": {
        height: 16,
        width: 16,
        color: `${theme.palette.black[40]} !important`,
      },
      boxShadow: theme.shadows[7],
    },
    actions: {
      padding: 0,
      flexDirection: "column",
      width: "100%",
    },
  }),
  {
    name: "Profile",
  }
);

const Profile = React.memo(
  ({ handleLogOut, handleSwitch, open, onClose }) => {
    const user = useSelector(selBaseUser);
    const userAssignment = useSelector(selBaseUserAssignment);

    console.log(555, { user, userAssignment });
    const airline = useSelector(selBaseAirline);
    const { onUpload, fileData, openFileManager, FileManager } = useFileManager(
      { type: "image" }
    );
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
      if (fileData.file) {
        onUpload()
          .then((res) => dispatch(updateUserProfile(user.id, res.id)))
          .then((res) => {
            dispatch(
              initProfileV2(() => toast.success("Change avatar Success")) ////TODO not clean (for updating user_assignments)
            );
          });
      }
    }, [fileData.file]);

    return (
      <Dialog open={open} onClose={onClose} className={classes.root}>
        <Card>
          <CardContent>
            <Grid>
              <Avatar
                key={user?.image?.id}
                imageId={user?.image?.id}
                className={classes.avatar}
              />
              <FileManager>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={openFileManager}
                  className={classes.uploadButton}
                >
                  <AddAPhotoOutlined />
                </IconButton>
              </FileManager>
            </Grid>
            <Typography className={classes.userName}>
              {userAssignment?.known_as}
            </Typography>
            <Typography className={classes.AirlineName} variant="subtitle1">
              {userAssignment?.code}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              variant="outlined"
              color="primary"
              text="SWITCH ACCOUNT"
              className={classes.switch}
              onClick={handleSwitch}
            />
            <Button
              size="small"
              text="Sign out"
              startIcon={<LogOutIcon />}
              className={classes.logOut}
              onClick={handleLogOut}
            />
          </CardActions>
        </Card>
      </Dialog>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default Profile;
