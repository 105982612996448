import { createSlice } from "@reduxjs/toolkit";
import { resetStore } from "store/actions";

const initialState = {
  loading: false,
  progress: 0,
  global: false,
};

const baseSlice = createSlice({
  name: "loading",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setLoaderApp: (state, action) => {
      state.loading = action.payload;
    },
    setLoaderGlobalApp: (state, action) => {
      state.global = action.payload;
    },
    setLoaderProgress: (state, action) => {
      console.log({ setLoaderProgress: action.payload });
      state.progress = action.payload;
    },
  },
});

export const { actions } = baseSlice;
export const { setLoaderApp, setUser, setLoaderGlobalApp, setLoaderProgress } =
  actions;
// selectors

export const selLoaderLoading = (state) => state.loading.loading;
export const selLoaderGlobalLoading = (state) => state.loading.global;
export const selLoaderProgress = (state) => state.loading.progress;

const reducer = {
  loading: baseSlice.reducer,
};

export default reducer;
