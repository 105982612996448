import { SvgIcon } from "@material-ui/core";

export const EnrouteLogo = (props) => {
  return (
    <SvgIcon {...props} width='100%' height='100%' viewBox='0 0 396 243'>
      <path
        d='M13.8 235.4C13.8027 237.163 14.5042 238.853 15.7507 240.099C16.9973 241.346 18.6872 242.047 20.45 242.05H47.35V234.64H13.8V235.4Z'
        fill='currentColor'
      />
      <path
        d='M13.8 202.15C13.8027 203.913 14.5042 205.603 15.7507 206.849C16.9973 208.096 18.6872 208.797 20.45 208.8H47.35V201.39H13.8V202.15Z'
        fill='currentColor'
      />
      <path
        d='M13.8 218.77C13.8027 220.533 14.5042 222.223 15.7507 223.469C16.9973 224.716 18.6872 225.417 20.45 225.42H47.35V218H13.8V218.77Z'
        fill='currentColor'
      />
      <path
        d='M341 242.05H367.89C369.653 242.047 371.343 241.346 372.589 240.099C373.836 238.853 374.537 237.163 374.54 235.4V234.64H341V242.05Z'
        fill='currentColor'
      />
      <path
        d='M341 201.39V208.8H368C369.734 208.797 371.396 208.107 372.622 206.882C373.847 205.656 374.537 203.994 374.54 202.26V201.39H341Z'
        fill='currentColor'
      />
      <path
        d='M341 225.42H367.89C369.653 225.417 371.343 224.716 372.589 223.469C373.836 222.223 374.537 220.533 374.54 218.77V218H341V225.42Z'
        fill='currentColor'
      />
      <path
        d='M86.6001 201.39C81.6236 201.395 76.8524 203.375 73.3335 206.893C69.8146 210.412 67.8354 215.184 67.8301 220.16V242.05H75.2401V220.16C75.2401 217.147 76.4369 214.258 78.5673 212.127C80.6978 209.997 83.5872 208.8 86.6001 208.8C89.6129 208.8 92.5024 209.997 94.6328 212.127C96.7632 214.258 97.9601 217.147 97.9601 220.16V242.05H105.37V220.16C105.365 215.184 103.386 210.412 99.8666 206.893C96.3477 203.375 91.5766 201.395 86.6001 201.39Z'
        fill='currentColor'
      />
      <path
        d='M255.39 223.28C255.39 226.293 254.193 229.182 252.063 231.313C249.932 233.443 247.043 234.64 244.03 234.64C241.017 234.64 238.128 233.443 235.997 231.313C233.867 229.182 232.67 226.293 232.67 223.28V201.39H225.26V223.28C225.26 228.258 227.238 233.032 230.758 236.552C234.278 240.072 239.052 242.05 244.03 242.05C249.008 242.05 253.782 240.072 257.302 236.552C260.822 233.032 262.8 228.258 262.8 223.28V201.39H255.39V223.28Z'
        fill='currentColor'
      />
      <path
        d='M189 201.39C184.023 201.395 179.252 203.375 175.733 206.893C172.215 210.412 170.235 215.184 170.23 220.16V223.28C170.23 228.258 172.208 233.032 175.728 236.552C179.248 240.072 184.022 242.05 189 242.05C193.978 242.05 198.752 240.072 202.272 236.552C205.792 233.032 207.77 228.258 207.77 223.28V220.16C207.765 215.184 205.785 210.412 202.267 206.893C198.748 203.375 193.976 201.395 189 201.39ZM189 234.64C185.988 234.637 183.1 233.44 180.97 231.31C178.84 229.18 177.643 226.292 177.64 223.28V220.16C177.64 217.147 178.837 214.258 180.967 212.127C183.098 209.997 185.987 208.8 189 208.8C192.013 208.8 194.902 209.997 197.033 212.127C199.163 214.258 200.36 217.147 200.36 220.16V223.28C200.355 226.291 199.156 229.178 197.027 231.307C194.898 233.436 192.011 234.635 189 234.64Z'
        fill='currentColor'
      />
      <path
        d='M120.81 220.16V242.05H128.22V220.16C128.225 217.149 129.424 214.262 131.553 212.133C133.682 210.004 136.569 208.805 139.58 208.8H146.42C148.183 208.797 149.873 208.096 151.119 206.849C152.366 205.603 153.067 203.913 153.07 202.15V201.39H139.59C134.612 201.393 129.838 203.371 126.317 206.89C122.796 210.409 120.815 215.182 120.81 220.16Z'
        fill='currentColor'
      />
      <path
        d='M282 208.8H297.65V242.05H305V208.8H314C315.763 208.797 317.453 208.096 318.699 206.849C319.946 205.603 320.647 203.913 320.65 202.15V201.39H282V208.8Z'
        fill='currentColor'
      />
      <path d='M1.25 128.79L0 116.34L188.78 97.33L361.83 21.45L366.85 32.91L192 109.58L1.25 128.79Z' fill='currentColor' />
      <path d='M173.29 59.14L161.9 2.56L174.14 0L185.53 56.58L173.29 59.14Z' fill='currentColor' />
      <path
        d='M187.82 148.46C178.071 148.46 168.542 145.569 160.436 140.153C152.33 134.737 146.013 127.039 142.282 118.032C138.551 109.026 137.575 99.1154 139.477 89.554C141.379 79.9927 146.073 71.2101 152.967 64.3167C159.86 57.4234 168.643 52.729 178.204 50.8271C187.765 48.9253 197.676 49.9014 206.683 53.632C215.689 57.3626 223.387 63.6803 228.803 71.786C234.219 79.8917 237.11 89.4214 237.11 99.17C237.097 112.238 231.9 124.768 222.659 134.009C213.418 143.249 200.889 148.447 187.82 148.46ZM187.82 62.4C180.545 62.4 173.434 64.5574 167.385 68.5993C161.336 72.6412 156.622 78.386 153.839 85.1072C151.055 91.8285 150.328 99.2242 151.747 106.359C153.167 113.494 156.671 120.048 161.816 125.191C166.961 130.334 173.515 133.836 180.651 135.255C187.786 136.673 195.182 135.943 201.902 133.157C208.623 130.372 214.366 125.656 218.406 119.607C222.447 113.557 224.602 106.445 224.6 99.17C224.587 89.4203 220.707 80.0739 213.812 73.1808C206.917 66.2877 197.57 62.4106 187.82 62.4Z'
        fill='currentColor'
      />
      <path
        d='M380.4 184.4C380.364 183.38 380.538 182.364 380.911 181.415C381.285 180.465 381.85 179.603 382.571 178.882C383.293 178.16 384.155 177.595 385.105 177.221C386.054 176.848 387.07 176.674 388.09 176.71C392.6 176.71 395.82 179.71 395.82 184.29C395.854 185.316 395.676 186.338 395.296 187.291C394.917 188.245 394.344 189.11 393.615 189.832C392.885 190.554 392.014 191.118 391.057 191.487C390.099 191.857 389.075 192.025 388.05 191.98C387.036 192.037 386.022 191.881 385.073 191.521C384.123 191.161 383.26 190.606 382.539 189.892C381.818 189.177 381.255 188.319 380.886 187.373C380.518 186.427 380.352 185.414 380.4 184.4ZM394.14 184.4C394.171 183.598 394.039 182.798 393.75 182.049C393.461 181.3 393.022 180.619 392.46 180.046C391.898 179.472 391.225 179.02 390.482 178.717C389.739 178.413 388.942 178.264 388.14 178.28C387.338 178.254 386.54 178.395 385.795 178.692C385.05 178.99 384.375 179.438 383.811 180.009C383.248 180.58 382.809 181.261 382.521 182.01C382.233 182.759 382.104 183.559 382.14 184.36C382.14 185.963 382.777 187.501 383.91 188.634C385.044 189.768 386.582 190.405 388.185 190.405C389.788 190.405 391.326 189.768 392.459 188.634C393.593 187.501 394.23 185.963 394.23 184.36L394.14 184.4ZM387.21 185.21V188.21H385.67V180.21H388.23C389.84 180.21 390.94 180.91 390.94 182.56C390.94 184.21 390.21 184.65 389.47 184.83L391.41 188.2H389.6L388.06 185.2L387.21 185.21ZM388.13 183.89C389.05 183.89 389.38 183.49 389.38 182.68C389.409 182.523 389.4 182.361 389.354 182.207C389.307 182.054 389.225 181.914 389.113 181.8C389.001 181.685 388.864 181.599 388.712 181.549C388.56 181.498 388.398 181.485 388.24 181.51H387.24V183.89H388.13Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
};
