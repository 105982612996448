import { createMuiTheme } from "@material-ui/core";
import { colors } from "./colors";
import { shadows } from "./shadows";
const defaultTheme = createMuiTheme({
  palette: {
    type: "light",
    ...colors,
    action: {
      hover: "rgba(0, 0, 0, 0.12)",
      hoverOpacity: "0.04",
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeightRegular: 400,
    h1: {
      fontFamily: "Roboto",
      fontSize: "26px",
      fontWeight: 500,
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: 500,
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
    },
    h5: {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
    },
    h6: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 500,
    },
    h7: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: 500,
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontSize: "11px",
      fontWeight: 400,
    },
    subtitle3: {
      fontFamily: "Roboto",
      fontSize: "10px",
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: "4px",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            background: colors.primary["lightest"],
            width: "6px",
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: colors.grey["main"],
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: colors.main[100],
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: colors.primary["lightest"],
          },
          "&": {
            scrollbarWidth: "thin",
            scrollbarColor: `${colors.grey["main"]} ${colors.primary["lightest"]}`,
          },
          "& *": {
            scrollbarWidth: "thin",
            scrollbarColor: `${colors.grey["main"]} ${colors.primary["lightest"]}`,
          },
        },
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                   Button                                   */
    /* -------------------------------------------------------------------------- */
    MuiButton: {
      containedPrimary: {
        width: "108px",
        whiteSpace: "nowrap",
      },
      containedSecondary: {
        width: "108px",
        whiteSpace: "nowrap",
      },
    },
    MuiButtonBase: {
      root: {
        fontSize: 13,
        "&.MuiIconButton-root ": {
          // color: colors.black[40],
        },
        "&.MuiPickersDay-daySelected": {
          // color: "unset",
        },
        "&.MuiButton-outlined": {
          borderColor: colors.primary.main,
        },
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Input                                   */
    /* -------------------------------------------------------------------------- */
    MuiInputAdornment: {
      root: {
        paddingRight: "7px",
        "&.MuiInputAdornment-positionEnd": {
          marginLeft: 2,
        },
        "& .MuiIconButton-root": {
          color: colors.black[40],
        },
        "& .MuiSvgIcon-root": {
          color: colors.grey.dark,
        },
        "& .Mui-disabled": {
          "& .MuiSvgIcon-root": {
            color: colors.grey["main"],
          },
        },
      },
    },

    MuiInputBase: {
      root: {
        "& *:-webkit-autofill": {
          transition: "background-color 1000s ease-in-out 0s",
        },
        height: "36px",
        backgroundColor: colors.common.white,
        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
          {
            right: "9px !important",
            top: "unset !important",
            "& .MuiSvgIcon-root": {
              color: colors.grey.dark,
            },
          },
        '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
          {
            padding: "0px 15px !important",
            paddingLeft: "8px !important",
          },
        "&.Mui-focused": {
          "& .MuiSvgIcon-root": {
            color: colors.primary.main,
          },
        },
        "&.Mui-error": {
          "& .MuiSvgIcon-root": {
            color: `${colors.error.main} !important`,
          },
        },
        "& .MuiSvgIcon-root.MuiSelect-icon": {
          color: colors.grey.dark,
        },
        "&.Mui-disabled": {
          "& .MuiSvgIcon-root": {
            color: `${colors.grey["main"]} !important`,
          },
        },
      },
      adornedEnd: {
        paddingRight: "0px !important",
      },
    },
    MuiInputLabel: {
      root: {
        transform: "translate(14px, 11px) scale(1) !important",
        fontSize: `14px !important`,
      },
      shrink: {
        transform: "translate(14px, -6px) scale(0.7) !important",
        fontSize: `14px !important`,
        lineHeight: "unset !important",
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                Autocomplete                                */
    /* -------------------------------------------------------------------------- */
    MuiAutocomplete: {
      popper: {
        boxShadow: shadows[11],
        borderRadius: 4,
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                   Select                                   */
    /* -------------------------------------------------------------------------- */
    MuiSelect: {
      root: {
        height: "36px",
        padding: "0px 15px",
        paddingLeft: 8,
      },
      icon: {
        top: "unset",
        color: colors.grey.dark,
      },
      select: {
        height: "100% !important",
        display: "flex",
        alignItems: "center",
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Table                                   */
    /* -------------------------------------------------------------------------- */
    MuiTableContainer: {
      root: {
        borderRadius: 4,
        border: `1px solid ${colors.primary["light"]}`,
      },
    },
    MuiTableHead: {
      root: {
        top: 0,
        zIndex: 1,
        position: "sticky",
        height: 50,
        backgroundColor: colors.primary["lightest"],
        "& .MuiTableCell-root": {
          backgroundColor: colors.primary["lightest"],
          borderBottom: `1px solid ${colors.black[40]}`,
          paddingBlock: 0,
          color: colors.black[100],
          fontSize: 15,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },
    MuiTableBody: {
      root: {
        "& .MuiTableRow-root": {
          height: 50,
          "&:hover": {
            backgroundColor: colors.common.white,
          },
          "&:nth-of-type(odd)": {
            backgroundColor: colors.main[5],
          },
          "&:nth-of-type(even)": {
            backgroundColor: colors.common.white,
          },
          "& .MuiTableCell-root": {
            paddingBlock: 0,
            borderColor: colors.primary["lighter"],
            fontSize: 14,
            "& .MuiTypography-root": {
              fontSize: 14,
            },
          },
          "&:last-of-type": {
            "& .MuiTableCell-root": {
              borderBottom: "none",
            },
          },
          "& .MuiTableCell-root:last-of-type": {
            borderRight: "none",
          },
        },
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Tabs                                    */
    /* -------------------------------------------------------------------------- */
    MuiTabs: {
      root: {
        "& .MuiTab-wrapper": {
          fontSize: 16,
        },
      },
      vertical: {
        "& .MuiTab-wrapper": {
          fontSize: 14,
        },
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Chip                                    */
    /* -------------------------------------------------------------------------- */
    MuiChip: {
      label: {
        fontSize: `10px !important`,
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                   Dialog                                   */
    /* -------------------------------------------------------------------------- */
    MuiDialog: {
      paper: {
        "&.MuiDialog-paperWidthSm": {
          maxWidth: "480px", // Your custom maxWidth for lg breakpoint
        },
        "&.MuiDialog-paperWidthLg": {
          maxWidth: "976px", // Your custom maxWidth for lg breakpoint
        },
        "& .MuiPickersToolbarText-toolbarTxt": {
          color: `${colors.primary.main} !important`,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "16px 14px",
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                     Svg                                    */
    /* -------------------------------------------------------------------------- */
    MuiSvgIcon: {
      root: {
        width: "20px",
        height: "20px",
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                  ListItem                                  */
    /* -------------------------------------------------------------------------- */
    MuiListItemIcon: {
      root: {
        minWidth: "initial",
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Paper                                   */
    /* -------------------------------------------------------------------------- */
    MuiPaper: {
      root: {
        "& .MuiPickersToolbar-toolbar": {
          backgroundColor: colors.primary["lightest"],
          "& .MuiButton-label": {
            "& .MuiTypography-subtitle1": {
              color: colors.main[60],
            },
            "& .MuiPickersToolbarText-toolbarTxt": {
              color: colors.main[100],
            },
            "& .MuiPickersToolbarText-toolbarBtnSelected": {
              color: colors.main[100],
            },
          },
          "& .MuiPickerDTToolbar-separator": {
            color: colors.main[100],
          },
        },
      },
      elevation1: {
        boxShadow: shadows[2],
      },
    },
    /* -------------------------------------------------------------------------- */
    /*                                    Grid                                    */
    /* -------------------------------------------------------------------------- */
    MuiGrid: {
      margin: 0,
      item: {
        // margin: "0 !important",
        // paddingLeft: "0 !important",
        // "&:last-child": {
        //   paddingRight: "inherit !important",
        // },
      },
    },
  },
  shadows: [...shadows],
});

export default defaultTheme;
/* -------------------------------------------------------------------------- */
function generateSpacingClasses(spacing) {
  const spacingClasses = {};
  for (let i = 0; i <= 10; i++) {
    spacingClasses[`marg-${i}`] = {
      margin: spacing(i),
    };
    spacingClasses[`p-${i}`] = {
      padding: spacing(i),
    };
  }
  return spacingClasses;
}
