import {
  downloadFileAPI,
  uploadAPI,
  uploadMultipleAPI,
} from "api/fileManagerApi";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selTokens } from "store/slices";

/* -------------------------------------------------------------------------- */
const initFileData = { file: null, url: "", fileId: "" };
const initMultipleFileData = [];
/* -------------------------------------------------------------------------- */
export const FilemanagerAcceptTypes = {
  image: "image/gif, image/jpeg, image/png",
  pdf: "application/pdf",
};

export const useFileManager = (props) => {
  const [acceptType, setAcceptType] = useState(null);
  const [fileData, setFileData] = useState(initFileData);
  const [arrayFileData, setArrayFileData] = useState(initMultipleFileData);
  console.log("nedata", arrayFileData);
  const { token: Authorization } = useSelector(selTokens);
  const inputRef = useRef(null);
  useEffect(() => {
    if (acceptType) {
      inputRef.current?.click();
    }
  }, [acceptType]);

  function onFileChange(event) {
    let file = event.target?.files[0];
    let url = URL.createObjectURL(file);
    setFileData((prevState) => ({ ...prevState, file, url }));
  }

  function onFilesChange(event) {
    let files = [];
    for (const file of Array.from(event.target?.files)) {
      const plain = { file, url: URL.createObjectURL(file) };
      files.push(plain);
    }
    setArrayFileData((prev) => [...prev, ...files]);
  }

  const openFileManager = (type = "image") => {
    if (!inputRef?.current) return;
    if (acceptType === type) return inputRef.current?.click();
    setAcceptType(type);
  };

  const onClearFile = () => {
    setFileData(initFileData);
  };

  const onClearMultipleFile = () => {
    setArrayFileData(initMultipleFileData);
  };

  const onRemoveFile = (url) => {
    let filteredFiles = arrayFileData.filter((f) => f.url !== url);
    setArrayFileData(filteredFiles);
  };

  const onUpload = async () => {
    let { file } = fileData;
    if (file) {
      try {
        let res = await uploadAPI(file);
        if (res.message.id) {
          return res.message;
        }
        throw res.message;
      } catch (error) {}
    }
  };
  const onMultipleUpload = async (is_public = true) => {
    let files = arrayFileData.map((f) => f?.file);
    if (files) {
      try {
        let res = await uploadMultipleAPI(files, (progress) => {}, is_public);
        if (res?.message?.length > 0) {
          return res;
        }
        throw "Error: File upload failed";
      } catch (error) {}
    }
  };

  const onFetchFile = async (fileId) => {
    try {
      let res = await downloadFileAPI(fileId, true, { Authorization });
      let url = URL.createObjectURL(res);
      return url;
    } catch (error) {
      let errors = {
        403: "Download Permission Denied!",
      };
    }
  };
  const onDownload = async (fileId) => {
    try {
      let res = await downloadFileAPI(fileId);
      let url = URL.createObjectURL(res);
      window.open(url, "_blank");
      return url;
    } catch (error) {
      let errors = {
        403: "Download Permission Denied!",
      };
    }
  };
  const FileManager = ({ children, multiple = false }) => {
    return (
      <>
        <input
          accept={FilemanagerAcceptTypes[acceptType]}
          type="file"
          onChange={multiple ? onFilesChange : onFileChange}
          autoFocus
          style={{ display: "none" }}
          ref={inputRef}
          multiple={multiple}
        />
        {children}
      </>
    );
  };

  return {
    FileManager,
    fileData,
    filesData: arrayFileData,
    onClearFile,
    openFileManager,
    onUpload,
    onDownload,
    onFetchFile,
    onFileChange,
    onFilesChange,
    onMultipleUpload,
    onClearMultipleFile,
    onRemoveFile,
  };
};
