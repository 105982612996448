import { Grid, makeStyles } from "@material-ui/core";
import CommonCard from "app/components/common/Card";
import React from "react";
import clsx from "clsx";

export const Card = ({
  topLeftContent,
  topRightContent,
  bottomLeftContent,
  bottomRightContent,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <CommonCard
      className={clsx(classes.card, className)}
      content={
        <Grid container justify="space-between" xs={12}>
          <Grid items container justify="space-between" alignItems="flex-start">
            <Grid item>{topLeftContent}</Grid>
            <Grid item>{topRightContent}</Grid>
          </Grid>
          <Grid items container justify="space-between" alignItems="flex-end">
            <Grid item>{bottomLeftContent}</Grid>
            <Grid item>{bottomRightContent}</Grid>
          </Grid>
        </Grid>
      }
      {...rest}
    />
  );
};
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    "& .MuiCardContent-root": {
      padding: 0,
      height: "100%",
      "& > .MuiGrid-root": {
        height: "100%",
      },
    },
  },
}));
