import { Card } from "app/components/modules/Card";
import React from "react";

export const CardSkeleton = ({
  topLeftContent,
  topRightContent,
  bottomLeftContent,
  bottomRightContent,
  className,
}) => {
  return (
    <Card
      topLeftContent={topLeftContent}
      topRightContent={topRightContent}
      bottomLeftContent={bottomLeftContent}
      bottomRightContent={bottomRightContent}
      className={className}
    />
  );
};
