import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  list: [],
  flightTabChanged: false,
  selectedTripType: "",
};
const flightEditingSlice = createSlice({
  name: "flightEditing",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setAirlineFlightEditing: (state, action) => {
      state.list = action.payload;
    },
    setFlightChange: (state, action) => {
      state.flightTabChanged = action.payload;
    },
    setTripType: (state, action) => {
      state.selectedTripType = action.payload;
    },
  },
});

export const { actions } = flightEditingSlice;

export const { setAirlineFlightEditing, setFlightChange, setTripType } =
  actions;

export const selFlightEditing = (state) => state.flightEditing.list;
export const selFlightHasChanged = (state) =>
  state.flightEditing.flightTabChanged;
export const selSelectedTripType = (state) =>
  state.flightEditing.selectedTripType;

const reducer = {
  flightEditing: flightEditingSlice.reducer,
};

export default reducer;
