import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  list: {},
};
const flightReportsSlice = createSlice({
  name: "flightReports",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setFlightReports: (state, action) => {
      let reports = { ...action.payload };
      if ("dispatch" in reports) {
        reports["dispatch_release_form"] = { ...reports.dispatch };
        delete reports.dispatch;
      }
      state.list = reports;
    },
    updateFlightReport: (state, action) => {
      let list = { ...state.list };
      list[action.payload.type] = {
        ...list[action.payload.type],
        ...action.payload.report,
      };
      state.list = list;
    },
  },
});

export const { actions } = flightReportsSlice;

export const { setFlightReports, updateFlightReport } = actions;

export const selFlightReports = (state) => state.flightReports.list;

const reducer = {
  flightReports: flightReportsSlice.reducer,
};

export default reducer;
