import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  positions: [],
  aircrafts: [],
  defaultSettings: [],
  airlineSettings: { id: "", ftlSettings: [] },
};

const ftlSettingsSlice = createSlice({
  name: "ftlSettings",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setDefaultFtlSettings: (state, action) => {
      state.defaultSettings = action.payload;
    },
    setAirlineFtlSettings: (state, action) => {
      state.airlineSettings = action.payload;
    },
  },
});
/* --------------------------------- Actions -------------------------------- */
const { actions } = ftlSettingsSlice;

export const { setAirlineFtlSettings, setDefaultFtlSettings } = actions;
/* -------------------------------- Selectors ------------------------------- */
export const selFtlSettings = (state) => state.ftlSettings;
/* -------------------------------------------------------------------------- */
const reducer = {
  ftlSettings: ftlSettingsSlice.reducer,
};

export default reducer;
