import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { resetStore } from "store/actions";
/* -------------------------------------------------------------------------- */
const initialState = {};
/* -------------------------------------------------------------------------- */
const airportsSlice = createSlice({
  name: "airports",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setAirportList: (state, action) => {
      state.airports = action.payload;
    },
  },
});

export const { actions } = airportsSlice;
export const { setAirportList } = actions;

// selectors

export const selAirportList = (state) => state.airport.airports;

const reducer = {
  airport: airportsSlice.reducer,
};

export default reducer;
