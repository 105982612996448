export const IDLE = "idle";
export const WAITING = "waiting";

export const INITCUT = "init";
export const FAILEDCUT = "failed";
export const ONCUT = "oncut";

// upoload

export const INITUPLOAD = "init-upload";
export const CHECKFAILED = "check-failed";
export const READYFORUPLOAD = "ready-for-upload";

export const ONCHECK = "upload-check";
export const ONUPLOAd = "on-upload";
export const DONEUPLOAD = "finish-upload";

// file
export const INITFILE = "init-file";
export const SUCCESSFILE = "success-file";
export const FAILEDFILE = "failed-file";
export const ONPROGRESSFILE = "progress-file";
