import loaderClient, {
  clientNoLoader,
  loaderWithSetter,
} from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import { setAirlineSetting } from "store/slices/airline/airlineSlice";
import {
  setCountriesInUser,
  setCurrencyCodes,
  setDepartment,
  setGeneralSettingForm,
} from "./generalSettingSlice";
import { selAirlineId } from "store/slices";

export const getAirlineSettings =
  (id, callback) => async (dispatch, getState) => {
    let airlineId = id ?? selAirlineId(getState());
    const client = loaderClient("get")(`/airlines/${airlineId}/settings`);
    const res = await client({})({});
    if (res.error) return;
    console.log("resssss", res.message);
    dispatch(setAirlineSetting(res.message));
    return callback && callback(res.message);
  };

export const updateAirlineSettings =
  (settingsId, body, callback) => async (dispatch) => {
    const client = clientNoLoader("patch")(`/airline-settings/${settingsId}`);
    const res = await client(body)({});
    if (res.error) {
      callback?.(res.error);
      return;
    }
    toast.success("Flight status updated successfully!");
    dispatch(setAirlineSetting(res.message));
    return callback?.(res.error);
  };

export const patchAirlineGeneralSetting =
  (data, trip_types, callback = false) =>
  // console.log('patchAirlineGeneralSetting',id)
  async (dispatch, getState) => {
    console.log("test data 1", data);
    const id = selAirlineId(getState());
    const client = loaderClient("patch")(`airlines/general-setting/${id}`);
    const res = await client({ ...data, trip_types })({});
    console.log("client_", res);
    if (!res.error) {
      toast.success("General setting edited successfully");
      dispatch(setGeneralSettingForm({ ...data, trip_types }));
    }
    // res.error?  :  toast.success("General setting edited successfully");
  };

export const editAirlineSeatClass =
  (id, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("patch")(
      `/airline-settings/${id}/seat-classes`
    );
    const res = await client(data)({});
    if (res.error) return;
    return callback && callback();
  };

export const createAirlineSeatClass =
  (id, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("post")(`/airline-settings/${id}/seat-classes`);

    const res = await client(data)({});
    if (res.error) return;
    return callback && callback();
  };

export const deleteAirlineSeatClass =
  (id, classCode, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("delete")(
      `/airline-settings/${id}/seat-classes`
    );
    const res = await client({ classCode })({});
    if (res.error) return;
    return callback && callback();
  };

export const getCurrencyCodes = (id) => async (dispatch) => {
  const client = loaderClient("get")(`currency-codes`);
  const res = await client({})({});
  if (res.error) return toast.error(res.message.cu);
  dispatch(setCurrencyCodes(res.message.currencies));
};

export const getDepartment = (query) => async (dispatch, getState) => {
  let airlineId = selAirlineId(getState());
  let params = `airline=${airlineId}`;
  if (query) params += `&${query}`;
  const client = loaderClient("get")(`/departments?${params}`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setDepartment(res));
};

export const createDepartment = (data, callback) => async (dispatch) => {
  const client = loaderClient("post")(`/departments`);
  const res = await client(data)({});
  if (res.error) return;
  return callback && callback();
};

export const updateDepartment = (id, data, callback) => async (dispatch) => {
  const client = loaderClient("patch")(`/departments/${id}`);
  const res = await client(data)({});
  if (res.error) return;
  return callback && callback();
};

export const deleteDepartment = (id, callback) => async (dispatch) => {
  console.log(id);

  const client = loaderClient("delete")(`/departments/${id}?softDelete=false`);
  const res = await client({})({});
  if (res.error) return;
  return callback && callback();
};

export const getCountries = (setter, search) => async (dispatch) => {
  let searchValue = search?.replace("+", "");
  searchValue = searchValue?.replace("(", "");
  searchValue = searchValue?.replace(")", "");
  if (!search || searchValue?.length < 2) return;
  const res = await loaderWithSetter(setter)("get")(`/countries`)({
    params: {
      search: searchValue,
    },
  })({});
  if (res.error) return;
  return dispatch(setCountriesInUser(res.message));
};
