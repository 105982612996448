/* eslint-disable react/jsx-no-bind */
import {
  useTheme,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  CloseIcon,
  CrewIcon,
  FleetIcon,
  OPSIcon,
  ReportIcon,
  SettingsIcon,
} from "app/components/common/Icons";
import { ListItem } from "app/components/common/ListItem";
import GroupButton from "app/components/common/GroupButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { selBaseAirline } from "store/slices";
import { useSelector } from "react-redux";
import Avatar from "app/components/modules/Avatar";

const useStyles = makeStyles((theme) => ({
  more: {
    cursor: "pointer",
    color: `${theme.palette.black["80"]} !important`,

    marginLeft: "32px",
    "& .MuiButtonBase-root": {
      padding: 0,
    },
    "& .MuiListItemIcon-root": {
      transform: "none !important",
    },
    "& .MuiIconButton-root": {
      marginLeft: "0px",
    },
    "& .MuiSvgIcon-root": {
      color: `${theme.palette.black["80"]} !important`,
    },
  },
  moreItems: {
    cursor: "pointer",
    background: theme.palette.common.white,
    paddingBottom: "4px",
    marginLeft: "38px",
  },
  moreItem: {
    marginTop: "4px !important",
    paddingBottom: "0px",
    "& .MuiListItem-root ": {
      marginTop: 0,
    },
    "& .MuiListItemText-root": {
      marginTop: "0px",
      marginBottom: "0px",
    },

    "& .MuiTypography-root ": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  moreItemHover: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      borderLeftStyle: "solid",
      borderLeftWidth: "4px",
      borderRadius: "2px",
      borderLeftColor: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    paddingTop: "24px",
    width: "264px",
  },
  drawerHeader: {
    marginLeft: "30px",
    display: "flex",
    "& .MuiAvatar-root": {
      width: "40px",
      height: "40px",
      marginRight: "8px",
    },
    "& .MuiTypography-root": {
      color: theme.palette.main["100"],
    },
    "& .MuiDivider-root": {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
}));
const sidebarItemsOne = [
  {
    name: "OPS",
    children: [
      { name: "Timeline", link: "flights/timeline" },
      { name: "Table", link: "flights" },
      { name: "Calendar", link: "flights/calendar" },
    ],
    icon: OPSIcon,
  },
  {
    name: "Crew",
    children: [{ name: "Crew Calendar", link: "crew-calendar" }],
    icon: CalendarIcon,
    link: "crew-calendar",
    disabled: true,
  },
  {
    name: "Reports",
    children: [{ name: "Reports", link: "reports" }],
    icon: ReportIcon,
    disabled: true,
  },
];
const sidebarItemsTwo = [
  {
    name: "General settings",
    children: [
      { name: "General Settings", link: "general-settings" },
      { name: "Users", link: "users" },
      { name: "Admin Privileges", link: "admin-privileges" },
      { name: "Email Templates", link: "email-templates" },
      { name: "Messaging", link: "messaging" },
      { name: "Ticketing", link: "ticketing" },
    ],
    icon: SettingsIcon,
    link: "general-settings",
  },
  {
    name: "Fleet",
    children: [
      { name: "Fleet", link: "fleet" },
      { name: "Fleet Documents", link: "fleet-docs" },
      { name: "Seats", link: "seats" },
      { name: "Crew Position", link: "crew-position" },
    ],
    icon: FleetIcon,
    link: "fleet",
  },
  {
    name: "Crew",
    children: [
      { name: "Crew Currency", link: "crew-currency" },
      { name: "Crew Certificates", link: "crew-certificates" },
      { name: "Crew Ratings", link: "crew-ratings" },
      { name: "Ftl Settings", link: "ftl-settings" },
      { name: "Duties Setup", link: "duties-setup" },
    ],
    icon: CrewIcon,
    link: "crew-calendar",
  },
  {
    name: "Flight",
    children: [
      { name: "Flight Editing", link: "flight-editing" },
      { name: "Company Flight", link: "flight-default" },
    ],
    icon: OPSIcon,
    link: "crew-calendar",
  },
];

const Sidebar = ({ onClose, open }) => {
  const airline = useSelector(selBaseAirline);
  console.log("drawer-open", open);
  const classes = useStyles();

  // ===================== S E L E C T ====================

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid className={classes.drawerHeader}>
        <Grid container alignItems="center">
          <Avatar imageId={airline.logo} className={classes.airlineAvatar} />
          <Typography variant="h6">{airline.name}</Typography>
        </Grid>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid
        style={{
          marginRight: "12px",
          marginLeft: "12px",
          marginTop: "20px",
          marginBottom: "8px",
        }}
      >
        <Divider />
      </Grid>
      {sidebarItemsOne?.map((item, index) => {
        return <Category item={item} />;
      })}
      <Grid
        style={{
          marginRight: "12px",
          marginLeft: "12px",
          marginTop: "20px",
          marginBottom: "8px",
        }}
      >
        <Divider />
      </Grid>
      {sidebarItemsTwo?.map((item, index) => {
        return <Category item={item} />;
      })}
    </Drawer>
  );
};

export default Sidebar;

const SubCat = ({ item, selected }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Link
      key={item.name}
      to={`/app/dashboard/${item.link}`}
      style={{
        textDecoration: "none",
      }}
    >
      <ListItem
        key={item.name}
        className={`${classes.moreItem} ${!selected && classes.moreItemHover}`}
        text={item.name}
        animation={false}
        borderColor={!selected ? null : theme.palette.primary.main}
      />
    </Link>
  );
};
const Category = ({ item }) => {
  const history = useHistory();
  const [openMore, setOpenMore] = useState(false);
  const classes = useStyles();
  const toggleCollapse = (e) => {
    setOpenMore(!item.disabled && !openMore);
  };
  return (
    <ListItem
      onClick={toggleCollapse}
      key={item.name}
      text={item.name}
      disabled={item.disabled}
      endAdornment={
        <Grid>
          {openMore ? (
            <IconButton>
              <ArrowUpIcon
                className={classes.arrowUp}
                onClick={toggleCollapse}
              />
            </IconButton>
          ) : (
            <IconButton>
              <ArrowDownIcon
                className={classes.arrowDown}
                onClick={toggleCollapse}
              />
            </IconButton>
          )}
        </Grid>
      }
      startAdornment={<item.icon />}
      collapse={openMore}
      className={classes.more}
      collapsibleItems={
        <Grid className={classes.moreItems}>
          {item.children?.map((item) => {
            const path = history.location.pathname.split("/");
            const selectPath = path[path.length - 1];
            const itemPath = item.link?.split("/");
            const selectItemPath = itemPath[itemPath.length - 1];
            const selected = selectPath == selectItemPath;

            return <SubCat item={item} selected={selected} />;
          })}
        </Grid>
      }
    />
  );
};
