export const MODULELIBRARY = "lib";
export const MODULEUSER = "user";
export const MODULEFLIGHT = "flight";
export const MODULEFLEET = "fleet";
export const MODULEOFP = "ofp";
export const MODULEBRIFING = "briefing";
export const MODULEWB = "wb";
export const MODULEPERFORMANCE = "performance";
export const MODULEREPORT = "report";
export const MODULEAIRLINE = "airline";
export const MODULEEFB = "efb";

export const allModules = [
  MODULEFLIGHT,
  MODULELIBRARY,
  // MODULEUSER,
  MODULEFLEET,
  MODULEOFP,
  MODULEBRIFING,
  MODULEWB,
  MODULEPERFORMANCE,
  MODULEREPORT,
  MODULEEFB,
  // MODULEAIRLINE,
];

export const dictionary = {
  "enr:flight:module": MODULEFLIGHT,
  "enr:user:module": MODULEUSER,
  "enr:library:module": MODULELIBRARY,
  "enr:fleet:module": MODULEFLEET,
  "enr:briefing:module": MODULEBRIFING,
  "enr:ofp:module": MODULEOFP,
  "enr:w&b:module": MODULEWB,
  "enr:performance:module": MODULEPERFORMANCE,
  "enr:report:module": MODULEREPORT,
  "enr:airline:module": MODULEREPORT,
  "enr:efb:module": MODULEEFB,
};
