import { useState } from "react";
import * as R from "ramda";
import { yupFactory } from "./CreateDYupJSon";

export default function useValidation(init) {
  const [errors, setErrors] = useState({});
  const { data, key } = init;

  const [checker, checkVal] = yupFactory(data, key);
  async function checkFiled(name, value) {
    const message = await checker(name, value);
    setErrors((prev) => ({
      ...prev,
      [name]: message,
    }));
  }

  async function checkValidation(values) {
    let [mess, allmess] = await checkVal(values);
    //fleet validation

    if (key === "basicForm") {
      mess = fleetOpsValidation(values, mess, allmess);
    }

    if (key === "perForm") {
      mess = fleetPerformValidation(values, mess, allmess);
    }

    if (key === "fleetFullForm") {
      mess = fleetFullFormValidation(values, mess, allmess);
    } else if (key === "userForm") {
      mess = adminValidation(values, mess, allmess);
    }

    // if (key === 'fleetFullForm') {
    //   mess = fleetFormValidation(values, mess, allmess);
    // }

    if (mess) {
      setErrors({ ...allmess });
      return false;
    }
    return true;
  }

  //fleet Validation start
  const fleetFullFormValidation = (values, mess, allmess) => {
    mess += fleetOpsValidation(values, mess, allmess);
    mess += fleetPerformValidation(values, mess, allmess);
    return mess;
  };

  const fleetOpsValidation = (values, mess, allmess) => {
    if (!!values.short_name) {
      const validShortName = /^[0-9A-Za-z\-]+$/;
      if (!validShortName.test(values.short_name)) {
        allmess.short_name = "invalid character in short name";
        mess += "/ invalid character in short name";
      } else if (values.short_name.length > 6) {
        allmess.short_name = "short name must be a maximum of 6 characters";
        mess += "/ short name  be a maximum of 6 characters";
      }
    }
    if (!!values.registration) {
      const validRegistration = /^[0-9A-Z\-]+$/;
      if (!validRegistration.test(values.registration)) {
        allmess.registration = "valid characters is A-Z,0-9,-";
        mess += "/ valid characters is A-Z,0-9,-";
      } else if (values.registration.length > 8) {
        allmess.registration = "registration must be a maximum of 8 characters";
        mess += "/ registration must be a maximum of 8 characters";
      } else if (values.registration.length < 6) {
        allmess.registration = "registration must be at least 6 characters";
        mess += "/ registration must be at least 6 characters";
      }
    }

    if (!!values.cockpit_crew) {
      if (values.cockpit_crew < 1) {
        allmess.cockpit_crew = "cockpit crew must be at least 1";
        mess += "/ cockpit crew must be at least 1";
      } else if (values.cockpit_crew > 10) {
        allmess.cockpit_crew = "cockpit crew must maximum of 10";
        mess += "/ cockpit crew must maximum of 10";
      }
    }
    if (!!values.cabin_crew) {
      if (values.cabin_crew < 0) {
        allmess.cabin_crew = "cockpit crew must be at least 0";
        mess += "/ cabin crew must be at least 0";
      } else if (values.cabin_crew > 30) {
        allmess.cabin_crew = "cockpit crew must maximum of 30";
        mess += "/ cabin crew crew must maximum of 30";
      }
    }
    if (!!values.mtow) {
      if (values.mtow < 0) {
        allmess.mtow = "MTOW must be at least 0";
        mess += "/ MTOW must be at least 0";
      } else if (values.mtow > 1500000) {
        allmess.mtow = "MTOW  must maximum of 1,500,000";
        mess += "/ MTOW must maximum of 1,500,000";
      }
    }

    if (!!values.year_of_production) {
      const year = new Date().getFullYear();
      if (values.year_of_production < 1900) {
        allmess.year_of_production = "year of production must be minimum 1900";
        mess += "/ year of production must be minimum 1900";
      } else if (values.year_of_production > year) {
        allmess.year_of_production = `year of production must be maximum ${year}`;
        mess += `/ year of production must be maximum ${year}`;
      }
    }

    if (!!values.minimum_runway_length) {
      if (values.minimum_runway_length < 50) {
        allmess.minimum_runway_length =
          "minimum runway length must be minimum 50";
        mess += "/ minimum runway length must be minimum 50";
      } else if (values.minimum_runway_length > 1500000) {
        allmess.minimum_runway_length =
          "minimum runway length must be minimum 1,500,000";
        mess += "/ minimum runway length must be maximum 1,500,000";
      }
    }

    if (!!values.maximum_fuel) {
      if (values.maximum_fuel < 0) {
        allmess.maximum_fuel = "maximum fuel must be minimum 0";
        mess += "/ maximum fuel must be minimum 0";
      } else if (values.maximum_fuel > 1500000) {
        allmess.maximum_fuel = "maximum fuel must be maximum 1,500,000";
        mess += "/ maximum fuel must be maximum 1,500,000";
      }
    }

    if (!!values.burn_on_taxi) {
      if (values.burn_on_taxi < 0) {
        allmess.burn_on_taxi = "burn on taxi must be minimum 0";
        mess += "/ burn on taxi must be minimum 0";
      } else if (values.burn_on_taxi > 30000) {
        allmess.burn_on_taxi = "burn on taxi must be maximum 30,000";
        mess += "/ burn on taxi must be maximum 30,000";
      }
    }

    setErrors({ ...allmess });
    return mess;
  };

  const adminValidation = (values, mess, allmess) => {
    const validPhoneRegex = RegExp(/^[0-9]+$/);
    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const validDateRegex = RegExp(/^\d{1,2}\/\d{1,2}\/\d{4}$/);

    if (!values.telephone?.code || !values.telephone?.number) {
      allmess.telephone = "is required";
      mess += "/ is required";
    } else if (values.telephone?.number?.length < 10) {
      allmess.telephone = "phone number must be minimum 10 character";
      mess += "/ phone number must be minimum 10 character";
    } else if (!validPhoneRegex.test(values.telephone?.number)) {
      allmess.telephone = "invalid phone number";
      mess += "/ invalid phone number";
    }

    if (!values.cell_phone?.code || !values.cell_phone?.number) {
      allmess.cell_phone = "is required";
      mess += "/ is required";
    } else if (values.cell_phone.number?.length < 10) {
      allmess.cell_phone = "phone number must be minimum 10 character";
      mess += "/ phone number must be minimum 10 character";
    } else if (!validPhoneRegex.test(values.cell_phone?.number)) {
      allmess.cell_phone = "invalid phone number";
      mess += "/ invalid phone number";
    }

    if (!!values.email && !validEmailRegex.test(values.email)) {
      allmess.email = "invalid email";
      mess += "/ invalid email";
    }

    setErrors({ ...allmess });
    return mess;
  };

  const fleetPerformValidation = (values, mess, allmess) => {
    const validTime = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
    if (!!values.minimum_ground_time) {
      if (!validTime.test(values.minimum_ground_time)) {
        allmess.minimum_ground_time = "value should be format of HH:mm";
        mess += " / value should be format of HH:mm";
      }
    }
    setErrors({ ...allmess });
    return mess;
  };

  //fleet validation end

  return {
    errors,
    checkFiled,
    checkValidation,
  };
}

const mapper = (field) => {
  const [name, validationType] = field;

  if (validationType === "time")
    return {
      name: `${name}`,
      validationType: "string",
      validations: [
        {
          type: "required",
          params: ["is required"],
        },
        {
          type: "matches",
          // params: [/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "must be hh:mm"],
          params: [
            /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
            {
              message: " must be HH:MM",
              excludeEmptyString: false,
            },
          ],
        },
      ],
    };

  return {
    name: `${name}`,
    validationType,
    validations: [
      {
        type: "required",
        params: ["is required"],
      },
    ],
  };
};

export const mapFieldArrToObjSchema = R.map(mapper);
