import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";

/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      paddingInline: 0,
      "& .MuiTypography-root": {
        color: theme.palette.common.black,
      },
    },
    dialogContent: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: "relative",
      overflow: ({ overflow }) => `${overflow[0]} ${overflow[1]}`,
    },
    dialogActions: {
      paddingTop: theme.spacing(3),
      paddingInline: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.black["20"]}`,
    },
    container: {
      borderRadius: 8,
      "& .MuiPaper-root.MuiDialog-paper": {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: "100%",
      },
    },
    headerContainer: {
      display: "flex",
      borderBottom: `1px solid ${theme.palette.black["20"]}`,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerContent: {
      alignItems: "center",
      display: "flex",
    },
  }),
  { name: "CommonModal" }
);

const Modal = ({
  open,
  onClose,
  title,
  headerContent,
  content,
  actions,
  style,
  maxWidth = "sm",
  className,
  contentClass,
  disableBackdropClick = true,
  overflow = ["hidden", "auto"],
  contentClasses = "",
  ...rest
}) => {
  if (overflow === false) overflow = ["hidden", "hidden"];
  if (overflow === true) overflow = ["auto", "auto"];
  const classes = useStyles({ overflow });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={disableBackdropClick}
      style={style}
      maxWidth={maxWidth}
      className={`${classes.container} ${className}`}
      {...rest}
    >
      <Box className={classes.headerContainer}>
        {title && (
          <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
        )}
        <DialogContent className={classes.headerContent}>
          {headerContent}
        </DialogContent>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {content && (
        <DialogContent
          className={`${classes.dialogContent} ${classes.contentClass} ${contentClasses}`}
        >
          {content}
        </DialogContent>
      )}
      {actions && (
        <DialogActions className={classes.dialogActions}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  headerContent: PropTypes.node,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  style: PropTypes.object,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  className: PropTypes.string,
  contentClass: PropTypes.string,
  overflow: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  actions: null,
  maxWidth: "sm",
};

export { Modal };
