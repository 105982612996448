import {
  Box,
  Collapse,
  Grid,
  ListItemIcon,
  ListItemText,
  ListItem as MuiListItem,
  Typography,
  makeStyles,
} from "@material-ui/core";

import React from "react";
export const ListItem = React.memo(
  ({
    className,
    text,
    onClick,
    endAdornment,
    startAdornment,
    collapse,
    collapsibleItems,
    borderColor,
    roundedBorder = true,
    underline,
    animation = true,
    variant = "body1",
    selected,
    children,
    disabled,
    ...rest
  }) => {
    const classes = useStyles();
    return (
      <Grid className={className}>
        <MuiListItem
          disabled={disabled}
          onClick={onClick}
          style={{
            ...(borderColor && {
              borderLeftStyle: "solid",
              borderLeftWidth: "4px",
              ...(roundedBorder && {
                borderRadius: "2px",
              }),
              borderLeftColor: borderColor,
            }),
          }}
          {...rest}
        >
          {!startAdornment ? null : (
            <ListItemIcon className={classes.startAdornment}>
              {startAdornment}
            </ListItemIcon>
          )}
          {children ? (
            children
          ) : (
            <ListItemText disableTypography={true}>
              <Typography variant={variant}>{text}</Typography>
            </ListItemText>
          )}
          {!endAdornment ? null : (
            <ListItemIcon
              className={`${classes.endAdornment} ${
                animation && collapse && classes.iconAnimation
              }`}
            >
              {endAdornment}
            </ListItemIcon>
          )}
          {underline && (
            <Box
              className={selected ? classes.selected : null}
              style={{
                width: "100%",
              }}
            />
          )}
        </MuiListItem>
        {collapsibleItems ? (
          <Collapse
            in={collapse}
            timeout="auto"
            unmountOnExit
            className={classes.collapsible}
          >
            {collapsibleItems}
          </Collapse>
        ) : null}
      </Grid>
    );
  }
);

const useStyles = makeStyles(
  (theme) => ({
    item: { color: theme.palette.primary.main },
    subItem: {
      paddingInline: 20,
      "& .MuiListItemText-primary": {
        fontSize: "0.9rem",
      },
    },
    endAdornment: {
      // paddingLeft: "4px",
      transition: "0.3s",
    },
    startAdornment: {
      paddingRight: "4px",
    },
    iconAnimation: {
      transform: "rotate(90deg)",
    },
    selected: {
      backgroundColor: "transparent",
      border: `2px solid ${theme.palette.primary.main}`,
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      position: "absolute",
      bottom: "0",
    },
    collapsible: {
      backgroundColor: theme.palette.primary["lightish"],
    },
  }),
  {
    name: "ListItem",
  }
);
