/* eslint-disable react/jsx-no-bind */
import {
  AppBar,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { userSignOut2 } from "actions";
import { Button } from "app/components/common/Button";
import {
  AddIcon,
  AirportIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CrewIcon,
  DashboardIcon,
  FleetIcon,
  LibraryIcon,
  MessagingIcon,
  NewTicketIcon,
  OPSIcon,
  ReportIcon,
  SCHEDIcon,
  SettingsIcon,
  SettingsMoreIcon,
  SupportIcon,
} from "app/components/common/Icons";
import { ListItem } from "app/components/common/ListItem";
import {
  Modal as AirportModal,
  Modal as ConfirmModal,
  Modal,
  Modal as SwitchModal,
} from "app/components/common/Modal";
import { Search } from "app/components/common/Search";
import { SwitchAirlines } from "app/components/common/SwitchDialog";
import Avatar from "app/components/modules/Avatar";
import BugReportModal from "app/components/modules/bugReport/BugReportModal";
import clsx from "clsx";
import Airport from "containers/AppLayout/Horizontal/Header/components/airport";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { selBaseAirline, selBaseUser } from "store/slices";
import AirlineModalInfo from "./AirlineModalInfo";
import Profile from "./components/Profile";
import { MAIN_HEADER_NAV_HEIGHT } from "containers/themes/attributes";
import ProgressBar from "app/components/modules/ProgressBar";
import { BugReportRounded } from "@material-ui/icons";
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    appBar: {
      backgroundColor: theme.palette.main["10"],
      borderBottom: `1.5px solid ${theme.palette.main["40"]}`,
      boxShadow: "none",
      height: MAIN_HEADER_NAV_HEIGHT,
    },
    drawerPaper: {
      width: "264px",
    },
    menuItem: {
      cursor: "pointer",
      marginRight: "32px ",
      color: theme.palette.primary.main,
      paddingLeft: 0,
      paddingRight: 0,
      "& .MuiListItemIcon-root ": {
        minWidth: "16px !important",
        fontSize: "16px",
      },
      "& .MuiListItem-root": {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        "&  .MuiListItemText-root > .MuiTypography-root": {
          ...theme.typography.h7,
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
      "& svg": {
        marginRight: "0px !important",
      },
      "&:hover": {
        backgroundColor: "transparent",
        "& .MuiBox-root": {
          border: `2px solid ${theme.palette.primary.main}`,
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
          position: "absolute",
          bottom: "0",
        },
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: "transparent",
        "& .MuiBox-root": {
          border: `2px solid ${theme.palette.primary.main}`,
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
          position: "absolute",
          bottom: "0",
        },
      },
    },
    selected: {
      "&  .MuiListItemText-root > .MuiTypography-root": {
        fontWeight: `${theme.typography.fontWeightMedium} !important`,
      },
    },
    toolbar: {
      flexDirection: "column",
      paddingTop: theme.spacing(2),
      paddingInline: theme.spacing(4),
      justifyContent: "space-between",
      flexDirection: "column",
      height: "100%",
    },
    hamburger: {
      fontSize: "20px",
      border: `1px solid ${theme.palette.main["40"]}`,
      padding: "2px",
      marginRight: "16px",
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },
    settings: {
      border: "1px solid ",
      borderColor: theme.palette.main["40"],
      width: "32px",
      height: "32px",
      marginLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },
    airport: {
      border: "1px solid ",
      borderColor: theme.palette.main["40"],
      width: "32px",
      height: "32px",
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },

    toolbarTop: {
      flexWrap: "nowrap",
      alignItems: "center",
    },
    airlineAvatar: {
      marginRight: theme.spacing(1),
      cursor: "pointer",
    },
    avatar: {
      marginLeft: theme.spacing(1),
      width: "32px !important",
      height: "32px !important",
      cursor: "pointer",
    },
    airlineName: {
      color: theme.palette.main["100"],
    },
    noWrap: {
      flexWrap: "nowrap",
    },
    toolbarRight: {
      flexWrap: "nowrap",
      justifyContent: "end",
      alignItems: "center",
    },
    collapseItem: {
      color: theme.palette.black["40"],
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    textDecoration: {
      textDecoration: "none !important",
    },
    more: {
      cursor: "pointer",
      marginRight: "32px",
      "&:hover": {
        backgroundColor: "transparent",
        "& .MuiBox-root": {
          border: `2px solid ${theme.palette.primary.main}`,
          borderTopRightRadius: "25px",
          borderTopLeftRadius: "25px",
          position: "absolute",
          bottom: "0",
        },
      },
      "& .MuiListItemText-root": {
        minWidth: "auto",
      },
      "& .MuiListItem-root": {
        color: theme.palette.main["100"],
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
      },
      "& .MuiButtonBase-root": {
        padding: 0,
      },
      "& .MuiListItemIcon-root": {
        transform: "none !important",
      },
      "& .MuiIconButton-root": {
        marginLeft: "0px",
      },
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },
    moreItems: {
      position: "absolute",
      width: "160px",
      background: "white",
      cursor: "pointer",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      background: theme.palette.common.white,
    },
    moreItem: {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "& .MuiListItem-root ": {
        marginTop: 0,
        paddingBottom: "0px",
        marginLeft: theme.spacing(1),
      },
      "& .MuiListItemText-root": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "&:hover": {
        backgroundColor: `${theme.palette.black["20"]} !important`,
      },
      "& .MuiTypography-root ": {
        color: `${theme.palette.black["60"]} !important`,
      },
      // "& .MuiListItem-root .MuiListItemIcon-root .MuiGrid-root .MuiButtonBase-root .MuiIconButton-root":
      //   {
      //     marginLeft: "0px !important",
      //   },
    },
    plusSettings: {
      // "& .MuiIconButton-root": {
      //   marginRight: "16px"
      // },
      border: "1px solid  ",
      borderColor: theme.palette.main["40"],
      width: "64px",
      height: "32px",
      borderRadius: theme.shape.borderRadius,
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },
    new: {
      cursor: "pointer",
      marginRight: "-16px",

      "& .MuiListItemText-root": {
        minWidth: "auto",
      },
      "& .MuiListItem-root": {
        color: theme.palette.main["100"],
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& .MuiButtonBase-root": {
        padding: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginRight: "16px",
      },
      "& .MuiListItemIcon-root": {
        transform: "none !important",
      },
      "& .MuiIconButton-root": {
        marginLeft: "0px",
        marginRight: "16px",
      },
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.main["100"]} !important`,
      },
    },
    newItems: {
      position: "absolute",
      width: "160px",
      background: "white",
      cursor: "pointer",

      background: theme.palette.common.white,
      top: "40px",
      right: "-97px",
    },
    newItem: {
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "& .MuiListItem-root ": {
        marginTop: 0,
        paddingBottom: "0px",
        marginLeft: theme.spacing(1),
      },
      "& .MuiListItemText-root": {
        marginTop: "0px",
        marginBottom: "0px",
      },
      "&:hover": {
        backgroundColor: `${theme.palette.black["20"]} !important`,
      },
      "& .MuiTypography-root ": {
        color: `${theme.palette.black["60"]} !important`,
      },
      // "& .MuiListItem-root .MuiListItemIcon-root .MuiGrid-root .MuiButtonBase-root .MuiIconButton-root":
      //   {
      //     marginLeft: "0px !important",
      //   },
    },
    dividerStyle: {
      marginRight: "24px",
      height: "30px",
      marginLeft: "-6px",
    },
    drawerHeader: {
      // textAlign: "right",
      marginLeft: "30px",

      display: "flex",
      "& .MuiAvatar-root": {
        width: "40px",
        height: "40px",
        marginRight: theme.spacing(1),
        background: theme.palette.main["100"],
      },
      "& .MuiTypography-root": {
        color: theme.palette.main["100"],
      },
      "& .MuiDivider-root": {
        paddingTop: "20px",
        paddingBottom: "20px",
      },
    },
    switchDialog: {
      "& .MuiDialog-paper": {
        padding: theme.spacing(1),
        width: "fit-content !important",
      },
      "& .MuiDialogActions-root": {
        justifyContent: "flex-start",
        paddingLeft: "12px",
        paddingTop: "12px",
        paddingBottom: 0,
      },
      "& .MuiDialogContent-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    confirmDialog: {
      "& .MuiDialogTitle-root": {
        paddingLeft: 0,
      },
    },
    switchItem: {
      width: 338,
      "& .MuiListItem-button": {
        border: `1px solid ${theme.palette.black["20"]}`,
        borderRadius: "5px",
        minWidth: "200px !important",
        marginTop: "12px",
        marginBottom: "12px",
      },
    },
    container: {
      borderRadius: 8,
      height: "700px",
      top: "120px",
      "& .MuiPaper-root.MuiDialog-paper": {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        maxWidth: "1015px !important",
      },
      "& .MuiDialogContent": {
        width: "1015px !important",
      },
      "& .MuiDialogContent-root": {
        paddingTop: "0 !important",
      },
      "& .MuiBox-root.containerHeader": {
        marginLeft: "inherit !important",
        marginRight: "inherit !important",
      },
    },
    addIcon: {
      fill: theme.palette.main["100"],
    },
    newIconsColor: {
      fill: `${theme.palette.black["40"]} !important`,
    },
    evenRow: {
      backgroundColor: theme.palette.common.white,
    },
    oddRow: {
      backgroundColor: theme.palette.black["10"],
    },
    disabledLink: {
      pointerEvents: "none",
      color: "gray",
      cursor: "not-allowed",
      textDecoration: "none",
    },
  }),
  {
    name: "Header",
  }
);

const menuMoreItems = [
  { name: "Messaging", link: "messaging", icon: <MessagingIcon /> },
  { name: "Support", link: "ticketing", icon: <SupportIcon /> },
  { name: "Settings", link: "settings", icon: <SettingsMoreIcon /> },
];

const Header = ({ openDrawer }) => {
  const classes = useStyles();
  const newItem = [
    {
      name: "New SCHED",
      link: "sched?showModal=true",
      icon: <SCHEDIcon className={classes.newIconsColor} />,
    },
    {
      name: "New trip",
      link: "flights?showModal=true",
      icon: <OPSIcon className={classes.newIconsColor} />,
    },
    {
      name: "New message",
      link: "messaging?showModal=true",
      icon: <MessagingIcon className={classes.newIconsColor} />,
    },
    {
      name: "New ticket",
      link: "ticketing?showModal=true",
      icon: <NewTicketIcon className={classes.newIconsColor} />,
    },
    {
      name: "New User",
      link: "users?showModal=true",
      icon: <CrewIcon className={classes.newIconsColor} />,
    },
    {
      name: "New Fleet",
      link: "fleets?showModal=true",
      icon: <FleetIcon className={classes.newIconsColor} />,
    },
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [openOps, setOpenOps] = useState(false);
  const [openPlus, setOpenPlus] = useState(false);
  const [openNewButtom, setopenNewButtom] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openConfirmLogout, setOpenConfirmLogout] = useState(false);
  const [openAirportModal, setOpenAirportModal] = useState(false);
  const [openSwitch, setOpenSwitch] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selBaseUser);
  const airline = useSelector(selBaseAirline);
  console.log("header", airline.logo, user?.image?.id);
  const history = useHistory();
  let pathname = history.location.pathname;

  const menuItems = [
    {
      name: "Dashboard",
      icon: <DashboardIcon className={classes.menuItem} />,
      link: "/app/dashboard",
    },
    {
      name: "SCHED",
      icon: <SCHEDIcon className={classes.menuItem} />,
      link: "/app/dashboard/sched",
    },
    {
      name: "OPS",
      icon: <OPSIcon className={classes.menuItem} />,
      link: "/app/dashboard/flights",
      subLinks: ["/timeline", "/calendar"],
    },
    {
      name: "Crew",
      icon: <CrewIcon className={classes.menuItem} />,
      //link: "/app/dashboard/crew-calendar",
      disabled: true,
    },

    {
      name: "Library",
      icon: <LibraryIcon className={classes.menuItem} />,
      //link: "/app/dashboard/library",
      disabled: true,
    },
    {
      name: "Report",
      icon: <ReportIcon className={classes.menuItem} />,
      link: "/app/dashboard/reports",
      disabled: true,
    },
  ];

  const toggleCollapse = useCallback(
    (e) => {
      setOpenMore(!openMore);
      openOps && setOpenOps(false);
    },
    [openMore, openOps]
  );
  const toggleCollapseNewItem = useCallback(
    (e) => {
      setOpenPlus(!openPlus);
      openNewButtom && setopenNewButtom(false);
    },
    [openPlus, openNewButtom]
  );

  const handleClickProfile = useCallback(() => {
    setOpenProfile(!openProfile);
  }, [openProfile]);

  const handleLogOut = useCallback(() => {
    setOpenConfirmLogout(!openConfirmLogout);
  }, [openConfirmLogout]);

  const handleCloseLogOutModal = useCallback(() => {
    setOpenConfirmLogout(!openConfirmLogout);
  }, [openConfirmLogout]);

  const handleClickAirportModal = useCallback(() => {
    setOpenAirportModal(!openAirportModal);
  }, [openAirportModal]);

  const toggleBugReportModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const logoutUser = useCallback(() => {
    dispatch(userSignOut2());
  }, []);

  const handleSwitch = useCallback(() => {
    setOpenSwitch(!openSwitch);
  }, [openSwitch]);

  const handleClickAwayMore = useCallback(() => {
    setOpenMore(false);
  }, []);
  const handleClickAwayPlus = useCallback(() => {
    setOpenPlus(false);
  }, []);

  const handleClickSettings = useCallback(() => {
    history.push("/app/dashboard/settings");
  }, [history]);
  console.log("history.location", pathname);

  return (
    <>
      <Grid>
        <AppBar component="nav" className={classes.appBar}>
          <Toolbar className={classes.toolbar} disableGutters={false}>
            <Grid container className={classes.noWrap}>
              <Grid container className={classes.toolbarTop}>
                <Grid>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={openDrawer}
                    edge="start"
                    className={classes.hamburger}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid container alignItems="center">
                  <Avatar
                    onClick={() => setOpen(true)}
                    imageId={airline.logo}
                    className={classes.airlineAvatar}
                  />
                  <Typography
                    onClick={() => setOpen(true)}
                    variant="subtitle"
                    className={classes.menuItem}
                  >
                    {airline?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.toolbarRight}>
                <Search />
                <Divider
                  orientation="vertical"
                  className={classes.dividerStyle}
                />
                <Grid>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    className={classes.plusSettings}
                    onClick={toggleCollapseNewItem}
                  >
                    <AddIcon className={classes.addIcon} />

                    <Grid>
                      <ListItem
                        onClick={toggleCollapseNewItem}
                        underline={true}
                        endAdornment={
                          <Grid>
                            {openPlus ? (
                              <IconButton>
                                <ArrowUpIcon
                                  className={classes.addIcon}
                                  onClick={toggleCollapseNewItem}
                                />
                              </IconButton>
                            ) : (
                              <IconButton>
                                <ArrowDownIcon
                                  className={classes.addIcon}
                                  onClick={toggleCollapseNewItem}
                                />
                              </IconButton>
                            )}
                          </Grid>
                        }
                        collapse={openPlus}
                        className={classes.new}
                        collapsibleItems={
                          <ClickAwayListener onClickAway={handleClickAwayPlus}>
                            <Grid className={classes.newItems}>
                              {newItem?.map((item, index) => (
                                <Link
                                  key={index}
                                  to={`/app/dashboard/${item.link}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <ListItem
                                    key={index}
                                    onClick={toggleCollapseNewItem}
                                    className={classes.newItem}
                                    text={item.name}
                                    animation={false}
                                    startAdornment={item.icon}
                                  />
                                  <Divider
                                    className={
                                      index % 2 === 0
                                        ? classes.evenRow
                                        : classes.oddRow
                                    }
                                  />
                                </Link>
                              ))}
                            </Grid>
                          </ClickAwayListener>
                        }
                      />
                    </Grid>
                  </IconButton>
                </Grid>
                <Grid item className={classes.settings}>
                  <IconButton color="inherit" onClick={handleClickAirportModal}>
                    <AirportIcon />
                  </IconButton>
                </Grid>
                <Grid item className={classes.settings}>
                  <IconButton color="inherit" onClick={toggleBugReportModal}>
                    <BugReportRounded />
                  </IconButton>
                </Grid>
                <Grid item className={classes.settings}>
                  <IconButton color="inherit" onClick={handleClickSettings}>
                    <SettingsIcon />
                  </IconButton>
                </Grid>
                <Avatar
                  onClick={handleClickProfile}
                  className={classes.avatar}
                  imageId={user?.image?.id}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.noWrap}>
              {menuItems.map((item) => {
                let selected = pathname === item.link;
                if (!selected && item.subLinks)
                  selected = item.subLinks.some((sl) => pathname.includes(sl));
                return (
                  <MenuItem
                    key={item.name}
                    item={item}
                    selected={selected}
                    disabled={item.disabled}
                  />
                );
              })}
              <Grid style={{ position: "relative" }}>
                <ListItem
                  onClick={toggleCollapse}
                  text={"More"}
                  underline={true}
                  // selected={[
                  //   "/app/dashboard/messaging",
                  //   "/app/dashboard/ticketing",
                  //   "/app/dashboard/settings",
                  // ].includes(history.location.pathname)}
                  endAdornment={
                    <Grid>
                      {openMore ? (
                        <IconButton>
                          <ArrowUpIcon onClick={toggleCollapse} />
                        </IconButton>
                      ) : (
                        <IconButton>
                          <ArrowDownIcon onClick={toggleCollapse} />
                        </IconButton>
                      )}
                    </Grid>
                  }
                  collapse={openMore}
                  className={classes.more}
                  collapsibleItems={
                    <ClickAwayListener onClickAway={handleClickAwayMore}>
                      <Grid className={classes.moreItems}>
                        {menuMoreItems?.map((item, index) => (
                          <Link
                            key={index}
                            to={`/app/dashboard/${item.link}`}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <ListItem
                              key={index}
                              onClick={toggleCollapse}
                              className={classes.moreItem}
                              text={item.name}
                              animation={false}
                              startAdornment={item.icon}
                            />
                            <Divider />
                          </Link>
                        ))}
                      </Grid>
                    </ClickAwayListener>
                  }
                />
              </Grid>
            </Grid>
          </Toolbar>
          <ProgressBar />
        </AppBar>

        <BugReportModal open={isModalOpen} onClose={toggleBugReportModal} />
        <Profile
          open={openProfile}
          onClose={handleClickProfile}
          handleLogOut={handleLogOut}
          handleSwitch={handleSwitch}
        />
        <ConfirmModal
          className={classes.confirmDialog}
          title={<Typography variant="h6">Exit</Typography>}
          open={openConfirmLogout}
          onClose={handleCloseLogOutModal}
          content={
            <Typography variant="h6">
              Are you sure you want to logout?
            </Typography>
          }
          actions={
            <>
              <Button
                variant="text"
                onClick={() => {
                  handleCloseLogOutModal();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  logoutUser();
                }}
              >
                Confirm
              </Button>
            </>
          }
        />
        <AirportModal
          maxWidth="lg"
          className={classes.confirmDialog}
          title={"Airports directory"}
          open={openAirportModal}
          onClose={handleClickAirportModal}
          content={<Airport />}
        />
        <SwitchModal
          title={<Typography variant="h6">Switch account</Typography>}
          content={
            <Grid className={classes.switchItem}>
              <SwitchAirlines handleClose={handleSwitch} />
            </Grid>
          }
          open={openSwitch}
          onClose={handleSwitch}
          className={classes.switchDialog}
        />
        <Modal
          maxWidth="md"
          title="Airline Information"
          open={open}
          onClose={() => setOpen(false)}
          content={<AirlineModalInfo />}
          className={`${classes.container}`}
        />
      </Grid>
    </>
  );
};

export default Header;

const MenuItem = React.memo(({ item, selected, className, disabled }) => {
  const classes = useStyles();
  return (
    <Link
      key={item.name}
      to={disabled ? "" : `${item.link}`}
      style={{
        textDecoration: "none",
      }}
      className={disabled ? classes.disabledLink : ""}
    >
      <ListItem
        key={item.name}
        underline={true}
        selected={selected}
        className={clsx(
          classes.menuItem,
          selected && classes.selected,
          className
        )}
        text={item.name}
        startAdornment={item.icon}
        disabled={disabled}
      />
    </Link>
  );
});
