import {
  CircularProgress as MUICircularProgress,
  makeStyles,
} from "@material-ui/core";
import React from "react";
/* -------------------------------------------------------------------------- */
export default function CircularProgresss({ size = 16 }) {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <MUICircularProgress size={size} className={classes.loading} />
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  loading: {
    color: theme.palette.primary.main,
  },
}));
