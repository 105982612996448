import { Selector } from "app/components/common/Selector";
import React from "react";
import { Pagination as MuiPagination } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
/* -------------------------------------------------------------------------- */
export default function Pagination({ page, limit, total = 0, options, onChangePage, onChangeSize }) {
  const setPagingtionByAmountAndSize = (size, amount) => {
    if (amount % size === 0) return amount / size;
    return Math.floor(amount / size) + 1;
  };
  return (
    <Grid container justify='space-between' alignItems='center' style={{ marginTop: 15 }}>
      <Grid item>
        {limit !== "all" && (
          <MuiPagination page={page} count={setPagingtionByAmountAndSize(limit, total)} shape='rounded' color='primary' onChange={onChangePage} />
        )}
      </Grid>
      <Grid item style={{ width: 150 }}>
        <Selector inputLabel={"Shows per page"} value={limit} options={options} onChange={onChangeSize} />
      </Grid>
    </Grid>
  );
}
