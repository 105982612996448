import { createStyles } from "@material-ui/styles";
import {
  FOOTER_HEIGHT,
  MAIN_HEADER_NAV_HEIGHT,
  TAB_BAR_HEIGHT,
  TOOLBAR_HEIGHT,
} from "containers/themes/attributes";
import { colors } from "./colors";
/* -------------------------------------------------------------------------- */
/**
 *
 * @param {*} theme
 * @param { toolbar: boolean,tabBar: boolean,footer: boolean } props
 * @returns
 */
function globalStyles(theme, props) {
  return createStyles({
    toolbar: {
      paddingInline: theme.spacing(4),
      minHeight: TOOLBAR_HEIGHT,
      height: TOOLBAR_HEIGHT,
      display: "flex",
      alignItems: "center",
      // borderBottom: `1px solid ${theme.palette.primary["light"]}`,
      // backgroundColor: theme.palette.primary["lightest"],
      top: 0,
      width: "100%",
      zIndex: 1,
      position: "relative",
      boxShadow: theme.shadows[2],
    },
    footer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: 0,
      paddingInline: theme.spacing(4),
      left: 0,
      borderTop: `1px solid ${theme.palette.primary["light"]}`,
      backgroundColor: theme.palette.primary["lightest"],
      height: FOOTER_HEIGHT,
      zIndex: 1,
      gap: theme.spacing(1),
      boxShadow: theme.shadows[2],
    },
    content: {
      // paddingInline: theme.spacing(4),
      // paddingBottom: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "relative",
      maxHeight: `calc(100vh - ${
        MAIN_HEADER_NAV_HEIGHT +
        (props?.tabBar ? TAB_BAR_HEIGHT : 0) +
        (props?.toolbar ? TOOLBAR_HEIGHT : 0) +
        (props?.footer ? FOOTER_HEIGHT : 0)
      }px)`,
      overflow: "auto",
    },
    formControl: {
      "& .MuiFormLabel-root": {
        ...theme.typography.subtitle1,
        marginBottom: theme.spacing(0.5),
        lineHeight: "1",
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {},
    },
    readOnly: {
      "& .MuiInputBase-root": {
        "& .MuiOutlinedInput-input": {
          opacity: 0.5,
        },
        "& .MuiTypography-root": {
          opacity: 0.5,
        },
        backgroundColor: theme.palette.grey["lighter"],
        pointerEvents: "none",
        "& .MuiSvgIcon-root": {
          color: theme.palette.grey["main"],
        },
      },
    },
  });
}

export { globalStyles };
