import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { Grid, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import { client } from "api";
import { Alert } from "@material-ui/lab";
import { TextField } from "app/components/common/TextField";
import { MainFooter, useStyles } from "./SignIn";
import { ArrowLeftIcon } from "app/components/common/Icons";
import { Button } from "app/components/common/Button";
import { EnrouteLogo } from "app/components/common/Icons/Enroute/EnrouteLogo";

const verifyToken = async (token, callback = false) => {
  const res = await client("post")(`/auth/verify-token`)({
    token,
    type: "resetPassword",
  })({});
  // if (res.error) return toast.error(res.message);
  return callback && callback(res);
};

const onResetPassword = async (data, token, callback = false) => {
  const res = await client("post")(`/auth/reset-password?token=${token}`)(data)(
    {}
  );
  callback(res.error);
  if (res.error) return;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = (props) => {
  let query = useQuery();
  const token = query.get("token");

  const classes = useStyles();
  const history = useHistory();

  const [expired, setExpired] = useState(false);

  function callBackVerify(res) {
    if (res.error) {
      setExpired(true);
      return toast.info("You will return to sign in page", {
        onClose: () => history.push("login"),
      });
    } else {
      setExpired(false);
    }
  }

  useEffect(() => {
    if (!token) history.replace("login");
    if (token) {
      verifyToken(token, callBackVerify);
    }
  }, []);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [values, setValues] = React.useState({
    showConfirmPassword: false,
    showPassword: false,
  });

  function callbackReset(error) {
    if (!error) {
      toast.info("Your password reset you will return to sign in page", {
        onClose: () => history.push("login"),
      });
    }
    setLoading(false);
  }

  const handleResetPassword = (e) => {
    e?.preventDefault();
    if (password.length < 4) {
      setPassError(true);
      return toast.info("password should be at least 4 characters");
    }
    if (password !== confirmPassword) {
      setPassError(true);
      return toast.info("password and confirm password are not equal");
    }

    const data = {
      password,
    };
    setLoading(true);

    onResetPassword(data, token, callbackReset);
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <div className={`${classes.logoBg}`}>
          <Link to="/">
            <EnrouteLogo className={classes.logo} />
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5} className={classes.content}>
        <div className={classes.gridContainer}>
          <Typography variant="h4">
            {" "}
            <IntlMessages id="appModule.resetPassword" />
          </Typography>
          {expired ? (
            <div className="app-login-form">
              <Alert severity="error">
                Your password reset link has expired!
              </Alert>
            </div>
          ) : (
            <form onSubmit={handleResetPassword} className={classes.form}>
              <TextField
                type={"password"}
                onChange={(event) => setPassword(event.target.value)}
                label={<IntlMessages id="appModule.newPassword" />}
                fullWidth
                value={password}
                error={passError}
              />
              <TextField
                type={"password"}
                onChange={(event) => setConfirmPassword(event.target.value)}
                label={<IntlMessages id="appModule.confirmPassword" />}
                fullWidth
                value={confirmPassword}
                error={passError}
              />

              <div className={classes.actionsContainer}>
                <Button
                  type="submit"
                  style={{ width: "100%" }}
                  variant="contained"
                  onClick={handleResetPassword}
                  color="primary"
                  loading={isLoading}
                >
                  <IntlMessages id="appModule.recoveryPassword" />
                </Button>
                <Link to="/login">
                  <Button
                    style={{ width: "100%" }}
                    startIcon={<ArrowLeftIcon color={"primary"} />}
                    variant={"text"}
                    color="primary"
                  >
                    Back to <IntlMessages id="appModule.login" />
                  </Button>
                </Link>
              </div>
            </form>
          )}
        </div>
        <MainFooter />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
