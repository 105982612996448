import _ from "lodash";
import { clientNoLoader } from "./reduxLoaderApi";
/* -------------------------------------------------------------------------- */
export const uploadAPI = (file, uploadProgress, is_public = true) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("is_public", is_public);
  let percentCompleted = 0;
  let configObj = {
    timeout: 300000,
    headers: {
      "Content-Type": "multipart/form-data",
      resource: "Enroute Admin panel",
    },
    onUploadProgress: (progressEvent) => {
      if (uploadProgress) {
        if (progressEvent.total) {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
        console.warn({ percentCompleted });
        uploadProgress(percentCompleted);
      }
    },
  };
  return clientNoLoader("post")("filemanager/upload")(formData)(configObj)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      let errorCode = 0;
      let result_number = null;
      if (err?.response?.status) {
        errorCode = err.response.status;
      }
      if (err?.response?.data) {
        result_number = err.response.data.result_number;
      }
      return Promise.reject({
        ecode: "Services:APIs:File:Upload:2",
        err,
        errorCode,
        result_number,
      });
    });
};
/* -------------------------------------------------------------------------- */
export const downloadFileAPI = async (
  fileId,
  isPublic = true,
  headers = {}
) => {
  let path = `${isPublic ? "/public" : ""}/${fileId}`;
  try {
    const res = await clientNoLoader("get")(`filemanager/download${path}`)({
      responseType: "blob",
      headers: {
        resource: isPublic ? "Enroute user dashboard" : "Enroute Admin panel",
        ...headers,
      },
    })({});
    // console.log({ downloadFileAPI: res });
    return res;
  } catch (err) {
    console.log(1, "catcher", err);
    let errorCode = 0;
    let result_number = null;
    if (err?.response?.status) {
      errorCode = err.response.status;
    }
    if (err?.response?.data) {
      result_number = err.response.data.result_number;
    }
    let errorResponse = {
      ecode: "Services:APIs:File:Upload:2",
      err,
      errorCode,
      result_number,
    };
    throw errorResponse;
  }
};
/* -------------------------------------------------------------------------- */

//   for (let i = 0; i < files.length; i++) {
//     formData.append("files", files[i]);
//   }
//   formData.append("is_public", is_public);

//   let percentCompleted = 0;
//   let configObj = {
//     timeout: 300000,
//     headers: {
//       "Content-Type": "multipart/form-data",
//       resource: "Enroute Admin panel",
//     },
//     onUploadProgress: (progressEvent) => {
//       if (uploadProgress) {
//         if (progressEvent.total) {
//           percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//         }
//         console.warn({ percentCompleted });
//         uploadProgress(percentCompleted);
//       }
//     },
//   };
//   try {
//     const res = await loaderClient("post")("filemanager/upload-multiple")(
//       formData
//     )(configObj);
//     return res;
//   } catch (err) {
//     let errorCode = 0;
//     let result_number = null;
//     if (err?.response?.status) {
//       errorCode = err.response.status;
//     }
//     if (err?.response?.data) {
//       result_number = err.response.data.result_number;
//     }

//     return await Promise.reject({
//       ecode: "Services:APIs:File:Upload:2",
//       err,
//       errorCode,
//       result_number,
//     });
//   }
// };
export const uploadMultipleAPI = (files, uploadProgress, is_public) => {
  return new Promise(async (resolve, reject) => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("is_public", is_public);

    let percentCompleted = 0;
    let configObj = {
      timeout: 300000,
      headers: {
        "Content-Type": "multipart/form-data",
        resource: "Enroute Admin panel",
      },
      onUploadProgress: (progressEvent) => {
        if (uploadProgress) {
          if (progressEvent.total) {
            percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }
          console.warn({ percentCompleted });
          uploadProgress(percentCompleted);
        }
      },
    };

    try {
      const res = await clientNoLoader("post")("filemanager/upload-multiple")(
        formData
      )(configObj);
      resolve(res);
    } catch (err) {
      let errorCode = 0;
      let result_number = null;
      if (err?.response?.status) {
        errorCode = err.response.status;
      }
      if (err?.response?.data) {
        result_number = err.response.data.result_number;
      }

      reject({
        ecode: "Services:APIs:File:Upload:2",
        err,
        errorCode,
        result_number,
      });
    }
  });
};
