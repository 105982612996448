import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import reducers from "../reducers/index";
import { createBrowserHistory } from "history";
import defMiddles from "./middlewares";
import { PersistGate } from "redux-persist/integration/react";
/* -------------------------------------------------------------------------- */
/*                                   Slices                                   */
/* -------------------------------------------------------------------------- */
import baseSliceReducer from "./slices";
import loading from "./loader/loaderSlice";
import librarySlice from "app/routes/dashboard/routes/library/slice/librarySlice";
import fleetSlice from "app/routes/dashboard/routes/fleet/slice/fleetSlice";
import generalSettingSlice from "app/routes/dashboard/routes/generalSettings/slice/generalSettingSlice";
import flightScheduleSlice from "app/routes/dashboard/routes/sched/slice/scheduleFlightSlice";
import fleetDocuments from "app/routes/dashboard/routes/fleetDocument/slices/documentSlice";
import reportsSlice from "app/routes/dashboard/routes/reports/slice/reportsSlice";
import flightSlice from "app/routes/dashboard/routes/flights/slice/flightSlice";
import flightReportsSlice from "app/routes/dashboard/routes/flights/components/Reports/slice/reportsSlice";
import tagSlice from "app/routes/dashboard/routes/generalSettings/components/TagTab/slice/tagSlice";
import userSlice from "app/routes/dashboard/routes/users-management/slice/userSlice";
import airlineSlice from "./slices/airline/airlineSlice";
import signupSlice from "app/routes/dashboard/routes/signup-form/slice/signupSlice";
import crewPositionSlice from "app/routes/dashboard/routes/crew-position/slice/crewPositionSlice";
import crewEndorsementSlice from "app/routes/dashboard/routes/crew-certificates/slice/crewEndorsementSlice";
import crewRatingSlice from "app/routes/dashboard/routes/crew-ratings/slice/CrewRatingSlice";
import crewCurrencySlice from "app/routes/dashboard/routes/crew-currency/slice/CrewCurrencySlice";
import moduleSlice from "./slices/modules/modulesSlice";
import flightEditing from "app/routes/dashboard/routes/FlightEditing/slice/flightEditingSlice";
import emailTemplateSlice from "app/routes/dashboard/routes/email-templates/slice/emailTemplateSlice";
import ftlSettingsSlice from "app/routes/dashboard/routes/ftl-settings/slice/ftlSettingsSlice";
import aircraftsSlice from "./slices/aircrafts/aircraftsSlice";
import messagingSlice from "app/routes/dashboard/routes/messaging/slice/MessagingSlice";
import groupsSlice from "app/routes/dashboard/routes/adminGroupAndPermissions/slice/groupsSlice";
import ticketsSlice from "app/routes/dashboard/routes/ticketing/slice/ticketingSlice";
import delayCodesSlice from "./slices/delayCodes/delayCodesSlice";
import dutySetupSlice from "app/routes/dashboard/routes/duties-setup/slices/dutySetupSlice";
import crewCalendarSlice from "app/routes/dashboard/routes/crew-calendar/slice/crewCalendarSlice";
import defaultFlightSlice from "app/routes/dashboard/routes/company-flight/slices/defaultFlightSlice";
import airportsSlice from "containers/AppLayout/Horizontal/Header/components/airport/slice/airportSlice";
import helperSlice from "app/components/modules/HelperModal/slice/helperSlice";
import { createContext } from "react";
import { Provider } from "react-redux";
/* -------------------------------------------------------------------------- */
/*                                Create Store                                */
/* -------------------------------------------------------------------------- */
const history = createBrowserHistory();
const JamboReducers = reducers(history);

const allMiddles = [...defMiddles];
//Persistor
const persistConfig = {
  key: "root", // key for the root of your state in the storage
  storage, // the storage engine to use
  whitelist: ["base"],
  // blacklist: ['reducerToExclude'],
};

const rootReducer = combineReducers({
  ...JamboReducers,
  ...loading,
  ...baseSliceReducer,
  ...librarySlice,
  ...fleetSlice,
  ...fleetDocuments,
  ...tagSlice,
  ...userSlice,
  ...flightSlice,
  ...flightReportsSlice,
  ...airlineSlice,
  ...signupSlice,
  ...moduleSlice,
  ...crewPositionSlice,
  ...generalSettingSlice,
  ...crewEndorsementSlice,
  ...crewRatingSlice,
  ...flightEditing,
  ...flightScheduleSlice,
  ...emailTemplateSlice,
  ...crewCurrencySlice,
  ...ftlSettingsSlice,
  ...aircraftsSlice,
  ...messagingSlice,
  ...groupsSlice,
  ...ticketsSlice,
  ...delayCodesSlice,
  ...dutySetupSlice,
  ...crewCalendarSlice,
  ...defaultFlightSlice,
  ...airportsSlice,
  ...helperSlice,
  ...reportsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaults) =>
    getDefaults({
      serializableCheck: {
        ignoredPaths: ["library", "users"],
      },
      immutableCheck: {
        ignoredPaths: ["library"],
      },
    }).concat(allMiddles),
});
let persistor = persistStore(store);
/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */
const StoreProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
/* -------------------------------------------------------------------------- */
export default store;
export { StoreProvider, history };
