import { makeStyles } from "@material-ui/styles";
import React from "react";

import { TableCell, TableRow } from "@material-ui/core";

const AirportTableRow = ({ data }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow tabIndex={-1}>
        <TableCell></TableCell>
        <TableCell className={classes.root}>{data?.name}</TableCell>
        <TableCell align="center">
          {data?.latitude_deg.slice(0, data?.latitude_deg.indexOf(".") + 4 + 1)}
        </TableCell>
        <TableCell align="center">
          {data?.longitude_deg.slice(
            0,
            data?.longitude_deg.indexOf(".") + 4 + 1
          )}
        </TableCell>
        <TableCell align="center">{data?.iso_region}</TableCell>
        <TableCell align="center">{data?.iso_country}</TableCell>
        <TableCell align="center">{data?.iata_code}</TableCell>
        <TableCell align="center">{data?.icao_code}</TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default AirportTableRow;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 50,
      cursor: "default !important",
    },
    textAlignCenter: {
      textAlign: "center !important",
    },
  }),
  {
    name: "AirportTableRow",
  }
);
