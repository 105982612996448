import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  inboxlist: [],
  outboxList: [],
  selectedOutbox: null,
  labelList: [],
  tagList: [],
  totalItems: 0,
  filters: {
    from: null,
    to: null,
    search: "",
    page: 1,
    limit: 20,
  },
};

const messagingSlice = createSlice({
  name: "messaging",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setInboxList: (state, action) => {
      state.inboxlist = action.payload;
    },
    setOutboxList: (state, action) => {
      state.outboxList = action.payload;
    },
    setOutbox: (state, action) => {
      state.selectedOutbox = action.payload;
    },
    setLabelList: (state, action) => {
      state.labelList = action.payload;
    },
    setTagList: (state, action) => {
      state.tagList = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },
  },
});

const { actions } = messagingSlice;
export const {
  setInboxList,
  setOutboxList,
  setOutbox,
  setLabelList,
  setTagList,
  setFilters,
  setTotalItems,
} = actions;

export const selInboxList = (state) => state.messaging.inboxList;
export const selOutboxList = (state) => state.messaging.outboxList;
export const selOutboxAssignments = (state) =>
  state.messaging.selectedOutbox?.messageAssignments;
export const selTagList = (state) => state.messaging.tagList;
export const selLabelList = (state) => state.messaging.labelList;
export const selMessagingFilters = (state) => state.messaging.filters;
export const selTotalItems = (state) => state.messaging.totalItems;

const reducer = {
  messaging: messagingSlice.reducer,
};

export default reducer;
