import React, { Children, Fragment, cloneElement } from "react";
import "./styles.css";
/* -------------------------------------------------------------------------- */
export const Skeleton = ({ children, fragment = false, className = "" }) => {
  return fragment ? (
    <Fragment>
      {children}
      <div className="loading"></div>
    </Fragment>
  ) : (
    <div className={`wave-skeleton ${className}`}>
      {children}
      <div className="loading"></div>
    </div>
  );
};
