import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import { flightFormModel, remFlightData } from "./models/flight.model";
import { ALLFITLERS, EDITFLIGHT, NEWFLIGHT } from "./flighConstants";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import { resetStore } from "store/actions";
/* ------------------------------ Initial State ----------------------------- */
const initialState = {
  list: [],
  flightCrewPositions: [],
  form: flightFormModel,
  editForm: {},
  pax: {},
  tripDetail: undefined,
  fleetIsChanged: false,

  airports: [],
  aircrafts: [],
  allList: [],
  asc: true,
  sort: "date",
  filter: {
    group: "",
    status: "",
  },
  registrations: [],
  totalItems: 10,
  filters: {
    page: 1,
    limit: 20,
    flight_number: "",
    trip_number: "",
    origin: "",
    destination: "",
    days_of_the_Week: [],
    icao_flight_plan_type: [],
    crews: "",
    tags: "",
    scheduled_time_of_departure: moment().clone().toISOString(),
    to: null,
    aircraft: [],
  },
  //
  tags: [],
  countries: [],
  //  form
  curFlight: {
    flight: {},
    flightAssignments: [],
  },
  //
  groups: [],
  crewAssignments: [],
  flightWatch: {},
  timeline: {
    currentDate: moment.utc().clone().toISOString(),
    selectedFlight: null,
    flights: [],
  },
  calendar: {
    limit: "all",
    scheduled_time_of_departure: moment().clone().toISOString(),
    to: moment().clone().add(2, "weeks").toISOString(),
    aircraft: [],
  },
  selectedTrips: [],
  // CrewTab
  flightCrewRatings: [],
};
/* --------------------------------- Helpers -------------------------------- */
const filterByPath = (path, value) => {
  return R.filter((item) => R.pathOr(null, path)(item) === value);
};

const sortByPropTitle = (prop, asc) =>
  asc
    ? R.sortWith([R.ascend(R.compose(R.identity, R.pathOr("N/A", prop)))])
    : R.sortWith([R.descend(R.compose(R.identity, R.pathOr("N/A", prop)))]);

const filterByPropValue = (prop, val) =>
  R.filter((item) => R.prop(prop)(item) === val);

function filterListByPath(state, action) {
  // const {path,crew} = action.payload;

  const newGroup =
    action.payload.group !== "" ? action.payload.group : ALLFITLERS;

  const newStatus =
    action.payload.status !== "" ? action.payload.status : ALLFITLERS;

  const isAllGroup = newGroup === ALLFITLERS;
  const isAllStatus = newStatus === ALLFITLERS;

  let list = state.allList;
  let newList = R.filter((item) => {
    const groupCondition = isAllGroup
      ? true
      : R.pathOr(null, ["role"])(item) === newGroup;
    const statusCondition = isAllStatus
      ? true
      : R.pathOr(null, ["is_deleted"])(item) === newStatus;
    return statusCondition && groupCondition;
  })(list);

  state.list = newList;
  state.filter.group = newGroup;
  state.filter.status = newStatus;
}

function sortingList(state, action) {
  const list = state.list;
  const sort = state.sort;
  const asc = state.asc;
  const bysort = action.payload;
  const bySortTitle = action.payload[0];

  if (sort !== bySortTitle) {
    const newSorted = sortByPropTitle(bysort, true)(list);
    state.list = newSorted;
    state.asc = true;
    state.sort = bySortTitle;
  } else {
    const newSorted = sortByPropTitle(bysort, !asc)(list);

    state.list = newSorted;
    state.asc = !asc;
  }
  state.page = 1;
}

const serachProp = (prop, val) =>
  R.compose(R.includes(val), R.toLower, R.propOr("N/A", prop));
/* ------------------------------ Flight Slice ------------------------------ */
const flightSlice = createSlice({
  name: "flights",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setFlightList: (state, action) => {
      state.list = action.payload;
      state.allList = action.payload;
    },
    updateFlight: (state, action) => {
      let flights = cloneDeep(state.list);
      let index = flights.findIndex((flight) => flight.id == action.payload.id);
      if (index === -1) return;
      flights[index] = { ...flights[index], ...action.payload };
      state.list = flights;
    },
    setFlight: (state, action) => {
      console.log("setFlight", action.payload);
      const newData = {
        ...action.payload,
        ...{
          fleet: action.payload.fleet || {
            id: "",
          },
          ...{
            ops_notes: action.payload?.ops_notes ?? "",
          },
        },
      };
      state.form = newData;
      state.fleetIsChanged = false;
      state.status = EDITFLIGHT;
      state.id = action.payload.id;
    },

    setAirCrafts: (state, action) => {
      state.aircrafts = action.payload;
    },

    setFlightTripDetail: (state, action) => {
      state.tripDetail = action.payload;
    },

    handleAircraftChange: (state, action) => {
      const { key, value } = action.payload;
      state.form.aircraft.id = value;
    },

    handleFleetChange: (state, action) => {
      const { key, value } = action.payload;
      state.fleetIsChanged = state.form.fleet.id !== value;
      state.form.fleet.id = value;
    },

    handleTripDetailChange: (state, action) => {
      const { key, value } = action.payload;
      state.tripDetail[key] = value;
    },

    setAirports: (state, action) => {
      state.airports = action.payload;
    },

    setFlightCrewPositions: (state, action) => {
      let flightCrewPositions = [...state.flightCrewPositions];
      if (typeof action.payload != "object") return;
      !Array.isArray(action.payload)
        ? (flightCrewPositions = [action.payload, ...flightCrewPositions])
        : (flightCrewPositions = action.payload);
      state.flightCrewPositions = flightCrewPositions;
    },

    setForminit: (state, action) => {
      state.form = flightFormModel;
      state.status = NEWFLIGHT;
      state.id = "";
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setTagsInFlights: (state, action) => {
      state.tags = action.payload;
    },
    setCountriesInFlight: (state, action) => {
      state.countries = action.payload;
    },

    setGroupsInFlights: (state, action) => {
      state.groups = action.payload;
    },

    handleFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },
    handleEditForm: (state, action) => {
      state.editForm = action.payload;
    },
    handlePax: (state, action) => {
      state.pax = action.payload;
    },

    // List
    setFlightPage: (state, action) => {
      state.page = action.payload;
    },
    setFlightFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },

    resetFlightList: (state, action) => {
      const allList = state.allList;
      state.list = allList;
      state.filter.group = "";
      state.filter.status = "";
    },

    setFlightListSize: (state, action) => {
      state.page = 1;
      state.listSize = action.payload;
    },

    setFlightListTotalItems: (state, action) => {
      state.totalItems = action.payload;
    },

    sortFlightListBy: sortingList,

    filterFlightList: (state, action) => {
      const all = state.allList;
      const { name, value } = action.payload;
      if (value === "all") {
        state.list = all;
      } else {
        const filtered = filterByPropValue(name, value)(all);
        state.list = filtered;
      }
      state.page = 1;
      state.sort = "date";
      state.asc = true;
    },
    filterFlightByText: (state, action) => {
      const text = action.payload;
      let list = state.allList;
      let newList = R.filter((item) => {
        const codeCondition = serachProp("flightname", text)(item);
        const firstCondition = serachProp("first_name", text)(item);
        const lastCondition = serachProp("last_name", text)(item);

        return codeCondition || firstCondition || lastCondition;
      })(list);
      state.list = newList;
      state.filter.group = "";
      state.filter.status = "";
    },

    filterFlight: filterListByPath,

    setCurrentFlight: (state, action) => {
      state.curFlight = action.payload;
    },
    setCrewAssignments: (state, action) => {
      state.crewAssignments = action.payload;
    },
    setFlightCrewRatings: (state, action) => {
      state.flightCrewRatings = action.payload;
    },
    setFlightWatch: (state, action) => {
      state.flightWatch = { ...action?.payload };
    },
    setTimeline: (state, action) => {
      state.timeline = { ...state.timeline, ...action.payload };
    },
    setRegistrations: (state, action) => {
      state.registrations = action.payload;
    },
    setOpsCalendarFilters: (state, action) => {
      state.calendar = { ...state.calendar, ...action.payload };
    },
    setSelectedTrips: (state, action) => {
      state.selectedTrips = action.payload || [];
    },
  },
});

export const { actions } = flightSlice;

export const {
  setStatus,
  setFlight,
  handleFormChange,
  handleEditForm,
  handlePax,
  handleTripDetailChange,
  handleAircraftChange,
  handleFleetChange,
  setFlightList,
  updateFlight,
  setForminit,
  setCountriesInFlight,
  // list
  setFlightListSize,
  setFlightPage,
  setFlightFilters,
  setTagsInFlights,
  setFlightListTotalItems,
  filterFlightList,
  sortFlightListBy,
  setCurrentFlight,
  filterFlightByText,
  resetFlightList,
  filterFlight,
  setAirCrafts,
  setFlightTripDetail,
  setAirports,
  setFlightCrewPositions,
  setCrewAssignments,
  setFlightCrewRatings,
  setFlightWatch,
  setTimeline,
  setRegistrations,
  setOpsCalendarFilters,
  setSelectedTrips,
} = actions;

export const onFlightFormChange = (key, value) => (dispatch) => {
  console.log("key", value);

  return dispatch(handleFormChange({ key, value }));
};

export const onFlightAircraftChange = (key, value) => (dispatch) => {
  return dispatch(handleAircraftChange({ key, value }));
};

export const onFlightFleetChange = (key, value) => (dispatch) => {
  return dispatch(handleFleetChange({ key, value }));
};

export const onTripFormChange = (key, value) => (dispatch) => {
  return dispatch(handleTripDetailChange({ key, value }));
};

export const flightForminit = () => (dispatch) => {
  return dispatch(setForminit());
};

/* -------------------------------- Selectors ------------------------------- */
export const selFlightsSize = (state) => state.flights.listSize;
export const selFlightsFilters = (state) =>
  Object.freeze(state.flights.filters);

export const selFlightTotalItems = (state) => state.flights.totalItems;
export const selFlightSort = (state) => state.flights.sort;
export const selFlightasc = (state) => state.flights.asc;
export const selAircrafts = (state) => state.flights.aircrafts;
export const selAirports = (state) => state.flights.airports;
export const selFlightsStutus = (state) => state.flights.status;
export const selFlightId = (state) => state.flights.id;
export const selTagsInFlights = (state) => state.flights.tags;
export const selCountriesInFlight = (state) => state.flights.countries;
export const selTripDetail = (state) => state.flights.tripDetail;
export const selFleetIsChanged = (state) => state.flights.fleetIsChanged;
export const selFlightCrewPositions = (state) =>
  state.flights.flightCrewPositions;
export const selFlightCrewRatings = (state) => state.flights.flightCrewRatings;

export const selFlightFormStatus = (state) => state.flights.status;
export const selFlightform = (state) => state.flights.form;
export const selFlightEditForm = (state) => state.flights.editForm;
export const selFlightPax = (state) => state.flights.pax;
export const selGroupsInFlight = (state) => state.flights.groups;
export const selCurrentFlight = (state) => state.flights.curFlight;
export const selFlightTableFilter = (state) => state.flights.filter;
export const selFlightList = (state) => Object.freeze(state.flights.list);
export const selCrewAssignments = (state) => state.flights.crewAssignments;
export const selFlightWatch = (state) => state.flights.flightWatch;
export const selTimelineData = (state) => state.flights.timeline;
export const selRegistrations = (state) => state.flights.registrations;
export const selSelectedTrips = (state) => state.flights.selectedTrips;
export const selFlightHistories = (state) => state.flights.histories;
/* -------------------------------------------------------------------------- */
/*                                OPS Calendar                                */
/* -------------------------------------------------------------------------- */
export const selOpsCalendarFilters = (state) => state.flights.calendar;
/* -------------------------------------------------------------------------- */
const reducer = {
  flights: flightSlice.reducer,
};

export default reducer;
