import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from "../constants/ActionTypes";
import axios from "util/Api";
import { setLoaderApp } from "store/loader/loaderSlice";

import loaderClient from "api/reduxLoaderApi";
import {
  selTokens,
  setAppStatus,
  setStash,
  setUserAssignments,
} from "store/slices";
import { AIRLINE_SELECT } from "store/slices/constants";
import { toast } from "react-toastify";
import { saveStashAndRole } from "store/slices/thunks";
import { resetStore } from "store/actions";
import { globalAxiosInstance } from "api/axiosConfig";
const setLoading = setLoaderApp;

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

//TODO not functional
export const userSignUp = ({ name, email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error!!!." });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignInV3 =
  ({ email, password }, callback) =>
  async (dispatch) => {
    let body = {
      credential: email,
      password: password,
    };
    const signInUser = await loaderClient("post")("auth/login")(body)({
      headers: {
        design: "Enroute Admin panel",
      },
    });
    console.log({ signInUser });

    if (signInUser.error) {
      callback();
    } else {
      callback();
      const { message } = signInUser;

      const token = "Bearer " + message.tokens.access.token;
      const refresh = message.tokens.refresh.token;
      const user = message.user;
      const welcomeMessage = message?.welcomeMessage;
      const user_assignments = message.user_assignments;
      if (user_assignments?.length === 1) {
        dispatch(
          setStash({
            welcomeMessage,
            user,
            token,
            refresh,
          })
        );
        dispatch(saveStashAndRole(user_assignments[0]));
        return;
      }
      if (user_assignments?.length > 1) {
        dispatch(
          setStash({
            welcomeMessage,
            user,
            token,
            refresh,
          })
        );
        dispatch(setUserAssignments(user_assignments));
        dispatch(setAppStatus(AIRLINE_SELECT));
        return;
      }
    }
  };

export const userSignOut2 = () => async (dispatch, getState) => {
  const { refresh: refreshToken } = selTokens(getState());
  const obj = {
    refreshToken,
  };
  try {
    dispatch(resetStore());
    await loaderClient("post")("/auth/logout")(obj)({
      headers: {
        design: "Enroute Admin panel",
      },
    });
  } catch (error) {
    dispatch(resetStore());
  }
};

export const verifyEmail = (token, callback) => async (dispatch) => {
  globalAxiosInstance
    .post(`/auth/verify-email?token=${token}`)
    .then((res) => {
      callback(res.data.error);
    })
    .catch((err) => {
      callback(true);
    });
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/me")
      .then(({ data }) => {
        console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_START });
    const { refresh: refreshToken } = selTokens(getState());
    const obj = {
      refreshToken,
    };
    dispatch(setLoading(true));
    axios
      .post("https://enrouteservice.com/api/auth/logout", obj)
      .then(({ data }) => {
        if (!data.error) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
          dispatch(resetStore());
          dispatch(setLoading(false));
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
          dispatch(setLoading(false));
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
        dispatch(setLoading(false));
      });
  };
};
