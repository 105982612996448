import {
  addTokens,
  addUserBase,
  clearStash,
  selAirlineId,
  selAppStatus,
  selStash,
  selTokens,
  setAirlineBaseModule,
  setAppStatus,
  setBaseAirline,
  setBaseUser,
  setBaseUserAssignment,
  setCountries,
  setRole,
  setStash,
  setTokens,
  setUserAssignments,
} from "./";

import loaderClient, { clientNoLoader } from "api/reduxLoaderApi";
import { INIT, VERIFIED, HOLD } from "./constants";
import { toast } from "react-toastify";
import moment from "moment";
import { setModules } from "./modules/modulesSlice";
import * as R from "ramda";
import { dictionary } from "./modules/module.mode";
import { resetStore } from "store/actions";

export const initProfileV2 = (callback) => async (dispatch, getState) => {
  const { token, refresh } = selTokens(getState());
  const res = await loaderClient("get")("/users/profile")({
    headers: {
      design: "Enroute Admin panel",
    },
  })({});
  if (!res.message) return "";
  const airlineId = selAirlineId(getState());
  const { user, user_assignments } = res.message;
  const activeAssignment = user_assignments.find(
    (user_assignment) => user_assignment.airline.id === airlineId
  );
  const { userAssignment } = activeAssignment;
  dispatch(setBaseUserAssignment(userAssignment));
  dispatch(setUserAssignments(user_assignments));
  dispatch(addUserBase(user));
  dispatch(setTokens({ token, refresh }));
  return dispatch(setAppStatus(VERIFIED)) && callback?.();
};
export const initAirline = (callback) => async (dispatch, getState) => {
  const { token, refresh } = selTokens(getState());
  const airlineId = selAirlineId(getState());
  if (!token || !refresh) return dispatch(setAppStatus(INIT));
  const client = loaderClient("get")(`/airlines/${airlineId}`);
  const res = await client({})({});
  if (!res.message) return "";
  dispatch(setBaseAirline(res.message.airline));
  dispatch(setTokens({ token, refresh }));
  return dispatch(setAppStatus(VERIFIED));
};

export const createBugReport = (data, callback) => async (dispatch) => {
  const client = clientNoLoader("post")(`/bug-reports`);
  const res = await client(data)({});
  if (res.error) {
    return callback(res.error);
  }
  callback?.(res.error);
  return;
};

// export const getAirlineModules = (id) => async (dispatch) => {
//   const client = loaderClient("get")(`airlines/${id}/modules`);
//   const res = await client({})({});
//   console.log("res", res)
//   if (res.error) return ;
//   dispatch(setAirlineBaseModule(res.message));
// };
export const getListModulesByAirlineId = () => async (dispatch, getState) => {
  const id = selAirlineId(getState());
  console.log("id", id);
  const client = loaderClient("get")(`airlines/${id}/modules?limit=all`);
  const res = await client({})({});
  console.log("res", res);
  if (res.error) return;
  const modules = R.reduce((acc, m) => {
    const mKey = dictionary[m.module];
    console.log("mKey", mKey);
    if (!mKey) return acc;
    return { ...acc, [mKey]: { ...m, exist: true } };
  })({})(res.message);
  dispatch(setAirlineBaseModule(res.message));
  return dispatch(setModules(modules));
};
/* -------------------------------------------------------------------------- */
export const getAllCountries = (search) => async (dispatch) => {
  let params = `?limit=1000&fields=callingCodes,flag,name,alpha2Code,alpha3Code`;
  if (!!search) {
    params += `&search=${search}`;
  }
  const client = loaderClient("get")(`/countries${params}`);
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(setCountries(res.message));
};
/* -------------------------------------------------------------------------- */
export const saveStashAndRole =
  (user_assignmnet) => async (dispatch, getState) => {
    const stash = selStash(getState());
    const status = selAppStatus(getState());
    if (user_assignmnet) {
      /* --------------------------- Airline Validation --------------------------- */
      const userAirline = user_assignmnet.airline;
      let errorMessage = "";
      let expirationDate = moment.utc(userAirline.expiration_date);
      let currentDate = moment().utc();
      let isExpired = currentDate.isAfter(expirationDate);
      if (userAirline.is_deleted) errorMessage = "Airline is deleted!";
      if (!userAirline.is_enabled) errorMessage = "Airline is not active!";
      if (isExpired) errorMessage = "Airline is expired!";
      if (errorMessage) return toast.error(errorMessage);
      /* -------------------------------------------------------------------------- */
      dispatch(setBaseUserAssignment(user_assignmnet));
      dispatch(setBaseAirline(user_assignmnet.airline));
      dispatch(setRole(JSON.stringify(user_assignmnet)));
    } else {
      toast.warning(
        "You are in the pending mode. \n Please wait for airline administrator to activate your user.",
        { autoClose: 10000 }
      );
      dispatch(resetStore());
      return;
    }
    if (status !== VERIFIED) {
      dispatch(setBaseUser(stash.user));
      toast.success(
        stash.welcomeMessage ??
          `Welcome back ${user_assignmnet?.userAssignment?.known_as ?? ""}`
      );
      dispatch(
        addTokens({
          token: stash.token,
          refresh: stash.refresh,
        })
      );
      dispatch(setAppStatus(VERIFIED));
      dispatch(clearStash());
    }
  };
/* -------------------------------------------------------------------------- */
export const getEnums = (endpoint, callback) => async (dispatch) => {
  let params = `?collection_name=${endpoint}`;
  const client = loaderClient("get")(`/enums${params}`);
  const res = await client({})({});
  if (res.error) return;
  return callback(res.message);
};
