import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  list: [],
  fleets: [],
  defaultGroups: {},
  groupPolicies: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setGroups: (state, action) => {
      state.list = action.payload;
    },
    setFleets: (state, action) => {
      state.fleets = action.payload;
    },
    setDefaultGroups: (state, action) => {
      state.defaultGroups = action.payload;
    },
    setGroupPolicies: (state, action) => {
      state.groupPolicies = action.payload;
    },
    updateGroups: (state, action) => {
      let tmpGroups = [...state.list];
      let index = tmpGroups.findIndex(
        (group) => group.id === action.payload.groupId
      );
      if (index === -1) return;
      tmpGroups[index] = { ...tmpGroups[index], ...action.payload.fields };
      state.list = tmpGroups;
    },
  },
});

const { actions } = groupsSlice;
export const {
  setGroups,
  setFleets,
  setDefaultGroups,
  setGroupPolicies,
  updateGroups,
} = actions;

export const selGroups = (state) => state.groups.list;
export const selFleets = (state) => state.groups.fleets;
export const selDefaultGroups = (state) => state.groups.defaultGroups;
export const selGroupPolicies = (state) => state.groups.groupPolicies;

const reducer = {
  groups: groupsSlice.reducer,
};

export default reducer;
