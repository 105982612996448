import React, { createRef, useEffect, useMemo, useRef } from "react";
import {
  TableContainer,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  TableHead,
  makeStyles,
  Typography,
} from "@material-ui/core";
/* -------------------------------------------------------------------------- */
const TableSize = {
  small: {
    header: 36,
    body: 48,
  },
  default: {
    header: "initial",
    body: "initial",
  },
};

/* -------------------------------------------------------------------------- */
export default function Table({
  columns = [],
  rows = [],
  className,
  children,
  size = "default",
  distinct = false,
  showNoResult = false,
}) {
  let isDefault = size === "default";
  const classes = useStyles({ isDefault, size, distinct });
  let tableContainerRef = useRef(null);
  let minHeight = useRef("inherit");
  let noResult = useMemo(
    () =>
      Boolean(
        rows?.length === 0 ||
          (rows?.props?.rows?.length === 0 && !Boolean(rows?.props?.isLoading))
      ),
    [rows]
  ); // TODO
  console.log(
    "tableContainerRef",
    tableContainerRef.current,
    !isDefault,
    showNoResult,
    noResult,
    rows?.props?.rows?.length,
    !Boolean(rows?.props?.isLoading)
  );
  useEffect(() => {
    if (tableContainerRef.current && !isDefault && showNoResult) {
      const style = window.getComputedStyle(tableContainerRef.current);
      const maxHeight = style.getPropertyValue("max-height");
      minHeight.current = maxHeight;
    }
  }, [tableContainerRef.current, isDefault, showNoResult]);

  return (
    <TableContainer
      ref={tableContainerRef}
      className={`${classes.tableContainer} ${className}`}
      style={{ minHeight: minHeight.current }}
    >
      <MuiTable stickyHeader className={classes.table}>
        <TableHead>{columns}</TableHead>
        <TableBody className={classes.body}>{rows}</TableBody>
        {children}
      </MuiTable>
      {noResult && (
        <div className={classes.noResult}>
          <Typography>No results</Typography>
        </div>
      )}
    </TableContainer>
  );
}
/* -------------------------------------------------------------------------- */
const TableCell = ({ children, ...rest }) => {
  return (
    <MuiTableCell align="center" {...rest}>
      {children}
    </MuiTableCell>
  );
};
const TableRow = ({ className, children, hover = false, ...rest }) => {
  return (
    <MuiTableRow className={className} hover={hover} {...rest}>
      {children}
    </MuiTableRow>
  );
};
/* -------------------------------------------------------------------------- */
export { TableRow, TableCell, TableHead, TableBody };
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    tableContainer: {
      boxShadow: ({ isDefault }) => isDefault && theme.shadows[1],
      height: ({ isDefault }) => isDefault && "100%",
      position: "relative",
      overflow: "auto",
      borderColor: ({ isDefault }) =>
        !isDefault && `${theme.palette.primary["light"]} !important`,
      "& > *": {
        borderColor: ({ isDefault }) =>
          !isDefault && `${theme.palette.primary["light"]} !important`,
      },
    },
    table: {
      "& .MuiTableHead-root": {
        zIndex: 2,
        height: ({ isDefault, size }) => !isDefault && TableSize[size].header,
        "& .MuiTableCell-root": {
          borderColor: ({ isDefault }) =>
            !isDefault && `${theme.palette.primary["light"]} !important`,

          "& > p": {
            fontSize: ({ isDefault }) =>
              !isDefault && `${theme.typography.body2.fontSize}`,
            fontWeight: ({ isDefault }) =>
              !isDefault && theme.typography.body2.fontWeight,
          },
        },
      },
      "& .MuiTableBody-root > .MuiTableRow-root": {
        height: ({ isDefault, size }) => !isDefault && TableSize[size].body,
        "&:nth-of-type(odd)": {
          backgroundColor: ({ isDefault, distinct }) =>
            !isDefault && !distinct && theme.palette.common.white,
        },
        "& .MuiTableCell-root": {
          fontSize: ({ isDefault }) => !isDefault && "12px",
          borderBottom: ({ isDefault }) => isDefault && "none",
          borderColor: ({ isDefault }) =>
            !isDefault && `${theme.palette.primary["light"]} !important`,
        },
      },
    },
    body: {
      position: "relative",
    },
    noResult: {
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      top: "50%",
      width: "100%",
    },
  }),
  {
    index: 1,
    name: "Tables",
  }
);
/* -------------------------------------------------------------------------- */
