import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { resetStore } from "store/actions";
/* -------------------------------------------------------------------------- */
const initialState = {
  list: [],
  categories: [],
  comments: [],
};
//
/* -------------------------------------------------------------------------- */
const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setTicketList: (state, action) => {
      state.list = action.payload;
    },
    setTicketCategoriesList: (state, action) => {
      state.categories = action.payload;
    },
    setTicketComments: (state, action) => {
      state.comments = [...action.payload];
    },
  },
});

export const { actions } = ticketsSlice;
export const {
  setTicketList,
  setTicketCategoriesList,
  setTicketComments,
  addTicketComment,
} = actions;

// selectors

export const selTicketList = (state) => state.tickets.list;
// export const selPaginationTicketList = (state) => state.tickets.list;
export const selTicketCategoriesList = (state) => state.tickets.categories;
export const selTicketCommentsList = (state) => state.tickets.comments;

const reducer = {
  tickets: ticketsSlice.reducer,
};

export default reducer;
