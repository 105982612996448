import { useEffect, useState, useRef } from "react";

const defaultLimit = 10;

/**
 * Custom hook for infinite scrolling with dynamic search capabilities.
 *
 * @param {Function} dispatcher - Function to call the API, should return data based on the page.
 * @returns {Object} Object containing control methods and state for infinite scrolling.
 */
const useInfiniteScroll = ({ dispatcher }) => {
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [data, setData] = useState([]);
  console.log({ data });
  const fetchIdRef = useRef(0); // This ref will keep track of the current fetch operation.

  useEffect(() => {
    if (!data.length) return;
    fetchData(); // Initial fetch
  }, [page]);

  const fetchData = async (filters = {}) => {
    const currentFetchId = ++fetchIdRef.current; // Increment the fetch ID to mark the new operation
    console.log("fetchData", { page, filters });

    try {
      setLoading(true);
      const newData = await dispatcher(page, filters);
      console.log({ newData });

      if (currentFetchId === fetchIdRef.current) {
        // Check if the current fetch is the most recent
        if (newData && Array.isArray(newData) && newData.length > 0) {
          if (!filters?.limit) filters.limit = defaultLimit;
          setLoadMore(!(newData.length < filters.limit));
          setData((prevData) => [...prevData, ...newData]);
        } else {
          setLoadMore(false);
        }
      }
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      if (currentFetchId === fetchIdRef.current) {
        // Only stop loading if the fetch is the latest
        setLoading(false);
      }
    }
  };

  const loadMoreData = async () => {
    console.log({ isLoading, loadMore });
    if (isLoading || !loadMore) return;
    setLoading(true);
    setPage((prevPage) => prevPage + 1);
  };

  const resetData = () => {
    setPage(1);
    setLoadMore(true);
    setLoading(false);
    setData([]);
    fetchIdRef.current++; // Invalidate any ongoing fetch operations
  };

  return { data, setData, isLoading, loadMoreData, resetData, fetchData };
};

export { useInfiniteScroll };
