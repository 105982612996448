import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import CardTitle from "reactstrap/lib/CardTitle";
import check from "assets/images/check.svg";
import cross from "assets/images/cross.svg";
import { globalAxiosInstance } from "api/axiosConfig";
import { verifyEmail } from "actions";
import CircularProgresss from "app/components/common/CircularProgress";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  cardTitle: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  img: {
    width: "160px",
    height: "160px",
  },
  content: {
    textAlign: "center",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

const VerifyEmail = () => {
  const classes = useStyles();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const dispatch = useDispatch();
  const history = useHistory();
  const [isVerified, setVarify] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      dispatch(
        verifyEmail(token, (error) => {
          console.log(555, { error });
          if (!error) {
            setVarify(true);
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
      );
    } else {
      history.replace("/");
    }
  }, [token]);
  return (
    <Grid container justify="center" className={classes.container}>
      {isLoading ? (
        <CircularProgresss size={50} />
      ) : (
        <Grid item sm="6">
          {!isVerified ? (
            <Card>
              <CardTitle className={classes.cardTitle}>
                <img alt="check" src={cross} className={classes.img} />
              </CardTitle>
              <CardContent className={classes.content}>
                <Typography variant="h4">
                  {" "}
                  Email verification failed!{" "}
                </Typography>
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  onClick={() => {
                    history.replace("/");
                  }}
                  color="secondary"
                  variant="contained"
                >
                  ok
                </Button>
              </CardActions>
            </Card>
          ) : (
            <Card>
              <CardTitle className={classes.cardTitle}>
                <img alt="check" src={check} className={classes.img} />
              </CardTitle>
              <CardContent className={classes.content}>
                <Typography variant="h4"> Verified! </Typography>
                <Typography style={{ marginTop: "14px" }} variant="subtitle1">
                  You have successfully verified your email.
                </Typography>
              </CardContent>
              <CardActions className={classes.actions}>
                <Button
                  onClick={() => {
                    history.push("/");
                  }}
                  color="primary"
                  variant="contained"
                >
                  ok
                </Button>
              </CardActions>
            </Card>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default VerifyEmail;
