export const airlineModel = {
  address: "",
  callsign: "",
  created_by: "",
  created_time: "",
  email: "",
  iata_code: "",
  icao_code: "",
  id: "",
  is_deleted: false,
  known_as: "",
  logo: "",
  name: "",
  phone: "",
  updated_time: "",
};
