import {
  setList,
  setFleet,
  setAirCrafts,
  setAircraftTypes,
  selFleetFilter,
} from "./fleetSlice";
import * as R from "ramda";
import loaderClient, { clientNoLoader } from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import { selAirlineId } from "store/slices";

// const AXIOSFAILED = "axios_failed";
// const WAITING = "waiting";

function changeNullToUndefined(input) {
  const prependKeyAndDouble = (value, key, obj) => {
    if (value === "") return undefined;
    if (value === null) return undefined;
    return value;
  };
  return R.mapObjIndexed(prependKeyAndDouble)(input);
}

export const createFleet = (data, callback) => async (dispatch) => {
  const client = loaderClient("post")("/fleets");
  const validData = changeNullToUndefined(data);
  if (validData.fuel_unit === "lbs") {
    //convert lbs to kg
    validData.maximum_fuel = validData.maximum_fuel * 2.205;
  }
  if (validData.weight_unit === "lbs") {
    //convert lbs to kg
    validData.mtow = validData.mtow * 2.205;
    validData.maximum_cargo = validData.maximum_cargo * 2.205;
  }
  //TODO
  delete validData.seat_class;
  delete validData.icao;
  const res = await client(validData)({});
  if (res.error) return;
  return callback && callback(res.message);
};

export const editFleet =
  (id, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("patch")(`/fleets/${id}`);
    const validData = changeNullToUndefined(data);
    console.log({ data, validData });
    if (validData.fuel_unit === "lbs" && !!validData.maximum_fuel) {
      //convert lbs to kg
      validData.maximum_fuel = validData.maximum_fuel / 2.205;
    }
    if (
      validData.weight_unit === "lbs" &&
      !!validData.mtow &&
      !!validData.maximum_cargo
    ) {
      //convert lbs to kg
      validData.mtow = validData.mtow / 2.205;
      validData.maximum_cargo = validData.maximum_cargo / 2.205;
    }
    delete validData.seat_class;
    delete validData.iata;
    delete validData.icao;
    const res = await client(validData)({});
    if (res.error) return;
    dispatch(getFleetById(id));
    callback && callback();
  };

export const getAllFleets =
  (filterOverride = undefined, callback = undefined) =>
  async (dispatch, getState) => {
    if (!filterOverride) filterOverride = {};
    let filter = selFleetFilter(getState());
    console.log("getAllFleets", filterOverride, filter);
    let loader = filterOverride?.loader ?? true;
    delete filterOverride?.loader;
    filter = { ...filter, ...filterOverride };
    const airline = selAirlineId(getState());
    let params = `?airline=${airline}`;
    for (const key in filter) {
      if (filter[key]) {
        params += `&${key}=${filter[key]}`;
      }
    }

    const client = (loader ? loaderClient : clientNoLoader)?.("get")(
      `/fleets${params}`
    );
    const res = await client({})({});
    if (res.error) return;
    callback?.(res.message);
    return dispatch(
      setList({
        fleets: res.message,
        totalItems: res.result_number,
      })
    );
  };

export const getFleetById = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/fleets/${id}`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(setFleet(res.message));
};

export const restoreFleet =
  (id, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("post")(`/fleets/${id}/restore`);
    const res = await client({})({});
    if (res.error) return;
    dispatch(getFleetById(id));
    return callback && callback();
  };

export const deleteFleet =
  (id, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("delete")(`/fleets/${id}`);
    const res = await client({})({});
    if (res.error) return;
    dispatch(getFleetById(id));
    return callback && callback();
  };

// distance
export const addDistancProfile =
  (data, callback) => async (dispatch, getState) => {
    const { fleet } = getState();
    const { id } = fleet;
    let body = {
      ...data,
      fleet: id,
    };
    const client = loaderClient("post")(`/distance-profiles`);
    const res = await client(body)({});
    if (res.error) return;
    dispatch(getFleetById(id));
    return callback();
  };

export const editDistanceProfile =
  (dId, data, callback) => async (dispatch, getState) => {
    const { fleet } = getState();
    const { id } = fleet;
    const client = loaderClient("patch")(`/distance-profiles/${dId}`);
    const res = await client(data)({});
    if (!res.message) return "";
    dispatch(getFleetById(id));
    return callback();
  };

export const deleteDistanceProfile =
  (dId, callback = undefined) =>
  async (dispatch, getState) => {
    const { fleet } = getState();
    const { id: fleetId } = fleet;
    const client = loaderClient("delete")(
      `/distance-profiles/${dId}/${fleetId}`
    );
    const res = await client({})({});
    if (res.error) return;
    dispatch(getFleetById(fleetId));
    callback?.();
    return;
  };

export const createFleetSeat =
  (fleetId, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("post")(`/fleets/${fleetId}/seat-classes`);
    const res = await client(data)({});
    if (res.error) return;
    return callback && callback();
  };

export const updateFleetSeat =
  (seatId, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("patch")(`fleets/${seatId}/seat-classes`);
    const res = await client(data)({});
    if (res.error) return;
    return callback && callback();
  };

export const deleteFleetSeat =
  (fleetId, data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("delete")(`fleets/${fleetId}/seat-classes`);
    console.log(data);
    const res = await client(data)({});
    if (res.error) return;
    return callback && callback();
  };

export const getAllAircrafts =
  (filters, callback = undefined) =>
  async (dispatch) => {
    let loader = filters?.loader ?? true;
    delete filters?.loader;
    let limit = filters?.limit || "10";
    if (filters?.limit) delete filters.limit;
    let params = `?limit=${limit}`;
    for (const key in filters) {
      if (filters[key]) params += `&${key}=${filters[key]}`;
    }
    const client = (loader ? loaderClient : clientNoLoader)?.("get")(
      `/aircrafts${params}`
    );
    const res = await client({})({});
    if (res.error) return;
    if (callback) callback(res.message);
    dispatch(setAirCrafts(res.message));
    return res.message;
  };

export const getAircraftById =
  (aircraftId, callback = undefined) =>
  async (dispatch) => {
    const client = loaderClient("get")(`/aircrafts/${aircraftId}`);
    const res = await client({})({});
    if (res.error) return;
    if (callback) callback(res.message);
  };

export const getAircraftTypes = (callback) => async (dispatch, getState) => {
  const airline_id = selAirlineId(getState());
  const client = loaderClient("get")(`fleets/${airline_id}/aircraft-types`);
  const res = await client({})({});
  if (res.error) return;
  dispatch(setAircraftTypes(res.message));
  return callback && callback();
};
/* -------------------------------------------------------------------------- */
export const checkFleetPositioning =
  (body, callback) => async (dispatch, getState) => {
    const client = loaderClient("post")("/fleets/positioning-check");
    const res = await client(body)({});
    if (res.error);
    return callback?.(res);
  };
