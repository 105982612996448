import { mapFieldArrToObjSchema } from "hooks/useDValidation";
import * as R from "ramda";

const FlightForm = [
  ["airline", "string"],
  ["fleet", "string"],
  ["flight_number", "string"],
  ["flight_status", "string"],
  ["origin", "string"],
  ["destination", "string"],
  ["trip_number", "string"],
  ["alt_airports", "array"],
  ["scheduled_time_of_departure", "string"],
  ["scheduled_time_of_arrival", "string"],
  ["on_block_time", "string"],
  ["takeoff_time", "string"],
  ["landing_time", "string"],
  ["report_time", "string"],
  ["delay_time", "string"],
  ["off_block_time", "string"],
  ["route", "string"],
];
export const flightFormModel = {
  airline: "",
  fleet: "",
  flight_number: "",
  origin: "",
  destination: "",
  trip_number: "",
  alt_airports: ["", ""],
  scheduled_time_of_departure: "",
  scheduled_time_of_arrival: "",
  on_block_time: "",
  takeoff_time: "",
  landing_time: "",
  report_time: "",
  delay_time: "",
  off_block_time: "",
  flight_status: "",
  route: "",
};

export const remFlightDataCreate = R.pick([
  "airline",
  "fleet",
  "flight_number",
  "origin",
  "destination",
  "trip_number",
  "alt_airports",
  "scheduled_time_of_departure",
  "scheduled_time_of_arrival",
  "on_block_time",
  "takeoff_time",
  "landing_time",
  "report_time",
  "delay_time",
  "off_block_time",
  "route",
  "aircraft",
  "is_ferry",
]);
export const remFlightData = R.pick([
  "airline",
  "fleet",
  "flight_number",
  "origin",
  "destination",
  "trip_number",
  "alt_airports",
  "scheduled_time_of_departure",
  "scheduled_time_of_arrival",
  "on_block_time",
  "takeoff_time",
  "landing_time",
  "report_time",
  "delay_time",
  "off_block_time",
  "route",
]);

export const applogReqFields = mapFieldArrToObjSchema(FlightForm);
