import { SvgIcon } from "@material-ui/core";
import React from "react";

export const DashboardIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9C9.55 3 10.0208 3.19583 10.4125 3.5875C10.8042 3.97917 11 4.45 11 5V19C11 19.55 10.8042 20.0208 10.4125 20.4125C10.0208 20.8042 9.55 21 9 21H5ZM15 21C14.45 21 13.9792 20.8042 13.5875 20.4125C13.1958 20.0208 13 19.55 13 19V14C13 13.45 13.1958 12.9792 13.5875 12.5875C13.9792 12.1958 14.45 12 15 12H19C19.55 12 20.0208 12.1958 20.4125 12.5875C20.8042 12.9792 21 13.45 21 14V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM15 10C14.45 10 13.9792 9.80417 13.5875 9.4125C13.1958 9.02083 13 8.55 13 8V5C13 4.45 13.1958 3.97917 13.5875 3.5875C13.9792 3.19583 14.45 3 15 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V8C21 8.55 20.8042 9.02083 20.4125 9.4125C20.0208 9.80417 19.55 10 19 10H15Z" />
    </SvgIcon>
  );
};
