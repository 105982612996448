import loaderClient from "api/reduxLoaderApi";
import { toast } from "react-toastify";
import { setAircrafts, setFilteredAircrafts } from "./aircraftsSlice";
/* -------------------------------------------------------------------------- */
export const getAircrafts =
  (search, limit = 10) =>
  async (dispatch) => {
    let params = `?limit=${limit}`;
    if (!!search) {
      params += `&search=${search}&fields=manufacturer,model`;
    }
    const client = loaderClient("get")(`/aircrafts${params}`);
    const res = await client({})({});
    if (res.error) return;
    return !!search
      ? dispatch(setFilteredAircrafts(res.message))
      : dispatch(setAircrafts(res.message));
  };
/* -------------------------------------------------------------------------- */
export const clearFilteredAircrafts = () => (dispatch) => {
  {
    dispatch(setFilteredAircrafts([]));
  }
};
/* -------------------------------------------------------------------------- */
