import moment from "moment";
import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
/* -------------------------------------------------------------------------- */
const initialState = {
  list: [],
  filters: {
    fields: [],
    type: null,
    from: moment().add(-1, "month").clone().toISOString(),
    to: moment().clone().toISOString(),
    aircraft: null,
    delayCode: null,
    userId: null, //crew
    positionId: null,
    currencyType: null,
  },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setReportsList: (state, action) => {
      state.list = action.payload;
    },
    setReportFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

const { actions } = reportsSlice;
export const { setReportsList, setReportFilters } = actions;

export const selReportsList = (state) => state.reports.list;
export const selReportsFilters = (state) => state.reports.filters;

const reducer = {
  reports: reportsSlice.reducer,
};

export default reducer;
