import {
  setUserList,
  setTagsInUsers,
  setUser,
  setGroupsInUsers,
  setCurrentUser,
  setAirports,
  selUserId,
  setCountriesInUser,
  setHomebases,
  setAirlineGroups,
  setAirlineUserList,
  setUserTags,
  selUserTableFilter,
} from "./userSlice";
// import * as R from "ramda";
import loaderClient, {
  clientNoLoader,
  loaderWithSetter,
} from "api/reduxLoaderApi";
import * as R from "ramda";

import { toast } from "react-toastify";
import { selAirlineId } from "store/slices";

export const getAllUsers =
  (search = undefined) =>
  async (dispatch) => {
    let params = `?limit=all`;
    if (!!search) {
      params += `&search=${search}`;
    }
    const client = loaderClient("get")(`/users${params}`);
    const res = await client({})({});
    if (!res.message) return "";
    return dispatch(setUserList(res.message));
  };

export const getUserById =
  (id, callback = undefined) =>
  async (dispatch, getState) => {
    const client = loaderClient("get")(`/users/${id}`);
    const res = await client({})({});
    if (!res.message) return "";
    let { user, user_assignments } = res.message;
    if (callback) return callback(res.message);
    dispatch(
      setCurrentUser({
        user,
        user_assignments,
      })
    );
    const airlineId = selAirlineId(getState());
    return dispatch(setUser({ ...res.message, airlineId }));
  };

export const getUserAssignmentById =
  (id, callback = undefined) =>
  async (dispatch) => {
    const client = loaderClient("get")(`/user-assignments/${id}`);
    const res = await client({})({});
    if (!res.message) return "";
    if (callback) return callback(res.message);
  };

export const getUserAssignmentRatings =
  (userAssignmentId, callback) => async (dispatch) => {
    let params = `?limit=all`;
    if (userAssignmentId) params += `&userAssignment=${userAssignmentId}`;
    const client = loaderClient("get")(`/airlines/user-ratings${params}`);
    const res = await client({})({});
    if (res.error) {
      return;
    }
    if (callback) {
      return callback(res.message);
    }
  };

export const RemoveUserRating =
  (userid, ratingId, callback = undefined) =>
  async (dispatch) => {
    const client = loaderClient("delete")(
      `/users/${userid}/ratings/${ratingId}/hard`
    );
    const res = await client({})({});
    if (res.error) return;
    if (callback) {
      return callback(res.message);
    }
  };

export const addUserRating =
  (userId, data, callback = undefined) =>
  async (dispatch) => {
    const client = loaderClient("post")(`/users/${userId}/ratings/`);
    const res = await client(data)({});
    if (res.error) return;
    if (callback) {
      return callback(res.message);
    }
  };

export const getAllTags = () => async (dispatch) => {
  const client = loaderClient("get")("/tags?limit=all");
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(setTagsInUsers(res.message));
};

export const getAllGroups = () => async (dispatch) => {
  const client = loaderClient("get")("/groups?limit=1000");
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(setGroupsInUsers(res.message));
};

export const addGroupToUser = (data) => async (dispatch) => {
  // console.log(data);
  const { user } = data;
  const client = loaderClient("post")(`/users/${user}/assignments/`);
  const res = await client(data)({});
  if (!res.message) return "";
  return dispatch(getCurrentUserAssignments(data.user));
};
export const RemoveGroupFromUser = (user, id) => async (dispatch) => {
  // console.log(data);
  const client = loaderClient("delete")(`/users/${user}/assignments/${id}`);
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(getCurrentUserAssignments(user));
};

export const addTagsToUser = (user, tagsData) => async (dispatch) => {
  const client = loaderClient("patch")(`/users/${user.id}`);
  const res = await client(tagsData)({});
  if (!res.message) return "";
  return dispatch(getAllUsers());
};

export const createUser =
  (data, callback = false) =>
  async (dispatch) => {
    const client = loaderClient("post")(`/users`);
    // const validData = remUserDataCreate(data);
    const validData = R.reject(R.equals(""), data);
    const res = await client(validData)({});
    return callback(res);
    // if (!res.message) return "";
    // return dispatch(getAllUsers());
  };

export const editUser =
  (data, callback = false) =>
  async (dispatch, getState) => {
    const id = selUserId(getState());
    const client = loaderClient("patch")(`/users/${id}`);
    const res = await client(data)({});
    //if (res.error) return;
    // dispatch(setUser({ user: res.message }));
    return callback ? callback(res) : Promise.resolve(!res.error);
  };

export const createAirlineUser =
  (data, callback = false) =>
  async (dispatch, getState) => {
    const airlineId = selAirlineId(getState());
    const client = clientNoLoader("post")(`/airlines/${airlineId}/users`);

    const res = await client(createValidUserData({ ...data, airlineId }))({});
    if (res.error) {
      callback?.(res.message, res.error);
      return;
    }

    toast.success("User added successfully");
    dispatch(getAirlineUsers());
    return callback?.(res.message, res.error);
  };

export const editUserBody =
  (data, callback = false) =>
  async (dispatch, getState) => {
    const noPass = R.omit(["password"])(data);
    const noRole = R.omit(["role"])(noPass);
    const validData = replaceData(noRole);

    const id = selUserId(getState());
    const client = loaderClient("patch")(`/users/${id}`);
    const res = await client(noRole)({});

    if (res.error) return;
    return callback && callback(res, id);
    // return dispatch(getAllUsers());
  };

export const updateUserAssignments =
  (data, userId, assignmentId, callback = false) =>
  async (dispatch, getState) => {
    try {
      const client = loaderClient("patch")(
        `/users/${userId}/assignments/${assignmentId}`
      );
      const res = await client(data)({});

      if (!res.error) toast.success("User Successfully Updated");
      dispatch(getUserById(userId));
      return callback ? callback(res, userId) : Promise.resolve(!res.error);
    } catch (ex) {
      console.log("**", ex);
      return callback
        ? callback({ error: true, message: ex })
        : Promise.resolve(false);
    }
  };

export const addUserAssignments =
  (data, userId, callback = false) =>
  async (dispatch, getState) => {
    console.log("userassign data", data);
    const validData = createValidData(data);

    const client = loaderClient("post")(`/users/${userId}/assignments`);
    const res = await client(validData)({});

    if (res.error) return;
    return callback && callback(res, userId);
  };

export const removeUserAssignments =
  (userId, assignmentId, nextIndex, callback = false) =>
  async (dispatch) => {
    // console.log(data);
    const client = loaderClient("delete")(
      `/users/${userId}/assignments/${assignmentId}`
    );
    const res = await client({})({});
    if (res.error) return;
    return callback && callback(res, userId, nextIndex);
  };

export const getCurrentUserAssignments = (id) => async (dispatch) => {
  const client = loaderClient("get")(`/users/${id}`);
  const res = await client({})({});
  if (!res.error) {
    dispatch(setCurrentUser(res.message));
  }
  return;
};

export const searchAirports = (searchItem) => async (dispatch) => {
  const client = loaderClient("get")(`/airports?${searchItem}`);
  const res = await client({})({});
  if (!res.error) {
    dispatch(setAirports(res.message));
  }
  return;
};

export const SearchCountries = (searchItem) => async (dispatch) => {
  const client = loaderClient("get")(
    `/countries?fields=name,callingCodes&${searchItem}`
  );
  const res = await client({})({});
  if (!res.error) {
    dispatch(setCountriesInUser(res.message));
  }
  return;
};

export const getCountries = (setter, search) => async (dispatch) => {
  let searchValue = search?.replace("+", "");
  searchValue = searchValue?.replace("(", "");
  searchValue = searchValue?.replace(")", "");
  if (!search || searchValue?.length < 2) return;
  const res = await loaderWithSetter(setter)("get")(`/countries`)({
    params: {
      search: searchValue,
    },
  })({});
  if (res.error) return;
  return dispatch(setCountriesInUser(res.message));
};

export const getAllHomebases = () => async (dispatch, getState) => {
  const airline = selAirlineId(getState());
  const client = loaderClient("get")(`/airlines/${airline}/homebases`);
  const res = await client({})({});
  if (!res.error) {
    dispatch(setHomebases(res.message));
  }
  return;
};

export const getAirlineGroups = () => async (dispatch, getState) => {
  const airlineId = selAirlineId(getState());
  const client = loaderClient("get")(
    `/groups/?fields=title,description&airline=${airlineId}&limit=all`
  );
  const res = await client({})({});
  if (!res.error) {
    return dispatch(setAirlineGroups(res.message));
  }
};

export const getAirlineUsers = () => async (dispatch, getState) => {
  const airline = selAirlineId(getState());
  const { group, page, limit, code, is_active } = selUserTableFilter(
    getState()
  );
  let params = `?airline=${airline}`;
  if (group && group !== "all") params += `&groups=${group}`;
  if (page) params += `&page=${page}`;
  if (limit) params += `&limit=${limit}`;
  if (code) params += `&search=${code}`;
  if (is_active !== 2) {
    params += `&can_login=${is_active === 1}`;
  }

  const client = loaderClient("get")(`/user-assignments${params}`);
  const res = await client({})({});
  if (res.error) return;
  return dispatch(
    setAirlineUserList({
      users: res.message,
      totalItems: res.result_number,
    })
  );
};

export const getAllCountries = () => async (dispatch) => {
  const client = loaderClient("get")("/countries");
  const res = await client({})({});
  if (!res.message) return "";
  return dispatch(setCountriesInUser(res.message));
};

export const addUserTags = (userAssignmentId, tags) => async (dispatch) => {
  const client = loaderClient("post")(
    `/user-assignments/${userAssignmentId}/tags`
  );
  const res = await client({ tags })({});
  if (!res.message) return "";
  return dispatch(setUserTags(res.message));
};

export const updateUserProfile =
  (id, imageId, callback = false) =>
  async (dispatch) => {
    console.log("patch-upload");
    const client = loaderClient("patch")(`/users/${id}`);
    const res = await client({ image: imageId })({});
    if (res.error) return;
    // return callback && callback(imageId);
  };

// export const createTag = (data, callback) => async (dispatch) => {
//   const client = loaderClient("post")("/tags");
//   const res = await client(data)({});
//   if (!res.message) return "";
//   return dispatch(getAllUsers());
// };

const createValidUserData = (data) => {
  let homebases = [data.homebase_one];
  if (data?.homebase_two) homebases.push(data.homebase_two);
  let cell_phone = {
    code: data.cell_phone_code,
    number: data.cell_phone_number,
  };
  let telephone = data.telephone_code
    ? {
        code: data.telephone_code,
        number: data.telephone_number,
      }
    : undefined;
  return {
    userBody: createValidData({
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      password: data.password,
      gender: data.gender,
      address: data.address,
      birth_date: data.birth_date,
      birth_place: data.birth_place,
      cell_phone,
      middle_name: data.middle_name,
      nationality: data.nationality,
      telephone,
      // ftl_at_the_time_of_joining: [data.ftl_at_the_time_of_joining],
    }),
    assignmentBody: createValidData({
      airline: data.airlineId,
      groups: data.groups,
      crew_id: "1", //TODO must read from enum
      code: data.code,
      homebases,
      email: data.company_email,
      known_as: data.known_as,
      tags: data.tags,
      departments: data.departments,
    }),
  };
};

const createValidData = (data) => {
  return R.reject(R.equals(""), data);
};

const replaceData = (data) => {
  let convertedData = data;
  Object.keys(data).forEach(function (key) {
    if (data[key] === "") {
      convertedData[key] = "";
    }
  });
  return convertedData;
};
