import { resetStore } from "store/actions";

const { createSlice } = require("@reduxjs/toolkit");
const initialState = {
  list: [],
  total: 20,
};
const dutySetupSlice = createSlice({
  name: "dutySetup",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setAirlineDuties: (state, action) => {
      state.list = action.payload?.message;
      state.total = action.payload?.result_number;
    },
  },
});

export const { actions } = dutySetupSlice;

export const { setAirlineDuties } = actions;

export const selAirlineDuties = (state) => state.dutySetup.list;
export const selTotalAirlineDuties = (state) => state.dutySetup.total;

const reducer = {
  dutySetup: dutySetupSlice.reducer,
};

export default reducer;
