import { createSlice } from "@reduxjs/toolkit";
import {
  MODULEAIRLINE,
  MODULEBRIFING,
  MODULEFLEET,
  MODULELIBRARY,
  MODULEOFP,
  MODULEPERFORMANCE,
  MODULEREPORT,
  MODULEUSER,
  MODULEWB,
  MODULEFLIGHT,
} from "./module.mode";
import { resetStore } from "store/actions";
const initialState = {
  status: "init",
  // modules: {
  //   lib: {
  //     date: new Date(2019, 1, 1),
  //     settings: {},
  //   },
  // },
  modules: {
    [MODULEFLIGHT]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:flight:module",
    },
    [MODULELIBRARY]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:library:module",
    },

    [MODULEUSER]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:user:module",
    },

    [MODULEFLEET]: {
      exist: false,
      settings: {},
      enabled: false,
      expiration_date: new Date(),
      module: "enr:fleet:module",
    },

    [MODULEOFP]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:ofp:module",
    },

    [MODULEBRIFING]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:briefing:module",
    },

    [MODULEWB]: {
      exist: false,
      enabled: false,
      expiration_date: new Date(),
      module: "enr:w&b:module",
    },

    [MODULEPERFORMANCE]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:performance:module",
    },

    [MODULEREPORT]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:report:module",
    },

    [MODULEAIRLINE]: {
      exist: "",
      enabled: false,
      expiration_date: new Date(),
      module: "enr:airline:module",
    },
  },
  airline: "",
  refresh: null,
};

const modulesSlice = createSlice({
  name: "modules",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setAirlineInModules: (state, action) => {
      state.airline = action.payload;
      console.log("action", action);
    },
  },
});

export const { actions } = modulesSlice;
export const {
  setLoading,
  setModules,
  setUserAssignments,
  setModuleSetting,
  setAirlineInModules,
} = actions;

// selectors
export const selModsOBJ = (state) => state.modules.modules;
export const selModules = (state) => state.modules.modules;
export const selAirlineInModules = (state) => state.airline.modules;

const reducer = {
  modules: modulesSlice.reducer,
};

export default reducer;
