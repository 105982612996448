import { Grid, makeStyles } from "@material-ui/core";
import { Selector } from "app/components/common/Selector";
import { TextField } from "app/components/common/TextField";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AirportsTable from "./Table/AirportsTable";
import { selAirportList } from "./slice/airportSlice";
import { getAirports } from "./slice/airportThunk";
import useSWR from "swr";

const Airport = () => {
  const airports = useSelector(selAirportList);
  const [airportType, setAirportType] = useState("All");
  const [name, setName] = useState("");
  const [iataCode, setIataCode] = useState("");
  const [icaoCode, setIcaoCode] = useState("");
  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    sortBy: "ident:asc",
  });
  const dispatch = useDispatch();
  const { isLoading } = useSWR(
    `airports_${filters(params)}`,
    handleGetAirports
  );
  const classes = useStyles();

  // useEffect(() => {
  //   const queryParam = filters(params);
  //   dispatch(getAirports(queryParam));
  // }, [params]);

  const handlePagination = useCallback((value) => {
    setParams((params) => ({ ...params, ...value }));
  }, []);

  function handleGetAirports() {
    const queryParam = filters(params);
    return dispatch(getAirports(queryParam));
  }

  // ========================= D E B O U N C E ===============================
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

      return () => {
        clearTimeout(timer);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const searchDebounces = useDebounce(name, 1000);

  useEffect(() => {
    setParams(({ search, ...rest }) =>
      !searchDebounces
        ? { ...rest, page: 1 }
        : { ...rest, search: searchDebounces, page: 1 }
    );
  }, [searchDebounces]);

  // ========================= F I L T E R ===============================
  const handleSelectType = useCallback((e) => {
    const { value } = e.target;
    setAirportType(value);
    setParams(({ type, ...rest }) => {
      return value === "All"
        ? { ...rest, page: 1 }
        : { ...rest, type: value, page: 1 };
    });
  }, []);
  const handleSearchName = useCallback((e) => {
    const { value } = e.target;
    setName(value);
  }, []);
  const handleSearchIata = useCallback((e) => {
    const { value } = e.target;
    setIataCode(value);
    setParams(({ iata_code, ...rest }) => {
      return { ...rest, iata_code: value.length > 1 ? value : "", page: 1 };
    });
  }, []);
  const handleSearchIcao = useCallback((e) => {
    const { value } = e.target;
    setIcaoCode(value);
    setParams(({ iata_code, ...rest }) => {
      return { ...rest, icao_code: value.length > 1 ? value : "", page: 1 };
    });
  }, []);

  return (
    <>
      <Grid item container xs={12} className={classes.filters}>
        <Grid item>
          <TextField
            label="Airport name"
            placeholder="Search"
            value={name}
            onChange={handleSearchName}
          />
        </Grid>
        <Grid item>
          <TextField
            label="IATA"
            placeholder="IATA"
            value={iataCode}
            onChange={handleSearchIata}
          />
        </Grid>
        <Grid item>
          <TextField
            label="ICAO"
            placeholder="ICAO"
            value={icaoCode}
            onChange={handleSearchIcao}
          />
        </Grid>

        {/* <Selector
          fullWidth
          value={airportType || "All"}
          label='Airport Type'
          hasNone
          nonOptionText='All'
          options={[
            { id: "small_airport", value: "S" },
            { id: "large_airport", value: "L" },
            { id: "heliport", value: "H" },
            { id: "closed", value: "C" },
          ]}
          onChange={(e) => handleSelectType(e)}
        /> */}
      </Grid>
      <AirportsTable
        airports={airports}
        pagination={params}
        handlePagination={handlePagination}
        isLoading={isLoading}
      />
    </>
  );
};
export default Airport;

const filters = (params) => {
  const entries = Object.entries(params).filter(([, value]) => value);
  const queryParam = entries.map(([key, value]) => `${key}=${value}`).join("&");
  return queryParam;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  filters: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: 16,
  },
}));
