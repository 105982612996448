import { resetStore } from "store/actions";
import { generalSettingModel } from "./generalSettingModel";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  settings: {},
  form: generalSettingModel,
  currencyCodes: [],
  departments: [],
  countries: [],
  departmentForm: {},
  departmentModal: false,
  totalDepartment: 0,
};

const generalSettingSlice = createSlice({
  name: "generalSettings",
  initialState,
  extraReducers: (builder) => builder.addCase(resetStore, () => initialState),
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setGeneralSettingForm: (state, action) => {
      const settings = { ...action.payload };
      if (!settings.crew_age_validation) settings.crew_age_validation = 0;
      if (!settings.default_quantity_unit_for_fuel_prices)
        settings.default_quantity_unit_for_fuel_prices = 0;
      state.form = { ...settings };
    },
    handleGeneralSettingFormChange: (state, action) => {
      const { key, value } = action.payload;
      state.form[key] = value;
    },
    setCurrencyCodes: (state, action) => {
      state.currencyCodes = action.payload;
    },
    setCountriesInUser: (state, action) => {
      state.countries = action.payload;
    },
    setDepartment: (state, action) => {
      const departments = action.payload.message;
      state.departments = departments;
      state.totalDepartment = action.payload.result_number;
    },

    handleFormChange: (state, action) => {
      if (action.payload?.id) {
        state.departmentForm = action.payload;
      } else {
        const { name, value, key } = action.payload;
        if (!key) state.departmentForm[name] = value;
        else state.departmentForm[name][key] = value;
      }
    },
    handleFormClear: (state, action) => {
      state.departmentForm = {};
      state.departmentModal = false;
    },
    departmentModal: (state, action) => {
      state.departmentModal = action.payload;
    },
  },
});

const { actions } = generalSettingSlice;
export const {
  setSettings,
  setGeneralSettingForm,
  handleGeneralSettingFormChange,
  setCurrencyCodes,
  setDepartment,
  setCountriesInUser,
  handleFormChange,
  handleFormClear,
  departmentModal,
} = actions;

export const onUserFormChange = (name, value, key) => (dispatch) => {
  if (key === "working_days") {
    return dispatch(handleGeneralSettingFormChange({ value, key }));
  } else {
    return dispatch(handleFormChange({ name, value, key }));
  }
};

export const selGeneralSettingForm = (state) => state.generalSettings.form;
export const selCurrencyCodes = (state) => state.generalSettings.currencyCodes;
export const selDepartments = (state) => state.generalSettings.departments;
export const selDepartmentModal = (state) =>
  state.generalSettings.departmentModal;
export const selTotalDepartments = (state) =>
  state.generalSettings.totalDepartment;
export const selCountriesInUser = (state) => state.generalSettings.countries;
export const selDepartmentForm = (state) =>
  state.generalSettings.departmentForm;

// export const selDepartmentForm = (state) => state.users.form;

const reducer = {
  generalSettings: generalSettingSlice.reducer,
};

export default reducer;
