import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MUICard from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { CardHeader, Typography } from "@material-ui/core";
import clsx from "clsx";
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      minWidth: 232,
      overflow: "auto",
      paddingInline: theme.spacing(2),
      "& .MuiCardHeader-root": {
        paddingInline: 0,
        borderBottom: `1px solid ${theme.palette.black[20]}`,
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        "& .MuiCardHeader-title": {
          ...theme.typography.h6,
          color: theme.palette.black[80],
        },
      },
      "& .MuiCardContent-root": {
        // borderBottom: `1px solid ${theme.palette.black[20]}`,
      },
      "& .MuiCardActions-root": {
        paddingBlock: theme.spacing(2),
        position: "sticky",
        bottom: 0,
        width: "100%",
        background: theme.palette.common.white,
        borderTop: `1px solid ${theme.palette.black[20]}`,
      },
    },
  }),
  { name: "CommonCard" }
);
/* -------------------------------------------------------------------------- */
export default function Card({
  header,
  headerAction,
  subHeader,
  actions,
  content,
  className,
  ...rest
}) {
  const classes = useStyles();
  return (
    <MUICard className={clsx(classes.root, className)} {...rest}>
      {header && (
        <CardHeader
          action={headerAction}
          title={header}
          subheader={subHeader}
        ></CardHeader>
      )}
      <CardContent>{content}</CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </MUICard>
  );
}
