import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { client } from "api";
import { toast } from "react-toastify";
import useValidation from "hooks/useDValidation";
import BgLogo from "assets/images/enroute-bg-logo.png";
import LOGO from "assets/images/enroutelogo.png";
import { TextField } from "app/components/common/TextField";
import { Button } from "app/components/common/Button";
import { ArrowLeftIcon } from "app/components/common/Icons";
import { MainFooter, useStyles } from "./SignIn";
import { EnrouteLogo } from "app/components/common/Icons/Enroute/EnrouteLogo";
/* -------------------------------------------------------------------------- */
const fieldValidationJson = [
  {
    name: "email",
    validationType: "string",
    validations: [
      {
        type: "required",
        params: ["email is required"],
      },
      {
        type: "email",
        params: ["Please enter a valid email"],
      },
    ],
  },
];

const onAskEmail = async (data, callback = false) => {
  const res = await client("post")(`/auth/forgot-password`)(data)({});
  callback(res.error, res.message);
};

const ForgotPassword = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [requestSent, setRequest] = useState({ show: false, message: "" });

  const { errors, checkFiled, checkValidation } = useValidation({
    data: fieldValidationJson,
    key: "ResetPassword",
  });

  const handleChange = (e) => {
    setEmail(e.target.value ?? "");
  };

  const onResend = () => {
    onAskEmail({ credential: email }, () => {
      return toast.info("Email sent");
    });
  };

  async function handleResetPasswordClick(e) {
    e?.preventDefault();
    if (email.length < 6)
      return toast.error(`Invalid Username / email address`);
    setLoading(true);
    return onAskEmail({ credential: email }, callbackReset);
  }
  const callbackReset = (error, res) => {
    setLoading(false);
    if (!error) {
      const extractedEmail = extractEmails(res);
      setRequest({ show: true, message: extractedEmail });
      return;
    }
  };
  const openEmail = () => {
    window.open(`mailto:${email}`, "_blank");
  };
  /* -------------------------------------------------------------------------- */

  const renderForm = () => {
    return (
      <>
        <Typography variant="h4">Forgot password</Typography>
        <form onSubmit={handleResetPasswordClick} className={classes.form}>
          <TextField
            type="email"
            onChange={handleChange}
            error={errors.email}
            label={<IntlMessages id="appModule.usernameOrEmail" />}
            value={email}
          />
          <div
            className={classes.actionsContainer}
            alignContent="flex-end"
            alignItems="center"
          >
            <Button
              style={{ width: "100%" }}
              type="submit"
              variant="contained"
              onClick={handleResetPasswordClick}
              loading={isLoading}
              color="primary"
            >
              <IntlMessages id="appModule.recoveryPassword" />
            </Button>
            <Link to="/login">
              <Button
                startIcon={<ArrowLeftIcon color={"primary"} />}
                variant={"text"}
                style={{ width: "100%" }}
                color="primary"
              >
                Back to <IntlMessages id="appModule.login" />
              </Button>
            </Link>
          </div>
        </form>
      </>
    );
  };

  const renderRequestSent = () => {
    return (
      <>
        <Typography variant="h4">Check your email</Typography>
        <div className={classes.form}>
          <div>
            <Typography>We sent a password reset link to</Typography>
            <Typography variant={"h6"}>{requestSent.message}</Typography>
          </div>
          <div
            className={classes.actionsContainer}
            alignContent="flex-end"
            alignItems="center"
          >
            <Button
              style={{ width: "100%" }}
              type="submit"
              variant="contained"
              onClick={openEmail}
              color="primary"
            >
              <IntlMessages id="appModule.openEmail" />
            </Button>
            <div className={classes.resend}>
              <Typography>Didn't receive the email?</Typography>
              <Typography
                color="primary"
                style={{
                  height: 34,
                  display: "flex",
                  alignItems: "center",
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
                onClick={onResend}
              >
                Click to resend
              </Typography>
            </div>

            <Link to="/login">
              <Button
                startIcon={<ArrowLeftIcon />}
                variant={"text"}
                style={{ width: "100%" }}
                color="primary"
              >
                Back to <IntlMessages id="appModule.login" />
              </Button>
            </Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <Grid className={classes.container}>
      <Grid item xs={12} sm={12} md={6} lg={7}>
        <div className={`${classes.logoBg}`}>
          <Link to="/">
            <EnrouteLogo className={classes.logo} />
          </Link>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={5} className={classes.content}>
        <div className={classes.gridContainer}>
          {requestSent.show ? renderRequestSent() : renderForm()}
        </div>
        <MainFooter />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
/* -------------------------------------------------------------------------- */
function extractEmails(text) {
  const emailRegex = /[\w.*-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}/g;
  return String(text).match(emailRegex) || [];
}
