import React from "react";
import ReactDOM from "react-dom/client";
import "mapbox-gl/dist/mapbox-gl.css";
import "assets/vendors/style";
import "react-toastify/dist/ReactToastify.css";
import MainApp from "./MainApp";
import { initSentry } from "util/sentry";

const root = ReactDOM.createRoot(document.getElementById("app-site"));
initSentry();
root.render(<MainApp />);
